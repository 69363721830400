import React from 'react';
import { connect } from 'react-redux';

/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */

/**----------------------------------------------------------------------------------------*/
/** Importation des screens */



/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */
import './styles/home.style.scss';
import './responsive.style/home.responsive.style.scss';




/**----------------------------------------------------------------------------------------*/
/**Importation des images*/



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class HomeView extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            missionList: [],
            otherMissionList: [],
            processList: [],
        }

        this.grid = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    componentDidMount() {
        
    }

    _addProcessTask(processIndex, name) {
        let processList = this.state.processList;
        processList[processIndex].taskIdentification.taskList.push({
            name: name,
            taskDuration: {
                progressionWeightUnit: "",
                taskDurationValue: "",
                averageTaskTimeValue: "",
                taskLateness: "non",
                taskLatenessRaison: "",
                taskLatenessSolution: "",
                taskLatenessCoast: "",
            },
            taskMetResource: {
                taskMetResourceValue: "",
                taskRulJob: "",
                taskOperationalProblems: "",
                taskDifferentCrew: "non",
                taskMetRulExchange: "",
            },
            taskTechResource: {
                taskInfraEquipment: "",
                taskLogicEquipment: "",
                taskOtherTechResource: "",
                taskOtherMaterialResource: "",
            },
            taskOthersResource: {
                taskOthersResourceValue: "",
                taskAllOthersResourceAvailable: "oui",
                taskOthersResourceSolution: ""
            },
            taskDataExchange: {
                messageToSendAndReceive: "",
                forms: "",
            },
            taskManualAuto: {
                taskIsManual: "oui",
                taskIsMakeByInternAgents: "oui",
                taskInternAgents: "",
                taskIsMakeByExternalAgents: "non",
                taskExternalAgents: "",
                taskIsMakeByExternalActors: "non",
                taskExternalActors: "",
                taskHasInformatiqueResource: "oui",
                taskInformatiqueResource: ""
            },
            taskProblems: {
                taskProblemsValue: "",
                taskProblemsSolutions: "",
                taskProblemsDefinitiveSolution: "",
                taskProblemsOthersComments: "",
            }
        });
        this.setState({
            processList: processList,
        });
    }

    _deleteProcessTask(processIndex, taskIndex) {
        let processList = this.state.processList;
        processList[processIndex].taskIdentification.taskList.splice(taskIndex, 1);
        this.setState({
            processList: processList,
        });
    }

    _addProcess(name) {
        let processList = this.state.processList;
        processList.push({
            name: name,
            taskIdentification: {
                eventListener: "",
                otherEventListener: "",
                end_eventListener: "",
                otherEnd_eventListener: "",
                taskList: [],
                elementaryTaskLink: "",
                otherElementaryTaskLink: "",
                missionProblems: "",
            }
        });
        this.setState({
            processList: processList,
        });
    }

    _deleteProcess(index) {
        let processList = this.state.processList;
        processList.splice(index, 1);
        this.setState({
            processList: processList,
        });
    }

    _addMission(name) {
        let missionList = this.state.missionList;
        missionList.push({
            name: name,
            description: {
                description: "",
                condition: "",
                allConditionsSatisfy: false,
                whatMake: "",
                resultWaiting: "",
            },
            actors: {
                agents: "",
                roles: "",
                externalActors: "",
                actorsRoles: "",
                missionEtape: "",
                missionProcess: ""
            },
            process: {
                processList: [],
                "la_gestion_des_processus_couvre_t_elle_tous_vos_processus": "",
                "quel_est_le_perimetre_couvert": "",
            }
        });
        this.setState({
            missionList: missionList,
        });
    }

    _deleteMission(index) {
        let missionList = this.state.missionList;
        missionList.splice(index, 1);
        this.setState({
            missionList: missionList,
        });
    }

    _addMissionProcess(missionIndex, name) {
        let missionList = this.state.missionList;
        missionList[missionIndex].process.processList.push({
            name: name,
            taskIdentification: {
                eventListener: "",
                otherEventListener: "",
                end_eventListener: "",
                otherEnd_eventListener: "",
                taskList: [],
                elementaryTaskLink: "",
                otherElementaryTaskLink: "",
                missionProblems: "",
            }
        });
        this.setState({
            missionList: missionList,
        });
    }

    _deleteMissionProcess(missionIndex, index) {
        let missionList = this.state.missionList;
        missionList[missionIndex].process.processList.splice(index, 1);
        this.setState({
            missionList: missionList,
        });
    }

    _addMissionProcessTask(missionIndex, processIndex, name) {
        let missionList = this.state.missionList;
        missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList.push({
            name: name,
            taskDuration: {
                progressionWeightUnit: "",
                taskDurationValue: "",
                averageTaskTimeValue: "",
                taskLateness: "non",
                taskLatenessRaison: "",
                taskLatenessSolution: "",
                taskLatenessCoast: "",
            },
            taskMetResource: {
                taskMetResourceValue: "",
                taskRulJob: "",
                taskOperationalProblems: "",
                taskDifferentCrew: "non",
                taskMetRulExchange: "",
            },
            taskTechResource: {
                taskInfraEquipment: "",
                taskLogicEquipment: "",
                taskOtherTechResource: "",
                taskOtherMaterialResource: "",
            },
            taskOthersResource: {
                taskOthersResourceValue: "",
                taskAllOthersResourceAvailable: "oui",
                taskOthersResourceSolution: ""
            },
            taskDataExchange: {
                messageToSendAndReceive: "",
                forms: "",
            },
            taskManualAuto: {
                taskIsManual: "oui",
                taskIsMakeByInternAgents: "oui",
                taskInternAgents: "",
                taskIsMakeByExternalAgents: "non",
                taskExternalAgents: "",
                taskIsMakeByExternalActors: "non",
                taskExternalActors: "",
                taskHasInformatiqueResource: "oui",
                taskInformatiqueResource: ""
            },
            taskProblems: {
                taskProblemsValue: "",
                taskProblemsSolutions: "",
                taskProblemsDefinitiveSolution: "",
                taskProblemsOthersComments: "",
            }
        });
        this.setState({
            missionList: missionList,
        });
    }

    _deleteMissionProcessTask(missionIndex, processIndex, taskIndex) {
        let missionList = this.state.missionList;
        missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList.splice(taskIndex, 1);
        this.setState({
            missionList: missionList,
        });
    }

    _addOtherMission(name) {
        let otherMissionList = this.state.otherMissionList;
        otherMissionList.push({
            name: name,
            description: {
                description: "",
                condition: "",
                allConditionsSatisfy: false,
                whatMake: "",
                resultWaiting: "",
            },
            actors: {
                agents: "",
                roles: "",
                externalActors: "",
                actorsRoles: "",
                missionEtape: "",
                missionProcess: ""
            },
            process: {
                processList: [],
                "la_gestion_des_processus_couvre_t_elle_tous_vos_processus": "",
                "quel_est_le_perimetre_couvert": "",
            }
        });
        this.setState({
            otherMissionList: otherMissionList,
        });
    }

    _deleteOtherMission(index) {
        let otherMissionList = this.state.otherMissionList;
        otherMissionList.splice(index, 1);
        this.setState({
            otherMissionList: otherMissionList,
        });
    }

    _addOtherMissionProcess(missionIndex, name) {
        let otherMissionList = this.state.otherMissionList;
        otherMissionList[missionIndex].process.processList.push({
            name: name,
            taskIdentification: {
                eventListener: "",
                otherEventListener: "",
                end_eventListener: "",
                otherEnd_eventListener: "",
                taskList: [],
                elementaryTaskLink: "",
                otherElementaryTaskLink: "",
                missionProblems: "",
            }
        });
        this.setState({
            otherMissionList: otherMissionList,
        });
    }

    _deleteOtherMissionProcess(missionIndex, index) {
        let otherMissionList = this.state.otherMissionList;
        otherMissionList[missionIndex].process.processList.splice(index, 1);
        this.setState({
            otherMissionList: otherMissionList,
        });
    }

    _addOtherMissionProcessTask(missionIndex, processIndex, name) {
        let otherMissionList = this.state.otherMissionList;
        otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList.push({
            name: name,
            taskDuration: {
                progressionWeightUnit: "",
                taskDurationValue: "",
                averageTaskTimeValue: "",
                taskLateness: "non",
                taskLatenessRaison: "",
                taskLatenessSolution: "",
                taskLatenessCoast: "",
            },
            taskMetResource: {
                taskMetResourceValue: "",
                taskRulJob: "",
                taskOperationalProblems: "",
                taskDifferentCrew: "non",
                taskMetRulExchange: "",
            },
            taskTechResource: {
                taskInfraEquipment: "",
                taskLogicEquipment: "",
                taskOtherTechResource: "",
                taskOtherMaterialResource: "",
            },
            taskOthersResource: {
                taskOthersResourceValue: "",
                taskAllOthersResourceAvailable: "oui",
                taskOthersResourceSolution: ""
            },
            taskDataExchange: {
                messageToSendAndReceive: "",
                forms: "",
            },
            taskManualAuto: {
                taskIsManual: "oui",
                taskIsMakeByInternAgents: "oui",
                taskInternAgents: "",
                taskIsMakeByExternalAgents: "non",
                taskExternalAgents: "",
                taskIsMakeByExternalActors: "non",
                taskExternalActors: "",
                taskHasInformatiqueResource: "oui",
                taskInformatiqueResource: ""
            },
            taskProblems: {
                taskProblemsValue: "",
                taskProblemsSolutions: "",
                taskProblemsDefinitiveSolution: "",
                taskProblemsOthersComments: "",
            }
        });
        this.setState({
            otherMissionList: otherMissionList,
        });
    }

    _deleteOtherMissionProcessTask(missionIndex, processIndex, taskIndex) {
        let otherMissionList = this.state.otherMissionList;
        otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList.splice(taskIndex, 1);
        this.setState({
            otherMissionList: otherMissionList,
        });
    }

    _makeSubmission() {
        let data = new FormData();
        data.append("data", JSON.stringify({
            "poste_occuper": document.forms["submissionForm"]["Quel poste occupez-vous ?"].value,
            "service_travaillez_vous": document.forms["submissionForm"]["Dans quel service travaillez-vous ?"].value,
            "service_departement_direction_hierarchi": document.forms["submissionForm"]["A quels service(s) ou département(s) ou direction(s) êtes-vous hiérarchiquement rattaché ?"].value,
            "superieur_hierarchique": document.forms["submissionForm"]["Qui est votre supérieur hiérarchique direct ?"].value,
            "a_qui_reportez_vous": document.forms["submissionForm"]["A qui reportez-vous dans le service ?"].value,
            "qui_au_sein_affecte_les_missions": document.forms["submissionForm"]["Qui, au sein du service/département/direction affecte les missions ?"].value,
            "missions": this.state.missionList,
            "othersMission": this.state.otherMissionList,
            "avez_vous_une_definition_ecrite_de_chacune_de_vos_missions": document.forms["submissionForm"]["Avez-vous une définition écrite de chacune de vos missions ?"].value,
            "divers_points_que_comprend_les_definitions_de_vos_missions": document.forms["submissionForm"]["Quels sont les divers points que comprend les définitions de vos missions ?"].value,
            "instances_specifiques_pour_traiter_et_arbitrer_deventuels_conflits_dans_la_realisation_de_vos_missions": document.forms["submissionForm"]["Existe-t-il des instances spécifiques pour traiter et arbitrer d'éventuels conflits dans la réalisation de vos missions ?"].value,
            "echelles_evaluation_des_enjeux_de_vos_missions": document.forms["submissionForm"]["Y a-t-il des échelles d'évaluation des enjeux de vos missions ?"].value,
            "qui_au_sein_juge_les_resultats_obtenus": document.forms["submissionForm"]["Qui, au sein du service/département/direction juge les résultats obtenus ?"].value,
            "comment_sont_mesures_les_resultats_obtenus_dans_laccomplissement_de_vos_missions": document.forms["submissionForm"]["Comment sont mesurés les résultats obtenus dans l'accomplissement de vos missions ?"].value,
            "avez_vous_determine_des_indicateurs_de_base_pour_la_gestion_de_vos_differentes_missions": document.forms["submissionForm"]["Avez-vous déterminé des indicateurs de base pour la gestion de vos différentes missions ?"].value,
            "ces_indicateurs_sont_ils_toujours_consolidables": document.forms["submissionForm"]["Ces indicateurs sont-ils toujours consolidables ?"].value,
            "raison_qui_explique_que_des_indicateurs_ne_sont_pas_toujours_consolidables": document.forms["submissionForm"]["Quelles sont les raisons qui peuvent expliquer que des indicateurs ne sont pas toujours consolidables ?"].value,
            "pistes_damelioaration_de_ses_indicateurs": document.forms["submissionForm"]["Quels sont les pistes d'amélioration de ces indicateurs pour qu'ils puissent le mieux correspondre à une mesure cohérente des objectifs formulés ?"].value,
            // "processus_metiers": this.state.processList,
            // "la_gestion_des_processus_couvre_t_elle_tous_vos_processus": document.forms["submissionForm"]["La gestion des processus couvre-t-elle tous vos processus ?"].value,
            // "quel_est_le_perimetre_couvert": document.forms["submissionForm"]["Quel est le périmètre couvert ?"].value,

            "actions_menees_pour_suivre_le_bon_fonctionnement_de_ses_processuss": document.forms["submissionForm"]["Quelles sont les actions menées pour suivre le bon fonctionnement de ces processus ?"].value,
            "actions_menees_pour_assurer_le_bon_traitement_des_incidents_d'incoherence": document.forms["submissionForm"]["Quelles sont les actions menées pour assurer le bon traitement des différents incidents d'incohérence ?"].value,
            "actions_menees_pour_assurer_linterfacage_des_processuss_metiers": document.forms["submissionForm"]["Quelles sont les actions menées pour assurer l'interfaçage des processus métiers ?"].value,
            "actions_menees_pour_assurer_la_communication": document.forms["submissionForm"]["Quelles sont les actions menées pour assurer la communication ?"].value,
            "actions_menees_pour_garantir_la_consolidation_des_donnee_et_des_resultats": document.forms["submissionForm"]["Quelles sont les actions menées pour garantir la consolidation des données et des résultats ?"].value,
            
            "attitudes_competences_et_comportement_souhaitees_pour_une_gestion_performante_de_vos_processus": document.forms["submissionForm"]["Quelles sont les attitudes, compétences et comportement souhaitées pour une gestion performante de vos processus ?"].value,
            "comment_acquerir_attitudes_competences_et_comportement_necessaire_a_une_gestion_performante_de_vos_processus": document.forms["submissionForm"]["Comment acquérir les attitudes, compétences et comportement nécessaire à une gestion performante de vos processus ?"].value,
            "quels_types_de_formation_permettraient_dacquerir_les_attitudes_competences_et_comportement_necessaire_a_une_bonne_gestion_de_vos_processus": document.forms["submissionForm"]["Quelles types de formation permettraient d'acquérir les attitudes, compétences et comportement nécessaire à une bonne gestion de vos processus métiers ?"].value,
            
            "difficultes_preoccupations_problemes_que_vous_rencontrez_dans_la_realisation_de_vos_missions": document.forms["submissionForm"]["Quels sont les difficultés/préoccupations/problèmes que vous rencontrez dans la réalisation de vos missions ?"].value,
            "comment_procedez_vous_pour_resoudre_les_difficultes_que_vous_rencontrez": document.forms["submissionForm"]["Comment procédez-vous pour résoudre les difficultés que vous rencontrez ?"].value,
            "ameliorations_a_apporter_pour_simplifier_votre_travail": document.forms["submissionForm"]["Quelles sont les améliorations à apporter pour simplifier votre travail ?"].value,
            "nature_des_actions_conduites_pour_lamelioration_de_vos_missions": document.forms["submissionForm"]["Quelle est la nature des actions conduites pour l'amélioration de vos missions ?"].value,
            "mecanisme_permettant_de_sassurer_que_les_actions_conduitent_correspondent_bien_a_celles_qui_sont_prevues": document.forms["submissionForm"]["Y-a-t-il un mécanisme permettant de s'assurer que les actions conduitent correspondent bien à celles qui sont prévues ?"].value,
            
            "comment_pensez_vous_quil_faudrait_proceder_pour_ameliorer_le_fonctionnement_des_processus_metiers_qui_sont_a_votre_charge": document.forms["submissionForm"]["Comment pensez-vous qu'il faudrait procéder pour améliorer le fonctionnement des processus métiers qui sont à votre charge ?"].value,
            "regles_de_gestion_quon_peut_supprimer_sans_affecter_le_bon_deroulement_de_ces_processus": document.forms["submissionForm"]["Quelles sont les règles de gestion qu'on peut supprimer sans affecter le bon déroulement de ces processus ?"].value,
            
            "pensez_vous_quon_pourrait_realiser_vos_missions_en_utilisant_moins_de_ressources": document.forms["submissionForm"]["Pensez-vous qu'on pourrait réaliser vos missions en utilisant moins de ressources ?"].value,
            "comment_devrait_on_proceder_pour_utiliser_moins_de_ressources_afin_de_realiser_vos_missions": document.forms["submissionForm"]["Comment devrait-on procéder pour utiliser moins de ressources afin de réaliser vos missions ?"].value,
            
            "erreurs_qui_surviennent_dans_la_realisation_de_vos_missions": document.forms["submissionForm"]["Y-a-t-il des erreurs qui surviennent dans la réalisation de vos missions ?"].value,
            "raisons_qui_selon_vous_engendre_les_erreurs": document.forms["submissionForm"]["Qu'elles sont les raisons qui selon-vous engendre les erreurs ?"].value,
            "comment_les_eviter": document.forms["submissionForm"]["Comment les éviter ?"].value,
            "recevez_vous_des_plaintes_des_acteurs_impliques_dans_la_resolution_de_vos_missions": document.forms["submissionForm"]["Recevez-vous des plaintes des acteurs impliqués dans la résolution de vos missions ?"].value,
            "raisons_qui_expliquent_ces_plaintes": document.forms["submissionForm"]["Qu'elles sont les raisons qui expliquent ces plaintes ?"].value,
            "systeme_de_prise_en_compte_des_erreurs_et_des_plaintes_des_acteurs_de_maniere_a_ameliorer_le_fonctionnement_des_processus": document.forms["submissionForm"]["Existe-t-il un système de prise en compte des erreurs et des plaintes des acteurs (agents, clients, ...) de manière à améliorer le fonctionnement des processus ?"].value,
            
            // "avez_vous_des_periodes_dinactivites_dans_votre_service": document.forms["submissionForm"]["Avez-vous des périodes d'inactivités dans votre service ?"].value,
            // "raisons_qui_peuvent_expliquer_que_vous_avez_des_periodes_dinactivites_dans_votre_service": document.forms["submissionForm"]["Quelles sont les raisons qui peuvent expliquer que vous avez des périodes d'inactivités dans votre service ?"].value,
            // "comment_pensez_vous_quil_faudrait_proceder_pour_regler_ce_probleme": document.forms["submissionForm"]["Comment pensez-vous qu'il faudrait procéder pour régler ce problème ?"].value,
            
            "existe_til_un_systeme_permettant_de_conduire_les_changements_des_processus_metiers_et_facilitant_lintegration_des_ameliorations_de_ceux_ci": document.forms["submissionForm"]["Existe t-il un système permettant de conduire les changements des processus métiers et facilitant l'intégration des améliorations de ceux-ci ?"].value,
            "actions_de_communication_concernant_la_demarche_les_actions_conduites_les_resultats_obtenus": document.forms["submissionForm"]["Quelles sont les actions de communication concernant la démarche, les actions conduites, les résultats obtenus ?"].value,
            "systeme_decoute_clients_de_maniere_a_apprehender_leurs_besoins_et_mesurer_en_retour_les_impacts_des_conduites_de_changement_des_processus_et_de_leur_integration": document.forms["submissionForm"]["Existe-t-il un système d'écoute clients de manière à appréhender leurs besoins et mesurer en retour les impacts des conduites de changement des processus et de leur intégration ?"].value,
            
            // "importance_de_votre_travail_pour_votre_organisation": document.forms["submissionForm"]["Quelle est selon-vous l'importance de votre travail pour votre organisation ?"].value,
            // "obtenez_vous_satisfaction_de_votre_travail": document.forms["submissionForm"]["Obtenez-vous satisfaction de votre travail ?"].value,
            // "relations_que_vous_entretenez_avec_votre_superieur_hierarchique": document.forms["submissionForm"]["Comment qualifierez-vous les relations que vous entretenez avec votre supérieur hiérarchique ?"].value,
            // "criteres_de_nomination_de_lequipe_de_gouvernance_des_processus": document.forms["submissionForm"]["Connaissez-vous les critères de nomination de l'équipe de gouvernance des processus ?"].value,
            // "difficultes_rencontrees_pour_prendre_en_charge_votre_poste_et_dans_lexercice_de_celui_ci": document.forms["submissionForm"]["Quelles sont les difficultés que vous avez rencontrées pour prendre en charge votre poste et dans l'exercice de celui-ci ?"].value,
            // "conseils_que_vous_souhaitez_donner_pour_aider_dautres_services_ou_departements": document.forms["submissionForm"]["Quels sont les conseils que vous souhaitez donner pour aider d'autres services ou départements ?"].value,
            // "quelles_satisfactions_a_titre_personnel_trouvez_vous_dans_lexercice_de_votre_fonction": document.forms["submissionForm"]["Quelles satisfactions, à titre personnel, trouvez-vous dans l'exercice de votre fonction ?"].value,
            
        }));

        this._addSubmission(data, (res)=>{
            console.log(res);
            alert("ok");
        });
    }
   
    render() {
        return (
            <>
                {
                    super.render()
                }
                <div className='container'>
                    <header className='container_header'>
                        <div>
                            <h1 className='title text-center'>
                                Modélisation des processus métiers <br/>du Ministère du Travail et de la Fonction Publique
                            </h1>

                            <h2 className='container_header_subTitle title text-center'>
                                L’objectif de ces questions est de modéliser les processus 
                                métiers concourant à la réalisation de vos missions dans votre 
                                organisation. La finalité est de dégager des mesures permettant 
                                d’optimiser l’accomplissement de vos missions.
                            </h2>
                        </div>
                    </header>

                    <form method='POST' action='#' id='submissionForm' name='submissionForm'
                        onSubmit={(e) => {
                            e.preventDefault();
                            this._makeSubmission();
                        }}
                    >
                        <section className='section'>
                            <h2 className='section_title'>
                                Présentation du poste de l'agent
                            </h2>
                            <div className='section_body'>
                                <div className='section_body_question'>
                                    <div className='section_body_question_label'>
                                        <label htmlFor='Quel poste occupez-vous ?'>
                                            Quel poste occupez-vous ?
                                        </label>
                                    </div>
                                    <div className='section_body_question_input'>
                                        <input required type='text' id='Quel poste occupez-vous ?' name='Quel poste occupez-vous ?'/>
                                    </div>
                                </div>


                                <div className='section_body_question'>
                                    <div className='section_body_question_label'>
                                        <label htmlFor='Dans quel service travaillez-vous ?'>
                                            Dans quel service travaillez-vous ?
                                        </label>
                                    </div>
                                    <div className='section_body_question_input'>
                                        <input type='text' id='Dans quel service travaillez-vous ?' name='Dans quel service travaillez-vous ?'/>
                                    </div>
                                </div>

                                <div className='section_body_question'>
                                    <div className='section_body_question_label'>
                                        <label htmlFor='A quels service(s) ou département(s) ou direction(s) êtes-vous hiérarchiquement rattaché ?'>
                                            A quels service(s) ou département(s) ou direction(s) êtes-vous hiérarchiquement rattaché ?
                                        </label>
                                    </div>
                                    <div className='section_body_question_input'>
                                        <input type='text' id='A quels service(s) ou département(s) ou direction(s) êtes-vous hiérarchiquement rattaché ?' name='A quels service(s) ou département(s) ou direction(s) êtes-vous hiérarchiquement rattaché ?'/>
                                    </div>
                                </div>

                                <div className='section_body_question'>
                                    <div className='section_body_question_label'>
                                        <label htmlFor='Qui est votre supérieur hiérarchique direct ?'>
                                            Qui est votre supérieur hiérarchique direct ?
                                        </label>
                                    </div>
                                    <div className='section_body_question_input'>
                                        <input type='text' placeholder='Donnez le titre de la personne concernée' id='Qui est votre supérieur hiérarchique direct ?' name='Qui est votre supérieur hiérarchique direct ?'/>
                                    </div>
                                </div>

                                <div className='section_body_question'>
                                    <div className='section_body_question_label'>
                                        <label htmlFor='A qui reportez-vous dans le service ?'>
                                            A qui reportez-vous dans le service ?
                                        </label>
                                    </div>
                                    <div className='section_body_question_input'>
                                        <input type='text' placeholder='Donnez le titre de la personne concernée' id='A qui reportez-vous dans le service ?' name='A qui reportez-vous dans le service ?'/>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className='section'>
                            <h2 className='section_title'>
                                Missions de l'agent

                                <br/><strong className='color-green'>
                                    On s’intéresse maintenant à la modélisation des processus métiers 
                                    de chacune de vos missions. 
                                    Une mission peut être simplement vue comme un ensemble de processus 
                                    concourant à atteindre les objectifs de cette mission. Un processus 
                                    métier est un ensemble de tâches à réaliser pour l’accomplissement de
                                    ce processus. Une tâche est une activité élémentaire à effectuer.
                                </strong>
                            </h2>
                            <section className='section'>
                                <h3 className='section_title'>
                                    Identification des missions de l'agent
                                </h3>
                                <div className='section_body'>
                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor='Qui, au sein du service/département/direction affecte les missions ?'>
                                                Qui, au sein du service/département/direction affecte les missions ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='Donnez le titre de la personne concernée' id='Qui, au sein du service/département/direction affecte les missions ?' name='Qui, au sein du service/département/direction affecte les missions ?'/>
                                        </div>
                                    </div>


                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor='Quelles sont les missions/activités régaliennes que vous devez accomplir dans le cadre de votre poste ?'>
                                                Quelles sont les missions/activités régaliennes que vous devez accomplir dans le cadre de votre poste ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_list'>
                                            {
                                                this.state.missionList.map((mission, index) => (
                                                    <div className='section_body_question_list_elt'>
                                                        <span className='section_body_question_list_elt_name'>{mission.name}</span><span className='section_body_question_list_elt_x'
                                                            onClick={(e) => {
                                                                this._deleteMission(index);
                                                            }}
                                                        >×</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className='section_body_question_button'>
                                            <span className='button_style_1' onClick={(e) => {
                                                let missionName = prompt("Veuillez saisir le nom de la mission :");
                                                if(missionName) {
                                                    this._addMission(missionName);
                                                }
                                            }}>Ajouter une mission</span>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor='Quelles sont les autres missions que vous accomplissez de façon fréquente en dehors de vos missions régaliennes ?'>
                                                Quelles sont les autres missions que vous accomplissez de façon fréquente en dehors de vos missions régaliennes ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_list'>
                                            {
                                                this.state.otherMissionList.map((mission, index) => (
                                                    <div className='section_body_question_list_elt'>
                                                        <span className='section_body_question_list_elt_name'>{mission.name}</span><span className='section_body_question_list_elt_x'
                                                            onClick={(e) => {
                                                                this._deleteOtherMission(index);
                                                            }}
                                                        >×</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className='section_body_question_button'>
                                            <span className='button_style_1' onClick={(e) => {
                                                let missionName = prompt("Veuillez saisir le nom de la mission :");
                                                if(missionName) {
                                                    this._addOtherMission(missionName);
                                                }
                                            }}>Ajouter une autre mission</span>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className='section'>
                                <h3 className='section_title'>
                                    Termes de références des missions de l'agent
                                </h3>
                                <div className='section_body'>
                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label>
                                                Avez-vous une définition écrite de chacune de vos missions ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='radio' placeholder='' id='Avez-vous une définition écrite de chacune de vos missions ?__oui' name='Avez-vous une définition écrite de chacune de vos missions ?' value="oui"/>
                                            <label htmlFor='Avez-vous une définition écrite de chacune de vos missions ?__oui'>Oui</label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='radio' placeholder='' id='Avez-vous une définition écrite de chacune de vos missions ?__non' name='Avez-vous une définition écrite de chacune de vos missions ?' value="non"/>
                                            <label htmlFor='Avez-vous une définition écrite de chacune de vos missions ?__non'>Non</label>
                                        </div>
                                    </div>


                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor='Quels sont les divers points que comprend les définitions de vos missions ?'>
                                                Quels sont les divers points que comprend les définitions de vos missions ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' id='Quels sont les divers points que comprend les définitions de vos missions ?' name='Quels sont les divers points que comprend les définitions de vos missions ?'/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Existe-t-il des instances spécifiques pour traiter et arbitrer d'éventuels conflits dans la réalisation de vos missions ?">
                                                Existe-t-il des instances spécifiques pour traiter et arbitrer d'éventuels conflits dans la réalisation de vos missions ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' id="Existe-t-il des instances spécifiques pour traiter et arbitrer d'éventuels conflits dans la réalisation de vos missions ?" name="Existe-t-il des instances spécifiques pour traiter et arbitrer d'éventuels conflits dans la réalisation de vos missions ?"/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Y a-t-il des échelles d'évaluation des enjeux de vos missions ?">
                                                Y a-t-il des échelles d'évaluation des enjeux de vos missions ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' id="Y a-t-il des échelles d'évaluation des enjeux de vos missions ?" name="Y a-t-il des échelles d'évaluation des enjeux de vos missions ?"/>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className='section'>
                                <h3 className='section_title'>
                                    Evaluation des résultats obtenus par l'agent
                                </h3>
                                <div className='section_body'>
                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor='Qui, au sein du service/département/direction juge les résultats obtenus ?'>
                                                Qui, au sein du service/département/direction juge les résultats obtenus ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='Donnez le titre de la personne concernée' id='Qui, au sein du service/département/direction juge les résultats obtenus ?' name='Qui, au sein du service/département/direction juge les résultats obtenus ?'/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Comment sont mesurés les résultats obtenus dans l'accomplissement de vos missions ?">
                                                Comment sont mesurés les résultats obtenus dans l'accomplissement de vos missions ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' id="Comment sont mesurés les résultats obtenus dans l'accomplissement de vos missions ?" name="Comment sont mesurés les résultats obtenus dans l'accomplissement de vos missions ?"/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label>
                                                Avez-vous déterminé des indicateurs de base pour la gestion de vos différentes missions ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='radio' placeholder='' id='Avez-vous déterminé des indicateurs de base pour la gestion de vos différentes missions ?__oui' name='Avez-vous déterminé des indicateurs de base pour la gestion de vos différentes missions ?' value="oui"/>
                                            <label htmlFor='Avez-vous déterminé des indicateurs de base pour la gestion de vos différentes missions ?__oui'>Oui</label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='radio' placeholder='' id='Avez-vous déterminé des indicateurs de base pour la gestion de vos différentes missions ?__non' name='Avez-vous déterminé des indicateurs de base pour la gestion de vos différentes missions ?' value="non"/>
                                            <label htmlFor='Avez-vous déterminé des indicateurs de base pour la gestion de vos différentes missions ?__non'>Non</label>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label>
                                                Ces indicateurs sont-ils toujours consolidables ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='radio' placeholder='' id='Ces indicateurs sont-ils toujours consolidables ?__oui' name='Ces indicateurs sont-ils toujours consolidables ?' value="oui"/>
                                            <label htmlFor='Ces indicateurs sont-ils toujours consolidables ?__oui'>Oui</label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='radio' placeholder='' id='Ces indicateurs sont-ils toujours consolidables ?__non' name='Ces indicateurs sont-ils toujours consolidables ?' value="non"/>
                                            <label htmlFor='Ces indicateurs sont-ils toujours consolidables ?__non'>Non</label>
                                        </div>
                                    </div>


                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor='Quelles sont les raisons qui peuvent expliquer que des indicateurs ne sont pas toujours consolidables ?'>
                                                Quelles sont les raisons qui peuvent expliquer que des indicateurs ne sont pas toujours consolidables ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' id='Quelles sont les raisons qui peuvent expliquer que des indicateurs ne sont pas toujours consolidables ?' name='Quelles sont les raisons qui peuvent expliquer que des indicateurs ne sont pas toujours consolidables ?'/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Quels sont les pistes d'amélioration de ces indicateurs pour qu'ils puissent le mieux correspondre à une mesure cohérente des objectifs formulés ?">
                                                Quels sont les pistes d'amélioration de ces indicateurs pour qu'ils puissent le mieux correspondre à une mesure cohérente des objectifs formulés ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' id="Quels sont les pistes d'amélioration de ces indicateurs pour qu'ils puissent le mieux correspondre à une mesure cohérente des objectifs formulés ?" name="Quels sont les pistes d'amélioration de ces indicateurs pour qu'ils puissent le mieux correspondre à une mesure cohérente des objectifs formulés ?"/>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>

                        <section className='section'>
                            <h2 className='section_title'>
                                Gestion des processus métiers de l'agent <br/><strong className='color-green'>Rappel : un processus métier est un ensemble de tâches à réaliser pour l'accomplissement d'une mission</strong>
                            </h2>
                            {/* Iterration sur les missions */}
                            <section className='section'>
                                <h2 className='section_title'>
                                    Modélisation des missions de l'agent
                                </h2>
                                {
                                    this.state.missionList.map((mission, missionIndex) => (
                                        <>
                                            <section className='section'>
                                                <h2 className='title'>
                                                    {mission.name} 
                                                </h2>
                                                <br/>
                                                <h3 className='section_title'>
                                                    Description de la mission
                                                </h3>
                                                <div className='section_body'>
                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={'Décrivez la mission:' + mission.name}>
                                                                Décrivez la mission
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <textarea id={'Décrivez la mission:' + mission.name} name={'Décrivez la mission:' + mission.name} value={mission.description.description}
                                                                onChange={(e)=>{
                                                                    let missionList = this.state.missionList;
                                                                    missionList[missionIndex].description.description = e.target.value;
                                                                    this.setState({
                                                                        missionList: missionList,
                                                                    });
                                                                }}
                                                            ></textarea>
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={"Quels sont les conditions qu'il faut avoir pour convenablement conduire la mission?"+mission.name}>
                                                                Quels sont les conditions qu'il faut avoir pour convenablement conduire la mission?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input value={mission.description.condition} type='text' id={"Quels sont les conditions qu'il faut avoir pour convenablement conduire la mission?"+mission.name} name={"Quels sont les conditions qu'il faut avoir pour convenablement conduire la mission?"+mission.name}
                                                                onChange={(e)=>{
                                                                    let missionList = this.state.missionList;
                                                                    missionList[missionIndex].description.condition = e.target.value;
                                                                    this.setState({
                                                                        missionList: missionList,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label>
                                                                Ces conditions sont-elles réunies ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input type='radio' placeholder='' id={"Ces conditions sont-elles réunies ?__oui"+mission.name}
                                                                name={"Ces conditions sont-elles réunies ?"+mission.name}
                                                                value={"oui"}
                                                                onChange={(e)=>{
                                                                    let missionList = this.state.missionList;
                                                                    missionList[missionIndex].description.allConditionsSatisfy = document.forms["submissionForm"]["Ces conditions sont-elles réunies ?"+mission.name].value;
                                                                    this.setState({
                                                                        missionList: missionList,
                                                                    });
                                                                    // console.log(missionList[missionIndex].description.allConditionsSatisfy);
                                                                }}
                                                            />
                                                            <label htmlFor={"Ces conditions sont-elles réunies ?__oui"+mission.name}>Oui</label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input type='radio' placeholder='' id={"Ces conditions sont-elles réunies ?__non" + mission.name}
                                                                name={"Ces conditions sont-elles réunies ?"+mission.name}
                                                                value={"non"}
                                                                onChange={(e)=>{
                                                                    let missionList = this.state.missionList;
                                                                    missionList[missionIndex].description.allConditionsSatisfy = document.forms["submissionForm"]["Ces conditions sont-elles réunies ?"+mission.name].value;
                                                                    this.setState({
                                                                        missionList: missionList,
                                                                    });

                                                                    // console.log(missionList[missionIndex].description.allConditionsSatisfy);
                                                                }}
                                                            />
                                                            <label htmlFor={"Ces conditions sont-elles réunies ?__non" + mission.name}>Non</label>
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={"Que faudrait-il ?"+mission.name}>
                                                                Que faudrait-il ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input value={mission.description.whatMake} type='text' id={"Que faudrait-il ?"+mission.name} name={"Que faudrait-il ?"+mission.name}
                                                                onChange={(e)=>{
                                                                    let missionList = this.state.missionList;
                                                                    missionList[missionIndex].description.whatMake = e.target.value;
                                                                    this.setState({
                                                                        missionList: missionList,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={"Quels sont les principaux résultats attendus à la fin de l'exécution de la mission ?" + mission.name}>
                                                                Quels sont les principaux résultats attendus à la fin de l'exécution de la mission ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input value={mission.description.resultWaiting} type='text' id={"Quels sont les principaux résultats attendus à la fin de l'exécution de la mission ?" + mission.name} name={"Quels sont les principaux résultats attendus à la fin de l'exécution de la mission ?" + mission.name}
                                                                onChange={(e)=>{
                                                                    let missionList = this.state.missionList;
                                                                    missionList[missionIndex].description.resultWaiting = e.target.value;
                                                                    this.setState({
                                                                        missionList: missionList,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <h3 className='section_title'>
                                                    Acteurs qui interviennent dans la réalisation de la mission
                                                </h3>
                                                <div className='section_body'>
                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={'Quels sont les agents impliqués dans la réalisation de ces missions ?:' + mission.name}>
                                                                Quels sont les agents impliqués dans la réalisation de ces missions ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <textarea id={'Quels sont les agents impliqués dans la réalisation de ces missions ?:' + mission.name} name={'Quels sont les agents impliqués dans la réalisation de ces missions ?:' + mission.name} value={mission.actors.agents}
                                                                onChange={(e)=>{
                                                                    let missionList = this.state.missionList;
                                                                    missionList[missionIndex].actors.agents = e.target.value;
                                                                    this.setState({
                                                                        missionList: missionList,
                                                                    });
                                                                }}
                                                            ></textarea>
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={"Quels sont les rôles de chacun de ces agents dans la réalisation de ces missions ?"+mission.name}>
                                                                Quels sont les rôles de chacun de ces agents dans la réalisation de ces missions ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input value={mission.actors.roles} type='text' id={"Quels sont les rôles de chacun de ces agents dans la réalisation de ces missions ?"+mission.name} name={"Quels sont les rôles de chacun de ces agents dans la réalisation de ces missions ?"+mission.name}
                                                                onChange={(e)=>{
                                                                    let missionList = this.state.missionList;
                                                                    missionList[missionIndex].actors.roles = e.target.value;
                                                                    this.setState({
                                                                        missionList: missionList,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={"Quels sont les acteurs externes (autres services, clients, ...) impliqués dans la réalisation de ces missions ?"+mission.name}>
                                                                Quels sont les acteurs externes (autres services, clients, ...) impliqués dans la réalisation de ces missions ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input value={mission.actors.externalActors} type='text' id={"Quels sont les acteurs externes (autres services, clients, ...) impliqués dans la réalisation de ces missions ?"+mission.name} name={"Quels sont les acteurs externes (autres services, clients, ...) impliqués dans la réalisation de ces missions ?"+mission.name}
                                                                onChange={(e)=>{
                                                                    let missionList = this.state.missionList;
                                                                    missionList[missionIndex].actors.externalActors = e.target.value;
                                                                    this.setState({
                                                                        missionList: missionList,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={"Quels sont les rôles de chacun de ces acteurs dans la réalisation de ces missions ?" + mission.name}>
                                                                Quels sont les rôles de chacun de ces acteurs dans la réalisation de ces missions ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input value={mission.actors.actorsRoles} type='text' id={"Quels sont les rôles de chacun de ces acteurs dans la réalisation de ces missions ?" + mission.name} name={"Quels sont les rôles de chacun de ces acteurs dans la réalisation de ces missions ?" + mission.name}
                                                                onChange={(e)=>{
                                                                    let missionList = this.state.missionList;
                                                                    missionList[missionIndex].actors.actorsRoles = e.target.value;
                                                                    this.setState({
                                                                        missionList: missionList,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <br/>
                                                <div className='section_body_question'>
                                                    <div className='section_body_question_label'>
                                                        <label htmlFor={'Quels sont les différentes étapes de réalisation de votre mission ?:' + mission.name}>
                                                            Quels sont les différentes étapes de réalisation de votre mission ?
                                                        </label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <textarea placeholder='conception de document, graphiques, données produites, prise de décision, approbations, etc.' id={'Quels sont les différentes étapes de réalisation de votre mission ?:' + mission.name} name={'Quels sont les différentes étapes de réalisation de votre mission ?:' + mission.name} 
                                                            value={mission.actors.missionEtape}
                                                            onChange={(e)=>{
                                                                let missionList = this.state.missionList;
                                                                missionList[missionIndex].actors.missionEtape = e.target.value;
                                                                this.setState({
                                                                    missionList: missionList,
                                                                });
                                                            }}
                                                        ></textarea>
                                                    </div>
                                                </div>

                                                <div className='section_body_question'>
                                                    <div className='section_body_question_label'>
                                                        <label htmlFor={"Quels sont les processus qui sont réalisés dans l'accomplissement de la mission ?" + mission.name}>
                                                            Quels sont les processus qui sont réalisés dans l'accomplissement de la mission ?
                                                        </label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input value={mission.actors.missionProcess} type='text' id={"Quels sont les processus qui sont réalisés dans l'accomplissement de la mission ?" + mission.name} name={"Quels sont les processus qui sont réalisés dans l'accomplissement de la mission ?" + mission.name}
                                                            onChange={(e)=>{
                                                                let missionList = this.state.missionList;
                                                                missionList[missionIndex].actors.missionProcess = e.target.value;
                                                                this.setState({
                                                                    missionList: missionList,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <h3 className='section_title'>
                                                    Identification des processus métiers
                                                </h3>
                                                <div className='section_body'>
                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor='Quels sont les processus dont vous avez la charge pour la réalisation de la mission ?'>
                                                                Quels sont les processus dont vous avez la charge pour la réalisation de la mission ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_list'>
                                                            {
                                                                this.state.missionList[missionIndex].process.processList.map((process, index) => (
                                                                    <div className='section_body_question_list_elt'>
                                                                        <span className='section_body_question_list_elt_name'>{process.name}</span><span className='section_body_question_list_elt_x'
                                                                            onClick={(e) => {
                                                                                this._deleteMissionProcess(missionIndex, index);
                                                                            }}
                                                                        >×</span>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className='section_body_question_button'>
                                                            <span className='button_style_1' onClick={(e) => {
                                                                let processName = prompt("Veuillez saisir le nom du processus :");
                                                                if(processName) {
                                                                    this._addMissionProcess(missionIndex, processName);
                                                                }
                                                            }}>Ajouter un processus</span>
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label>
                                                                La gestion des processus couvre-t-elle tous vos processus ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input onChange={(e)=>{
                                                                let missionList = this.state.missionList;
                                                                missionList[missionIndex].process.la_gestion_des_processus_couvre_t_elle_tous_vos_processus = e.target.value;
                                                                this.setState({
                                                                    missionList: missionList,
                                                                });
                                                            }} type='radio' placeholder='' id='La gestion des processus couvre-t-elle tous vos processus ?__oui' name='' value="oui"/>
                                                            <label>Oui</label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input onChange={(e)=>{
                                                                let missionList = this.state.missionList;
                                                                missionList[missionIndex].process.la_gestion_des_processus_couvre_t_elle_tous_vos_processus = e.target.value;
                                                                this.setState({
                                                                    missionList: missionList,
                                                                });
                                                            }} type='radio' placeholder='' id='La gestion des processus couvre-t-elle tous vos processus ?__non' name='' value="non"/>
                                                            <label>Non</label>
                                                        </div>
                                                    </div>


                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor=''>
                                                                Quel est le périmètre couvert ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input onChange={(e)=>{
                                                                let missionList = this.state.missionList;
                                                                missionList[missionIndex].process.quel_est_le_perimetre_couvert = e.target.value;
                                                                this.setState({
                                                                    missionList: missionList,
                                                                });
                                                            }} type='text' id='Quel est le périmètre couvert ?' name=''/>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Iterration sur les processus metiers */}
                                                <section className='section'>
                                                        <h2 className='section_title'>
                                                            Modélisation des processus métiers d'une mission <br/><strong className='color-green'>Rappel : Un processus métier est un ensemble de tâches à réaliser pour l'accomplissement d'une mission</strong>
                                                        </h2>
                                                        {
                                                            mission.process.processList.map((process, processIndex) => (
                                                                <>
                                                                    <section className='section'>
                                                                        <h2 className='title'>
                                                                            {process.name} 
                                                                        </h2>
                                                                        <br/>
                                                                        <h3 className='section_title'>
                                                                            Identification des tâches de chaque processus métiers <br/><strong className='color-green'>Rappel : une tâche est une activité élémentaire à effectuer</strong>
                                                                        </h3>
                                                                        <div className='section_body'>
                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_label'>
                                                                                    <label htmlFor={"Quel est l'événement qui déclenche la réalisation du processus ?:" + process.name}>
                                                                                        Quel est l'événement qui déclenche la réalisation du processus ?
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input placeholder="Décrivez le plus fidèlement possible l'événement qui subvient pour déclencher le début du processus" 
                                                                                        value={process.taskIdentification.eventListener} type='text' id={"Quel est l'événement qui déclenche la réalisation du processus ?"+process.name} name={"Quel est l'événement qui déclenche la réalisation du processus ?"+process.name}
                                                                                        onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_subLabel'>
                                                                                    <label>
                                                                                        Ou choisissez celui qui correspond le mieux
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Générique : Début automatique du processus" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Générique : Début automatique du processus"
                                                                                        onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Générique : Début automatique du processus">Générique : Début automatique du processus</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Message : Arrivé d'un message d'un autre processus" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Message : Arrivé d'un message d'un autre processus"
                                                                                        onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Message : Arrivé d'un message d'un autre processus">Message : Arrivé d'un message d'un autre processus</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Temporisateur : Début à un moment temporelle donné" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Temporisateur : Début à un moment temporelle donné"
                                                                                        onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Temporisateur : Début à un moment temporelle donné">Temporisateur : Début à un moment temporelle donné</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Conditionnel : Début conditionné à la réalisation d'un événement" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Conditionnel : Début conditionné à la réalisation d'un événement"
                                                                                        onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Conditionnel : Début conditionné à la réalisation d'un événement">Conditionnel : Début conditionné à la réalisation d'un événement</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Signal : Début à la réception d'un signal d'un autre processus" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Signal : Début à la réception d'un signal d'un autre processus"
                                                                                        onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Signal : Début à la réception d'un signal d'un autre processus">Signal : Début à la réception d'un signal d'un autre processus</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Parallèle : Début conditionné à de nombreux événements à prendre en compte" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Parallèle : Début conditionné à de nombreux événements à prendre en compte"
                                                                                        onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Parallèle : Début conditionné à de nombreux événements à prendre en compte">Parallèle : Début conditionné à de nombreux événements à prendre en compte</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Multiple : Début conditionné à la réalisation simultanée de plusieurs événements" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Multiple : Début conditionné à la réalisation simultanée de plusieurs événements"
                                                                                        onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Multiple : Début conditionné à la réalisation simultanée de plusieurs événements">Multiple : Début conditionné à la réalisation simultanée de plusieurs événements</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Autre" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Autre"
                                                                                        onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Autre">Autre</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_label'>
                                                                                    <label htmlFor={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?:" + process.name}>
                                                                                        Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input placeholder="Décrivez l'événement qui survient à la fin du processus" 
                                                                                        value={process.taskIdentification.end_eventListener} type='text' id={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?"+process.name} name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?"+process.name}
                                                                                        onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.end_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_subLabel'>
                                                                                    <label>
                                                                                        Ou Choisissez celui qui correspond le mieux
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus">Fin d'un processus</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus composé de plusieurs modules de finition" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus composé de plusieurs modules de finition"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus composé de plusieurs modules de finition">Fin d'un processus composé de plusieurs modules de finition</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant l'émission d'un message à envoyer à un autre processus" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus déclenchant l'émission d'un message à envoyer à un autre processus"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant l'émission d'un message à envoyer à un autre processus">Fin d'un processus déclenchant l'émission d'un message à envoyer à un autre processus</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant l'attribution d'une tâche à une entité" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus déclenchant l'attribution d'une tâche à une entité"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant l'attribution d'une tâche à une entité">Fin d'un processus déclenchant l'attribution d'une tâche à une entité</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus initiant l'émission d'un signal pour d'autres processus" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus initiant l'émission d'un signal pour d'autres processus"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus initiant l'émission d'un signal pour d'autres processus">Fin d'un processus initiant l'émission d'un signal pour d'autres processus</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant un message d'erreur" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus déclenchant un message d'erreur"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant un message d'erreur">Fin d'un processus déclenchant un message d'erreur</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin de traitement de tout le processus par suite de détection d'une incohérence grave" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin de traitement de tout le processus par suite de détection d'une incohérence grave"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin de traitement de tout le processus par suite de détection d'une incohérence grave">Fin de traitement de tout le processus par suite de détection d'une incohérence grave</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin de traitement d'un processus en déclenchant le traitement d'un processus de remédiation" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin de traitement d'un processus en déclenchant le traitement d'un processus de remédiation"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin de traitement d'un processus en déclenchant le traitement d'un processus de remédiation">Fin de traitement d'un processus en déclenchant le traitement d'un processus de remédiation</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Autre" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Autre"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Autre">Autre</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_label'>
                                                                                    <label htmlFor="Quelles sont les tâches à réaliser pour l'accomplissement du processus?">
                                                                                        Quelles sont les tâches à réaliser pour l'accomplissement du processus?
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_list'>
                                                                                    {
                                                                                        process.taskIdentification.taskList.map((task, taskIndex) => (
                                                                                            <div className='section_body_question_list_elt'>
                                                                                                <span className='section_body_question_list_elt_name'>{task.name}</span><span className='section_body_question_list_elt_x'
                                                                                                    onClick={(e) => {
                                                                                                        this._deleteMissionProcessTask(missionIndex, processIndex, taskIndex);
                                                                                                    }}
                                                                                                >×</span>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                                <div className='section_body_question_button'>
                                                                                    <span className='button_style_1' onClick={(e) => {
                                                                                        let taskName = prompt("Veuillez saisir le nom de la tâche :");
                                                                                        if(taskName) {
                                                                                            this._addMissionProcessTask(missionIndex, processIndex, taskName);
                                                                                        }
                                                                                    }}>Ajouter une tâche</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_label'>
                                                                                    <label htmlFor={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?:" + process.name}>
                                                                                        Qu'elles sont les liens qui existent entre les tâches élémentaires ?
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input placeholder="Décrivez les liens qui existent entre les tâches."
                                                                                        value={process.taskIdentification.elementaryTaskLink} type='text' id={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?"+process.name} name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?"+process.name}
                                                                                        onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.elementaryTaskLink = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_subLabel'>
                                                                                    <label>
                                                                                        Ou Choisissez celui qui correspond le mieux
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                        let missionList = this.state.missionList;
                                                                                        missionList[missionIndex].process.processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                                                        this.setState({
                                                                                            missionList: missionList,
                                                                                        });
                                                                                    }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Exclusif : le traitement ne suit que l'une seule des possibilités. Le traitement peut aussi être confié à une autre partie prenante qui en avait précédemment la charge" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Exclusif : le traitement ne suit que l'une seule des possibilités. Le traitement peut aussi être confié à une autre partie prenante qui en avait précédemment la charge"/>
                                                                                    <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Exclusif : le traitement ne suit que l'une seule des possibilités. Le traitement peut aussi être confié à une autre partie prenante qui en avait précédemment la charge">Exclusif : le traitement ne suit que l'une seule des possibilités. Le traitement peut aussi être confié à une autre partie prenante qui en avait précédemment la charge</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                        let missionList = this.state.missionList;
                                                                                        missionList[missionIndex].process.processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                                                        this.setState({
                                                                                            missionList: missionList,
                                                                                        });
                                                                                    }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Exclusif basé sur les évènements : existence de multiples évènements dont l'un seul à la fois peut subvenir comme entré d'un processus" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Exclusif basé sur les évènements : existence de multiples évènements dont l'un seul à la fois peut subvenir comme entré d'un processus"/>
                                                                                    <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Exclusif basé sur les évènements : existence de multiples évènements dont l'un seul à la fois peut subvenir comme entré d'un processus">Exclusif basé sur les évènements : existence de multiples évènements dont l'un seul à la fois peut subvenir comme entré d'un processus</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                        let missionList = this.state.missionList;
                                                                                        missionList[missionIndex].process.processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                                                        this.setState({
                                                                                            missionList: missionList,
                                                                                        });
                                                                                    }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Inclusif : le traitement dépend de plusieurs évènements qui peuvent se réaliser" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Inclusif : le traitement dépend de plusieurs évènements qui peuvent se réaliser"/>
                                                                                    <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Inclusif : le traitement dépend de plusieurs évènements qui peuvent se réaliser">Inclusif : le traitement dépend de plusieurs évènements qui peuvent se réaliser</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                        let missionList = this.state.missionList;
                                                                                        missionList[missionIndex].process.processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                                                        this.setState({
                                                                                            missionList: missionList,
                                                                                        });
                                                                                    }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Parallèle : le traitement se divise en plusieurs traitements qui peuvent se réaliser simultanément" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Parallèle : le traitement se divise en plusieurs traitements qui peuvent se réaliser simultanément"/>
                                                                                    <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Parallèle : le traitement se divise en plusieurs traitements qui peuvent se réaliser simultanément">Parallèle : le traitement se divise en plusieurs traitements qui peuvent se réaliser simultanément</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                        let missionList = this.state.missionList;
                                                                                        missionList[missionIndex].process.processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                                                        this.setState({
                                                                                            missionList: missionList,
                                                                                        });
                                                                                    }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Initiale parallèle : le processus démarre à partir de plusieurs évènements" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Initiale parallèle : le processus démarre à partir de plusieurs évènements"/>
                                                                                    <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Initiale parallèle : le processus démarre à partir de plusieurs évènements">Initiale parallèle : le processus démarre à partir de plusieurs évènements</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                        let missionList = this.state.missionList;
                                                                                        missionList[missionIndex].process.processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                                                        this.setState({
                                                                                            missionList: missionList,
                                                                                        });
                                                                                    }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Synchronisation inclusive : plusieurs traitements se regroupent en ce point pour constituer une entrée d'un processus" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Synchronisation inclusive : plusieurs traitements se regroupent en ce point pour constituer une entrée d'un processus"/>
                                                                                    <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Synchronisation inclusive : plusieurs traitements se regroupent en ce point pour constituer une entrée d'un processus">Synchronisation inclusive : plusieurs traitements se regroupent en ce point pour constituer une entrée d'un processus</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                        let missionList = this.state.missionList;
                                                                                        missionList[missionIndex].process.processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                                                        this.setState({
                                                                                            missionList: missionList,
                                                                                        });
                                                                                    }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Autre" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Autre"/>
                                                                                    <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Autre">Autre</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_label'>
                                                                                    <label htmlFor={"Dans la chaîne de réalisation de chacune de vos missions, quelles sont les problèmes, difficultés et préoccupations que vous rencontrés ?:" + process.name}>
                                                                                        Dans la chaîne de réalisation de chacune de vos missions, quelles sont les problèmes, difficultés et préoccupations que vous rencontrés ?
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input placeholder="Décrivez les liens qui existent entre les tâches."
                                                                                        value={process.taskIdentification.missionProblems} type='text' id={"Dans la chaîne de réalisation de chacune de vos missions, quelles sont les problèmes, difficultés et préoccupations que vous rencontrés ?"+process.name} name={"Dans la chaîne de réalisation de chacune de vos missions, quelles sont les problèmes, difficultés et préoccupations que vous rencontrés ?"+process.name}
                                                                                        onChange={(e)=>{
                                                                                            let missionList = this.state.missionList;
                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.missionProblems = e.target.value;
                                                                                            this.setState({
                                                                                                missionList: missionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <h2 className='section_title'>
                                                                            Modélisation Modélisation des tâches des processus métiers de l'agent
                                                                        </h2>
                                                                        {/* Iterration sur les taches */}
                                                                        {
                                                                                process.taskIdentification.taskList.map((task, taskIndex) => (
                                                                                    <>
                                                                                        <section className='section'>
                                                                                            <h2 className='title'>
                                                                                                {task.name} 
                                                                                            </h2>
                                                                                            <br/>
                                                                                            <h3 className='section_title'>
                                                                                                Précision sur la durée de la tâche à la réalisation de la tâche
                                                                                            </h3>
                                                                                            <div className='section_body'>
                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Comment mesurez-vous l'avancement dans la réalisation de cette tâche ?:" + task.name}>
                                                                                                            Comment mesurez-vous l'avancement dans la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <textarea id={"Comment mesurez-vous l'avancement dans la réalisation de cette tâche ?:" + task.name} name={"Comment mesurez-vous l'avancement dans la réalisation de cette tâche ?:" + task.name}
                                                                                                            value={task.taskDuration.progressionWeightUnit}
                                                                                                            onChange={(e)=>{
                                                                                                                let missionList = this.state.missionList;
                                                                                                                missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.progressionWeightUnit = e.target.value;
                                                                                                                this.setState({
                                                                                                                    missionList: missionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelle est la durée prévue pour la réalisation de cette tâche ?"+task.name}>
                                                                                                            Quelle est la durée prévue pour la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.taskDurationValue = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskDuration.taskDurationValue} type='text' id={"Quelle est la durée prévue pour la réalisation de cette tâche ?"+task.name} name={"Quelle est la durée prévue pour la réalisation de cette tâche ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quel est le temps moyen que vous passez à réaliser la tâche ?"+task.name}>
                                                                                                            Quel est le temps moyen que vous passez à réaliser la tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.averageTaskTimeValue = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskDuration.averageTaskTimeValue} type='text' id={"Quel est le temps moyen que vous passez à réaliser la tâche ?"+task.name} name={"Quel est le temps moyen que vous passez à réaliser la tâche ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            Observez-vous des retards dans la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input type='radio' placeholder='' id={"Observez-vous des retards dans la réalisation de cette tâche ?__oui"+task.name} name={"Observez-vous des retards dans la réalisation de cette tâche ?"+task.name} 
                                                                                                            value={"oui"}
                                                                                                            onChange={(e)=>{
                                                                                                                let missionList = this.state.missionList;
                                                                                                                missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.taskLateness = e.target.value;
                                                                                                                this.setState({
                                                                                                                    missionList: missionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                        <label htmlFor={"Observez-vous des retards dans la réalisation de cette tâche ?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input type='radio' placeholder='' id={"Observez-vous des retards dans la réalisation de cette tâche ?__non" + task.name} name={"Observez-vous des retards dans la réalisation de cette tâche ?"+task.name} 
                                                                                                            value={"non"}
                                                                                                            onChange={(e)=>{
                                                                                                                let missionList = this.state.missionList;
                                                                                                                missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.taskLateness = e.target.value;
                                                                                                                this.setState({
                                                                                                                    missionList: missionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                        <label htmlFor={"Observez-vous des retards dans la réalisation de cette tâche ?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Qu'elles sont les raisons qui selon-vous engendrent des retards ?"+task.name}>
                                                                                                            Qu'elles sont les raisons qui selon-vous engendrent des retards ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.taskLatenessRaison = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskDuration.taskLatenessRaison} type='text' id={"Qu'elles sont les raisons qui selon-vous engendrent des retards ?"+task.name} name={"Qu'elles sont les raisons qui selon-vous engendrent des retards ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Comment les éviter ?"+task.name}>
                                                                                                            Comment les éviter ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.taskLatenessSolution = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskDuration.taskLatenessSolution} type='text' id={"Comment les éviter ?"+task.name} name={"Comment les éviter ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quels sont les coûts (financiers, matériels, humains, ...) engendrés par des retards sur la réalisation de cette tâche ?"+task.name}>
                                                                                                            Quels sont les coûts (financiers, matériels, humains, ...) engendrés par des retards sur la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.taskLatenessCoast = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskDuration.taskLatenessCoast} type='text' id={"Quels sont les coûts (financiers, matériels, humains, ...) engendrés par des retards sur la réalisation de cette tâche ?"+task.name} name={"Quels sont les coûts (financiers, matériels, humains, ...) engendrés par des retards sur la réalisation de cette tâche ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </section>


                                                                                        <section className='section'>
                                                                                            <h3 className='section_title'>
                                                                                                Précision sur les ressources méthodologiques nécessaires à la réalisation de la tâche
                                                                                            </h3>
                                                                                            <div className='section_body'>
                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={'Quelles sont les ressources méthodologiques qui sont nécessaires à la réalisation de cette tâche ?:' + task.name}>
                                                                                                            Quelles sont les ressources méthodologiques qui sont nécessaires à la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <textarea id={'Quelles sont les ressources méthodologiques qui sont nécessaires à la réalisation de cette tâche ?:' + task.name} name={'Quelles sont les ressources méthodologiques qui sont nécessaires à la réalisation de cette tâche ?:' + task.name}
                                                                                                            value={task.taskMetResource.taskMetResourceValue}
                                                                                                            onChange={(e)=>{
                                                                                                                let missionList = this.state.missionList;
                                                                                                                missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskMetResource.taskMetResourceValue = e.target.value;
                                                                                                                this.setState({
                                                                                                                    missionList: missionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les règles métiers à appliquer pour valider la tâche ou les résultats produits ?"+task.name}>
                                                                                                            Quelles sont les règles métiers à appliquer pour valider la tâche ou les résultats produits ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskMetResource.taskRulJob = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskMetResource.taskRulJob} placholder="Préciser ces règles" type='text' id={"Quelles sont les règles métiers à appliquer pour valider la tâche ou les résultats produits ?"+task.name} name={"Quelles sont les règles métiers à appliquer pour valider la tâche ou les résultats produits ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les contraintes opérationnelles à observer pour la réalisation de la tâche ?"+task.name}>
                                                                                                            Quelles sont les contraintes opérationnelles à observer pour la réalisation de la tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskMetResource.taskOperationalProblems = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskMetResource.taskOperationalProblems} type='text' id={"Quelles sont les contraintes opérationnelles à observer pour la réalisation de la tâche ?"+task.name} name={"Quelles sont les contraintes opérationnelles à observer pour la réalisation de la tâche ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            Est ce que la tâche fait intervenir plusieurs équipes différentes ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input type='radio' placeholder='' id={"Est ce que la tâche fait intervenir plusieurs équipes différentes ?__oui"+task.name} name={"Est ce que la tâche fait intervenir plusieurs équipes différentes ?"+task.name} 
                                                                                                            value={"oui"}
                                                                                                            onChange={(e)=>{
                                                                                                                let missionList = this.state.missionList;
                                                                                                                missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskMetResource.taskDifferentCrew = e.target.value;
                                                                                                                this.setState({
                                                                                                                    missionList: missionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                        <label htmlFor={"Est ce que la tâche fait intervenir plusieurs équipes différentes ?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input type='radio' placeholder='' id={"Est ce que la tâche fait intervenir plusieurs équipes différentes ?__non" + task.name} name={"Est ce que la tâche fait intervenir plusieurs équipes différentes ?"+task.name} 
                                                                                                            value={"non"}
                                                                                                            onChange={(e)=>{
                                                                                                                let missionList = this.state.missionList;
                                                                                                                missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskMetResource.taskDifferentCrew = e.target.value;
                                                                                                                this.setState({
                                                                                                                    missionList: missionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                        <label htmlFor={"Est ce que la tâche fait intervenir plusieurs équipes différentes ?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Y-a-t-il des règles méthodologiques à observer dans les échanges entre les équipes qui interviennent dans la réalisation de cette tâche ?" + task.name}>
                                                                                                            Y-a-t-il des règles méthodologiques à observer dans les échanges entre les équipes qui interviennent dans la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskMetResource.taskMetRulExchange = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskMetResource.taskMetRulExchange} type='text' id={"Y-a-t-il des règles méthodologiques à observer dans les échanges entre les équipes qui interviennent dans la réalisation de cette tâche ?" + task.name} name={"Y-a-t-il des règles méthodologiques à observer dans les échanges entre les équipes qui interviennent dans la réalisation de cette tâche ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </section>

                                                                                        <section className='section'>
                                                                                            <h3 className='section_title'>
                                                                                                Précision sur les ressources technologiques et matérielles
                                                                                            </h3>
                                                                                            <div className='section_body'>
                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={'Quelles sont les équipements infractructurelles qui sont nécessaires à la réalisation de cette tâche ?:' + task.name}>
                                                                                                            Quelles sont les équipements infractructurelles qui sont nécessaires à la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <textarea placeholder="En termes d'équipements matériels de réseaux informatiques et internet, ordinateurs, tablettes, smartpones, scanner, imprimante, ordinateur, téléphone, ..." id={'Quelles sont les équipements infractructurelles qui sont nécessaires à la réalisation de cette tâche ?:' + task.name} name={'Quelles sont les équipements infractructurelles qui sont nécessaires à la réalisation de cette tâche ?:' + task.name} 
                                                                                                            value={task.taskTechResource.taskInfraEquipment}
                                                                                                            onChange={(e)=>{
                                                                                                                let missionList = this.state.missionList;
                                                                                                                missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskTechResource.taskInfraEquipment = e.target.value;
                                                                                                                this.setState({
                                                                                                                    missionList: missionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={'Quelles sont les équipements logicielles qui sont nécessaires à la réalisation de cette tâche ?:' + task.name}>
                                                                                                            Quelles sont les équipements logicielles qui sont nécessaires à la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <textarea placeholder="En termes de Système d'Information, de progiciels (ERP, CRM, ...), applicaitons informatiques, etc." id={'Quelles sont les équipements logicielles qui sont nécessaires à la réalisation de cette tâche ?:' + task.name} name={'Quelles sont les équipements logicielles qui sont nécessaires à la réalisation de cette tâche ?:' + task.name} 
                                                                                                            value={task.taskTechResource.taskLogicEquipment}
                                                                                                            onChange={(e)=>{
                                                                                                                let missionList = this.state.missionList;
                                                                                                                missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskTechResource.taskLogicEquipment = e.target.value;
                                                                                                                this.setState({
                                                                                                                    missionList: missionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les autres ressources technologiques qui sont nécessaires à la réalisation de cette tâche ?"+task.name}>
                                                                                                            Quelles sont les autres ressources technologiques qui sont nécessaires à la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskTechResource.taskOtherTechResource = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskTechResource.taskOtherTechResource} placeholder='Préciser les ressources qui sont différentes des équipements matériels et logiciels, comme par exemple les éuipements de sécurité' type='text' id={"Quelles sont les autres ressources technologiques qui sont nécessaires à la réalisation de cette tâche ?"+task.name} name={"Quelles sont les autres ressources technologiques qui sont nécessaires à la réalisation de cette tâche ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les autres ressources matérielles qui sont nécessaires à la réalisation de cette tâche?"+task.name}>
                                                                                                            Quelles sont les autres ressources matérielles qui sont nécessaires à la réalisation de cette tâche?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskTechResource.taskOtherMaterialResource = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskTechResource.taskOtherMaterialResource} placeholder="En termes de ressources logistiques, les salles, les équipements bureautiques, etc." type='text' id={"Quelles sont les autres ressources matérielles qui sont nécessaires à la réalisation de cette tâche?"+task.name} name={"Quelles sont les autres ressources matérielles qui sont nécessaires à la réalisation de cette tâche?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </section>

                                                                                        <section className='section'>
                                                                                            <h3 className='section_title'>
                                                                                                Autres ressources nécessaires à la réalisation de la tâche
                                                                                            </h3>
                                                                                            <div className='section_body'>
                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={'Quelles sont les autres ressources nécessaires à la réalisation de cette tâche ?:' + task.name}>
                                                                                                            Quelles sont les autres ressources nécessaires à la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <textarea id={'Quelles sont les autres ressources nécessaires à la réalisation de cette tâche ?:' + task.name} name={'Quelles sont les autres ressources nécessaires à la réalisation de cette tâche ?:' + task.name}
                                                                                                            value={task.taskOthersResource.taskOthersResourceValue}
                                                                                                            onChange={(e)=>{
                                                                                                                let missionList = this.state.missionList;
                                                                                                                missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskOthersResource.taskOthersResourceValue = e.target.value;
                                                                                                                this.setState({
                                                                                                                    missionList: missionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            Toutes ces ressources sont elles disponibles ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input type='radio' placeholder='' id={"Toutes ces ressources sont elles disponibles ?__oui"+task.name} name={"Toutes ces ressources sont elles disponibles ?"+task.name} 
                                                                                                            value={"oui"}
                                                                                                            onChange={(e)=>{
                                                                                                                let missionList = this.state.missionList;
                                                                                                                missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskOthersResource.taskAllOthersResourceAvailable = e.target.value;
                                                                                                                this.setState({
                                                                                                                    missionList: missionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                        <label htmlFor={"Toutes ces ressources sont elles disponibles ?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input type='radio' placeholder='' id={"Toutes ces ressources sont elles disponibles ?__non" + task.name} name={"Toutes ces ressources sont elles disponibles ?"+task.name} 
                                                                                                            value={"non"}
                                                                                                            onChange={(e)=>{
                                                                                                                let missionList = this.state.missionList;
                                                                                                                missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskOthersResource.taskAllOthersResourceAvailable = e.target.value;
                                                                                                                this.setState({
                                                                                                                    missionList: missionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                        <label htmlFor={"Toutes ces ressources sont elles disponibles ?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Qu'est-ce qu'il faudrait ?" + task.name}>
                                                                                                            Qu'est-ce qu'il faudrait ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskOthersResource.taskOthersResourceSolution = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskOthersResource.taskOthersResourceSolution} type='text' id={"Qu'est-ce qu'il faudrait ?" + task.name} name={"Qu'est-ce qu'il faudrait ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </section>

                                                                                        <section className='section'>
                                                                                            <h3 className='section_title'>
                                                                                                Précision sur les échanges de données
                                                                                            </h3>
                                                                                            <div className='section_body'>
                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={'Quels sont les messages à envoyer ou à recevoir ?:' + task.name}>
                                                                                                            Quels sont les messages à envoyer ou à recevoir ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <textarea placeholder='Préciser clairement la nature et les contenus types de ces messages' id={'Quels sont les messages à envoyer ou à recevoir ?:' + task.name} name={'Quels sont les messages à envoyer ou à recevoir ?:' + task.name} 
                                                                                                            value={task.taskDataExchange.messageToSendAndReceive}
                                                                                                            onChange={(e)=>{
                                                                                                                let missionList = this.state.missionList;
                                                                                                                missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDataExchange.messageToSendAndReceive = e.target.value;
                                                                                                                this.setState({
                                                                                                                    missionList: missionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quels sont les formulaires à saisir ?" + task.name}>
                                                                                                            Quels sont les formulaires à saisir ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDataExchange.forms = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskDataExchange.forms} type='text' id={"Quels sont les formulaires à saisir ?" + task.name} name={"Quels sont les formulaires à saisir ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </section>

                                                                                        <section className='section'>
                                                                                            <h3 className='section_title'>
                                                                                                Tâche manuelle ou automatisée
                                                                                            </h3>
                                                                                            <div className='section_body'>
                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            La tâche est-elle actuellement exécutée manuellement ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsManual = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={"oui"} type='radio' placeholder='' id={"La tâche est-elle actuellement exécutée manuellement ?__oui"+task.name} name={"La tâche est-elle actuellement exécutée manuellement ?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle actuellement exécutée manuellement ?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsManual = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={"non"} type='radio' placeholder='' id={"La tâche est-elle actuellement exécutée manuellement ?__non" + task.name} name={"La tâche est-elle actuellement exécutée manuellement ?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle actuellement exécutée manuellement ?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            La tâche est-elle exécutée par des agents internes au service?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsMakeByInternAgents = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={"oui"} type='radio' placeholder='' id={"La tâche est-elle exécutée par des agents internes au service?__oui"+task.name} name={"La tâche est-elle exécutée par des agents internes au service?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée par des agents internes au service?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsMakeByInternAgents = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={"non"} type='radio' placeholder='' id={"La tâche est-elle exécutée par des agents internes au service?__non" + task.name} name={"La tâche est-elle exécutée par des agents internes au service?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée par des agents internes au service?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les agents internes qui interviennent dans la réalisation de la tâche ?" + task.name}>
                                                                                                            Quelles sont les agents internes qui interviennent dans la réalisation de la tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskInternAgents = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskManualAuto.taskInternAgents} placeholder="Préciser lesquelles" type='text' id={"Quelles sont les agents internes qui interviennent dans la réalisation de la tâche ?" + task.name} name={"Quelles sont les agents internes qui interviennent dans la réalisation de la tâche ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            La tâche est-elle exécutée par des agents externes au service?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsMakeByExternalAgents = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={"oui"} type='radio' placeholder='' id={"La tâche est-elle exécutée par des agents externes au service?__oui"+task.name} name={"La tâche est-elle exécutée par des agents externes au service?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée par des agents externes au service?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsMakeByExternalAgents = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={"non"} type='radio' placeholder='' id={"La tâche est-elle exécutée par des agents externes au service?__non" + task.name} name={"La tâche est-elle exécutée par des agents externes au service?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée par des agents externes au service?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les agents externes qui interviennent dans la réalisation de la tâche ?" + task.name}>
                                                                                                            Quelles sont les agents externes qui interviennent dans la réalisation de la tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskExternalAgents = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskManualAuto.taskExternalAgents} placeholder="Préciser lesquelles" type='text' id={"Quelles sont les agents externes qui interviennent dans la réalisation de la tâche ?" + task.name} name={"Quelles sont les agents externes qui interviennent dans la réalisation de la tâche ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            La tâche est-elle exécutée par des acteurs externes au service ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsMakeByExternalActors = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={"oui"} type='radio' placeholder='' id={"La tâche est-elle exécutée par des acteurs externes au service ?__oui"+task.name} name={"La tâche est-elle exécutée par des acteurs externes au service ?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée par des acteurs externes au service ?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsMakeByExternalActors = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={"non"} type='radio' placeholder='' id={"La tâche est-elle exécutée par des acteurs externes au service ?__non" + task.name} name={"La tâche est-elle exécutée par des acteurs externes au service ?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée par des acteurs externes au service ?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les acteurs externes qui interviennent dans la réalisation de la tâche ?" + task.name}>
                                                                                                            Quelles sont les acteurs externes qui interviennent dans la réalisation de la tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskExternalActors = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskManualAuto.taskExternalActors} placeholder="Préciser lesquelles" type='text' id={"Quelles sont les acteurs externes qui interviennent dans la réalisation de la tâche ?" + task.name} name={"Quelles sont les acteurs externes qui interviennent dans la réalisation de la tâche ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            La tâche est-elle exécutée à partir de ressources informatiques ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskHasInformatiqueResource = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={"oui"} type='radio' placeholder='' id={"La tâche est-elle exécutée à partir de ressources informatiques ?__oui"+task.name} name={"La tâche est-elle exécutée à partir de ressources informatiques ?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée à partir de ressources informatiques ?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskHasInformatiqueResource = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={"non"} type='radio' placeholder='' id={"La tâche est-elle exécutée à partir de ressources informatiques ?__non" + task.name} name={"La tâche est-elle exécutée à partir de ressources informatiques ?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée à partir de ressources informatiques ?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les ressources informatiques qui sont utilisées dans la réalisation de la tâche ?" + task.name}>
                                                                                                            Quelles sont les ressources informatiques qui sont utilisées dans la réalisation de la tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskInformatiqueResource = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskManualAuto.taskInformatiqueResource} placeholder="Préciser lesquelles" type='text' id={"Quelles sont les ressources informatiques qui sont utilisées dans la réalisation de la tâche ?" + task.name} name={"Quelles sont les ressources informatiques qui sont utilisées dans la réalisation de la tâche ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </section>

                                                                                        <section className='section'>
                                                                                            <h3 className='section_title'>
                                                                                                Précisions sur les problèmes rencontrés
                                                                                            </h3>
                                                                                            <div className='section_body'>
                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={'Quels sont les problèmes que vous rencontrez dans la réalisation de cette tâche ?:' + task.name}>
                                                                                                            Quels sont les problèmes que vous rencontrez dans la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <textarea placeholder='Préciser clairement la nature et les contenus types de ces messages' id={'Quels sont les problèmes que vous rencontrez dans la réalisation de cette tâche ?:' + task.name} name={'Quels sont les problèmes que vous rencontrez dans la réalisation de cette tâche ?:' + task.name} 
                                                                                                            value={task.taskProblems.taskProblemsValue}
                                                                                                            onChange={(e)=>{
                                                                                                                let missionList = this.state.missionList;
                                                                                                                missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskProblems.taskProblemsValue = e.target.value;
                                                                                                                this.setState({
                                                                                                                    missionList: missionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Comment procédez-vous pour résoudre ces problèmes ?" + task.name}>
                                                                                                            Comment procédez-vous pour résoudre ces problèmes ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskProblems.taskProblemsSolutions = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskProblems.taskProblemsSolutions} type='text' id={"Comment procédez-vous pour résoudre ces problèmes ?" + task.name} name={"Comment procédez-vous pour résoudre ces problèmes ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Comment pensez-vous qu'on pourrait procéder pour régler définitivement ces problèmes ?" + task.name}>
                                                                                                            Comment pensez-vous qu'on pourrait procéder pour régler définitivement ces problèmes ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskProblems.taskProblemsDefinitiveSolution = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskProblems.taskProblemsDefinitiveSolution} type='text' id={"Comment pensez-vous qu'on pourrait procéder pour régler définitivement ces problèmes ?" + task.name} name={"Comment pensez-vous qu'on pourrait procéder pour régler définitivement ces problèmes ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Autres commentaires" + task.name}>
                                                                                                            Autres commentaires
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let missionList = this.state.missionList;
                                                                                                            missionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskProblems.taskProblemsOthersComments = e.target.value;
                                                                                                            this.setState({
                                                                                                                missionList: missionList,
                                                                                                            });
                                                                                                        }} value={task.taskProblems.taskProblemsOthersComments} type='text' id={"Autres commentaires" + task.name} name={"Autres commentaires" + task.name}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </section>
                                                                                    </>
                                                                                ))
                                                                        }
                                                                    </section>
                                                                </>
                                                            ))
                                                        }
                                                    </section>
                                            </section>
                                        </>
                                    ))
                                }

                                {/* Other missions */}
                                {
                                    this.state.otherMissionList.map((mission, missionIndex) => (
                                        <>
                                            <section className='section'>
                                                <h2 className='title'>
                                                    {mission.name} 
                                                </h2>
                                                <br/>
                                                <h3 className='section_title'>
                                                    Description de la mission
                                                </h3>
                                                <div className='section_body'>
                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={'Décrivez la mission:' + mission.name}>
                                                                Décrivez la mission
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <textarea id={'Décrivez la mission:' + mission.name} name={'Décrivez la mission:' + mission.name} value={mission.description.description}
                                                                onChange={(e)=>{
                                                                    let otherMissionList = this.state.otherMissionList;
                                                                    otherMissionList[missionIndex].description.description = e.target.value;
                                                                    this.setState({
                                                                        otherMissionList: otherMissionList,
                                                                    });
                                                                }}
                                                            ></textarea>
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={"Quels sont les conditions qu'il faut avoir pour convenablement conduire la mission?"+mission.name}>
                                                                Quels sont les conditions qu'il faut avoir pour convenablement conduire la mission?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input value={mission.description.condition} type='text' id={"Quels sont les conditions qu'il faut avoir pour convenablement conduire la mission?"+mission.name} name={"Quels sont les conditions qu'il faut avoir pour convenablement conduire la mission?"+mission.name}
                                                                onChange={(e)=>{
                                                                    let otherMissionList = this.state.otherMissionList;
                                                                    otherMissionList[missionIndex].description.condition = e.target.value;
                                                                    this.setState({
                                                                        otherMissionList: otherMissionList,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label>
                                                                Ces conditions sont-elles réunies ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input type='radio' placeholder='' id={"Ces conditions sont-elles réunies ?__oui"+mission.name}
                                                                name={"Ces conditions sont-elles réunies ?"+mission.name}
                                                                value={"oui"}
                                                                onChange={(e)=>{
                                                                    let otherMissionList = this.state.otherMissionList;
                                                                    otherMissionList[missionIndex].description.allConditionsSatisfy = document.forms["submissionForm"]["Ces conditions sont-elles réunies ?"+mission.name].value;
                                                                    this.setState({
                                                                        otherMissionList: otherMissionList,
                                                                    });
                                                                    // console.log(otherMissionList[missionIndex].description.allConditionsSatisfy);
                                                                }}
                                                            />
                                                            <label htmlFor={"Ces conditions sont-elles réunies ?__oui"+mission.name}>Oui</label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input type='radio' placeholder='' id={"Ces conditions sont-elles réunies ?__non" + mission.name}
                                                                name={"Ces conditions sont-elles réunies ?"+mission.name}
                                                                value={"non"}
                                                                onChange={(e)=>{
                                                                    let otherMissionList = this.state.otherMissionList;
                                                                    otherMissionList[missionIndex].description.allConditionsSatisfy = document.forms["submissionForm"]["Ces conditions sont-elles réunies ?"+mission.name].value;
                                                                    this.setState({
                                                                        otherMissionList: otherMissionList,
                                                                    });

                                                                    // console.log(otherMissionList[missionIndex].description.allConditionsSatisfy);
                                                                }}
                                                            />
                                                            <label htmlFor={"Ces conditions sont-elles réunies ?__non" + mission.name}>Non</label>
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={"Que faudrait-il ?"+mission.name}>
                                                                Que faudrait-il ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input value={mission.description.whatMake} type='text' id={"Que faudrait-il ?"+mission.name} name={"Que faudrait-il ?"+mission.name}
                                                                onChange={(e)=>{
                                                                    let otherMissionList = this.state.otherMissionList;
                                                                    otherMissionList[missionIndex].description.whatMake = e.target.value;
                                                                    this.setState({
                                                                        otherMissionList: otherMissionList,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={"Quels sont les principaux résultats attendus à la fin de l'exécution de la mission ?" + mission.name}>
                                                                Quels sont les principaux résultats attendus à la fin de l'exécution de la mission ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input value={mission.description.resultWaiting} type='text' id={"Quels sont les principaux résultats attendus à la fin de l'exécution de la mission ?" + mission.name} name={"Quels sont les principaux résultats attendus à la fin de l'exécution de la mission ?" + mission.name}
                                                                onChange={(e)=>{
                                                                    let otherMissionList = this.state.otherMissionList;
                                                                    otherMissionList[missionIndex].description.resultWaiting = e.target.value;
                                                                    this.setState({
                                                                        otherMissionList: otherMissionList,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <h3 className='section_title'>
                                                    Acteurs qui interviennent dans la réalisation de la mission
                                                </h3>
                                                <div className='section_body'>
                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={'Quels sont les agents impliqués dans la réalisation de ces missions ?:' + mission.name}>
                                                                Quels sont les agents impliqués dans la réalisation de ces missions ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <textarea id={'Quels sont les agents impliqués dans la réalisation de ces missions ?:' + mission.name} name={'Quels sont les agents impliqués dans la réalisation de ces missions ?:' + mission.name} value={mission.actors.agents}
                                                                onChange={(e)=>{
                                                                    let otherMissionList = this.state.otherMissionList;
                                                                    otherMissionList[missionIndex].actors.agents = e.target.value;
                                                                    this.setState({
                                                                        otherMissionList: otherMissionList,
                                                                    });
                                                                }}
                                                            ></textarea>
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={"Quels sont les rôles de chacun de ces agents dans la réalisation de ces missions ?"+mission.name}>
                                                                Quels sont les rôles de chacun de ces agents dans la réalisation de ces missions ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input value={mission.actors.roles} type='text' id={"Quels sont les rôles de chacun de ces agents dans la réalisation de ces missions ?"+mission.name} name={"Quels sont les rôles de chacun de ces agents dans la réalisation de ces missions ?"+mission.name}
                                                                onChange={(e)=>{
                                                                    let otherMissionList = this.state.otherMissionList;
                                                                    otherMissionList[missionIndex].actors.roles = e.target.value;
                                                                    this.setState({
                                                                        otherMissionList: otherMissionList,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={"Quels sont les acteurs externes (autres services, clients, ...) impliqués dans la réalisation de ces missions ?"+mission.name}>
                                                                Quels sont les acteurs externes (autres services, clients, ...) impliqués dans la réalisation de ces missions ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input value={mission.actors.externalActors} type='text' id={"Quels sont les acteurs externes (autres services, clients, ...) impliqués dans la réalisation de ces missions ?"+mission.name} name={"Quels sont les acteurs externes (autres services, clients, ...) impliqués dans la réalisation de ces missions ?"+mission.name}
                                                                onChange={(e)=>{
                                                                    let otherMissionList = this.state.otherMissionList;
                                                                    otherMissionList[missionIndex].actors.externalActors = e.target.value;
                                                                    this.setState({
                                                                        otherMissionList: otherMissionList,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor={"Quels sont les rôles de chacun de ces acteurs dans la réalisation de ces missions ?" + mission.name}>
                                                                Quels sont les rôles de chacun de ces acteurs dans la réalisation de ces missions ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input value={mission.actors.actorsRoles} type='text' id={"Quels sont les rôles de chacun de ces acteurs dans la réalisation de ces missions ?" + mission.name} name={"Quels sont les rôles de chacun de ces acteurs dans la réalisation de ces missions ?" + mission.name}
                                                                onChange={(e)=>{
                                                                    let otherMissionList = this.state.otherMissionList;
                                                                    otherMissionList[missionIndex].actors.actorsRoles = e.target.value;
                                                                    this.setState({
                                                                        otherMissionList: otherMissionList,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <br/>
                                                <div className='section_body_question'>
                                                    <div className='section_body_question_label'>
                                                        <label htmlFor={'Quels sont les différentes étapes de réalisation de votre mission ?:' + mission.name}>
                                                            Quels sont les différentes étapes de réalisation de votre mission ?
                                                        </label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <textarea placeholder='conception de document, graphiques, données produites, prise de décision, approbations, etc.' id={'Quels sont les différentes étapes de réalisation de votre mission ?:' + mission.name} name={'Quels sont les différentes étapes de réalisation de votre mission ?:' + mission.name} 
                                                            value={mission.actors.missionEtape}
                                                            onChange={(e)=>{
                                                                let otherMissionList = this.state.otherMissionList;
                                                                otherMissionList[missionIndex].actors.missionEtape = e.target.value;
                                                                this.setState({
                                                                    otherMissionList: otherMissionList,
                                                                });
                                                            }}
                                                        ></textarea>
                                                    </div>
                                                </div>

                                                <div className='section_body_question'>
                                                    <div className='section_body_question_label'>
                                                        <label htmlFor={"Quels sont les processus qui sont réalisés dans l'accomplissement de la mission ?" + mission.name}>
                                                            Quels sont les processus qui sont réalisés dans l'accomplissement de la mission ?
                                                        </label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input value={mission.actors.missionProcess} type='text' id={"Quels sont les processus qui sont réalisés dans l'accomplissement de la mission ?" + mission.name} name={"Quels sont les processus qui sont réalisés dans l'accomplissement de la mission ?" + mission.name}
                                                            onChange={(e)=>{
                                                                let otherMissionList = this.state.otherMissionList;
                                                                otherMissionList[missionIndex].actors.missionProcess = e.target.value;
                                                                this.setState({
                                                                    otherMissionList: otherMissionList,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <h3 className='section_title'>
                                                    Identification des processus métiers
                                                </h3>
                                                <div className='section_body'>
                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor='Quels sont les processus dont vous avez la charge pour la réalisation de la mission ?'>
                                                                Quels sont les processus dont vous avez la charge pour la réalisation de la mission ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_list'>
                                                            {
                                                                this.state.otherMissionList[missionIndex].process.processList.map((process, index) => (
                                                                    <div className='section_body_question_list_elt'>
                                                                        <span className='section_body_question_list_elt_name'>{process.name}</span><span className='section_body_question_list_elt_x'
                                                                            onClick={(e) => {
                                                                                this._deleteOtherMissionProcess(missionIndex, index);
                                                                            }}
                                                                        >×</span>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className='section_body_question_button'>
                                                            <span className='button_style_1' onClick={(e) => {
                                                                let processName = prompt("Veuillez saisir le nom du processus :");
                                                                if(processName) {
                                                                    this._addOtherMissionProcess(missionIndex, processName);
                                                                }
                                                            }}>Ajouter un processus</span>
                                                        </div>
                                                    </div>

                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label>
                                                                La gestion des processus couvre-t-elle tous vos processus ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input onChange={(e)=>{
                                                                let otherMissionList = this.state.otherMissionList;
                                                                otherMissionList[missionIndex].process.la_gestion_des_processus_couvre_t_elle_tous_vos_processus = e.target.value;
                                                                this.setState({
                                                                    otherMissionList: otherMissionList,
                                                                });
                                                            }} type='radio' placeholder='' id='La gestion des processus couvre-t-elle tous vos processus ?__oui' name='' value="oui"/>
                                                            <label>Oui</label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input onChange={(e)=>{
                                                                let otherMissionList = this.state.otherMissionList;
                                                                otherMissionList[missionIndex].process.la_gestion_des_processus_couvre_t_elle_tous_vos_processus = e.target.value;
                                                                this.setState({
                                                                    otherMissionList: otherMissionList,
                                                                });
                                                            }} type='radio' placeholder='' id='La gestion des processus couvre-t-elle tous vos processus ?__non' name='' value="non"/>
                                                            <label>Non</label>
                                                        </div>
                                                    </div>


                                                    <div className='section_body_question'>
                                                        <div className='section_body_question_label'>
                                                            <label htmlFor=''>
                                                                Quel est le périmètre couvert ?
                                                            </label>
                                                        </div>
                                                        <div className='section_body_question_input'>
                                                            <input onChange={(e)=>{
                                                                let otherMissionList = this.state.otherMissionList;
                                                                otherMissionList[missionIndex].process.quel_est_le_perimetre_couvert = e.target.value;
                                                                this.setState({
                                                                    otherMissionList: otherMissionList,
                                                                });
                                                            }} type='text' id='Quel est le périmètre couvert ?' name=''/>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/* Iterration sur les processus metiers */}
                                                <section className='section'>
                                                        <h2 className='section_title'>
                                                            Modélisation des processus métiers d'une mission <br/><strong className='color-green'>Rappel : Un processus métier est un ensemble de tâches à réaliser pour l'accomplissement d'une mission</strong>
                                                        </h2>
                                                        {
                                                            mission.process.processList.map((process, processIndex) => (
                                                                <>
                                                                    <section className='section'>
                                                                        <h2 className='title'>
                                                                            {process.name} 
                                                                        </h2>
                                                                        <br/>
                                                                        <h3 className='section_title'>
                                                                            Identification des tâches de chaque processus métiers <br/><strong className='color-green'>Rappel : une tâche est une activité élémentaire à effectuer</strong>
                                                                        </h3>
                                                                        <div className='section_body'>
                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_label'>
                                                                                    <label htmlFor={"Quel est l'événement qui déclenche la réalisation du processus ?:" + process.name}>
                                                                                        Quel est l'événement qui déclenche la réalisation du processus ?
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input placeholder="Décrivez le plus fidèlement possible l'événement qui subvient pour déclencher le début du processus" 
                                                                                        value={process.taskIdentification.eventListener} type='text' id={"Quel est l'événement qui déclenche la réalisation du processus ?"+process.name} name={"Quel est l'événement qui déclenche la réalisation du processus ?"+process.name}
                                                                                        onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_subLabel'>
                                                                                    <label>
                                                                                        Ou Choisissez celui qui correspond le mieux
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Générique : Début automatique du processus" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Générique : Début automatique du processus"
                                                                                        onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Générique : Début automatique du processus">Générique : Début automatique du processus</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Message : Arrivé d'un message d'un autre processus" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Message : Arrivé d'un message d'un autre processus"
                                                                                        onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Message : Arrivé d'un message d'un autre processus">Message : Arrivé d'un message d'un autre processus</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Temporisateur : Début à un moment temporelle donné" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Temporisateur : Début à un moment temporelle donné"
                                                                                        onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Temporisateur : Début à un moment temporelle donné">Temporisateur : Début à un moment temporelle donné</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Conditionnel : Début conditionné à la réalisation d'un événement" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Conditionnel : Début conditionné à la réalisation d'un événement"
                                                                                        onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Conditionnel : Début conditionné à la réalisation d'un événement">Conditionnel : Début conditionné à la réalisation d'un événement</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Signal : Début à la réception d'un signal d'un autre processus" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Signal : Début à la réception d'un signal d'un autre processus"
                                                                                        onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Signal : Début à la réception d'un signal d'un autre processus">Signal : Début à la réception d'un signal d'un autre processus</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Parallèle : Début conditionné à de nombreux événements à prendre en compte" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Parallèle : Début conditionné à de nombreux événements à prendre en compte"
                                                                                        onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Parallèle : Début conditionné à de nombreux événements à prendre en compte">Parallèle : Début conditionné à de nombreux événements à prendre en compte</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Multiple : Début conditionné à la réalisation simultanée de plusieurs événements" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Multiple : Début conditionné à la réalisation simultanée de plusieurs événements"
                                                                                        onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Multiple : Début conditionné à la réalisation simultanée de plusieurs événements">Multiple : Début conditionné à la réalisation simultanée de plusieurs événements</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Autre" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Autre"
                                                                                        onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                    <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Autre">Autre</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_label'>
                                                                                    <label htmlFor={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?:" + process.name}>
                                                                                        Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input placeholder="Décrivez l'événement qui survient à la fin du processus" 
                                                                                        value={process.taskIdentification.end_eventListener} type='text' id={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?"+process.name} name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?"+process.name}
                                                                                        onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.end_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_subLabel'>
                                                                                    <label>
                                                                                        Ou Choisissez celui qui correspond le mieux
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus">Fin d'un processus</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus composé de plusieurs modules de finition" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus composé de plusieurs modules de finition"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus composé de plusieurs modules de finition">Fin d'un processus composé de plusieurs modules de finition</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant l'émission d'un message à envoyer à un autre processus" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus déclenchant l'émission d'un message à envoyer à un autre processus"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant l'émission d'un message à envoyer à un autre processus">Fin d'un processus déclenchant l'émission d'un message à envoyer à un autre processus</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant l'attribution d'une tâche à une entité" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus déclenchant l'attribution d'une tâche à une entité"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant l'attribution d'une tâche à une entité">Fin d'un processus déclenchant l'attribution d'une tâche à une entité</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus initiant l'émission d'un signal pour d'autres processus" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus initiant l'émission d'un signal pour d'autres processus"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus initiant l'émission d'un signal pour d'autres processus">Fin d'un processus initiant l'émission d'un signal pour d'autres processus</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant un message d'erreur" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus déclenchant un message d'erreur"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant un message d'erreur">Fin d'un processus déclenchant un message d'erreur</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin de traitement de tout le processus par suite de détection d'une incohérence grave" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin de traitement de tout le processus par suite de détection d'une incohérence grave"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin de traitement de tout le processus par suite de détection d'une incohérence grave">Fin de traitement de tout le processus par suite de détection d'une incohérence grave</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin de traitement d'un processus en déclenchant le traitement d'un processus de remédiation" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin de traitement d'un processus en déclenchant le traitement d'un processus de remédiation"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin de traitement d'un processus en déclenchant le traitement d'un processus de remédiation">Fin de traitement d'un processus en déclenchant le traitement d'un processus de remédiation</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                    }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Autre" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Autre"/>
                                                                                    <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Autre">Autre</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_label'>
                                                                                    <label htmlFor="Quelles sont les tâches à réaliser pour l'accomplissement du processus?">
                                                                                        Quelles sont les tâches à réaliser pour l'accomplissement du processus?
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_list'>
                                                                                    {
                                                                                        process.taskIdentification.taskList.map((task, taskIndex) => (
                                                                                            <div className='section_body_question_list_elt'>
                                                                                                <span className='section_body_question_list_elt_name'>{task.name}</span><span className='section_body_question_list_elt_x'
                                                                                                    onClick={(e) => {
                                                                                                        this._addOtherMissionProcessTask(missionIndex, processIndex, taskIndex);
                                                                                                    }}
                                                                                                >×</span>
                                                                                            </div>
                                                                                        ))
                                                                                    }
                                                                                </div>
                                                                                <div className='section_body_question_button'>
                                                                                    <span className='button_style_1' onClick={(e) => {
                                                                                        let taskName = prompt("Veuillez saisir le nom de la tâche :");
                                                                                        if(taskName) {
                                                                                            this._addOtherMissionProcessTask(missionIndex, processIndex, taskName);
                                                                                        }
                                                                                    }}>Ajouter une tâche</span>
                                                                                </div>
                                                                            </div>

                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_label'>
                                                                                    <label htmlFor={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?:" + process.name}>
                                                                                        Qu'elles sont les liens qui existent entre les tâches élémentaires ?
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input placeholder="Décrivez les liens qui existent entre les tâches."
                                                                                        value={process.taskIdentification.elementaryTaskLink} type='text' id={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?"+process.name} name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?"+process.name}
                                                                                        onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.elementaryTaskLink = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_subLabel'>
                                                                                    <label>
                                                                                        Ou Choisissez celui qui correspond le mieux
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                        let otherMissionList = this.state.otherMissionList;
                                                                                        otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                                                        this.setState({
                                                                                            otherMissionList: otherMissionList,
                                                                                        });
                                                                                    }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Exclusif : le traitement ne suit que l'une seule des possibilités. Le traitement peut aussi être confié à une autre partie prenante qui en avait précédemment la charge" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Exclusif : le traitement ne suit que l'une seule des possibilités. Le traitement peut aussi être confié à une autre partie prenante qui en avait précédemment la charge"/>
                                                                                    <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Exclusif : le traitement ne suit que l'une seule des possibilités. Le traitement peut aussi être confié à une autre partie prenante qui en avait précédemment la charge">Exclusif : le traitement ne suit que l'une seule des possibilités. Le traitement peut aussi être confié à une autre partie prenante qui en avait précédemment la charge</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                        let otherMissionList = this.state.otherMissionList;
                                                                                        otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                                                        this.setState({
                                                                                            otherMissionList: otherMissionList,
                                                                                        });
                                                                                    }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Exclusif basé sur les évènements : existence de multiples évènements dont l'un seul à la fois peut subvenir comme entré d'un processus" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Exclusif basé sur les évènements : existence de multiples évènements dont l'un seul à la fois peut subvenir comme entré d'un processus"/>
                                                                                    <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Exclusif basé sur les évènements : existence de multiples évènements dont l'un seul à la fois peut subvenir comme entré d'un processus">Exclusif basé sur les évènements : existence de multiples évènements dont l'un seul à la fois peut subvenir comme entré d'un processus</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                        let otherMissionList = this.state.otherMissionList;
                                                                                        otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                                                        this.setState({
                                                                                            otherMissionList: otherMissionList,
                                                                                        });
                                                                                    }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Inclusif : le traitement dépend de plusieurs évènements qui peuvent se réaliser" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Inclusif : le traitement dépend de plusieurs évènements qui peuvent se réaliser"/>
                                                                                    <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Inclusif : le traitement dépend de plusieurs évènements qui peuvent se réaliser">Inclusif : le traitement dépend de plusieurs évènements qui peuvent se réaliser</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                        let otherMissionList = this.state.otherMissionList;
                                                                                        otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                                                        this.setState({
                                                                                            otherMissionList: otherMissionList,
                                                                                        });
                                                                                    }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Parallèle : le traitement se divise en plusieurs traitements qui peuvent se réaliser simultanément" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Parallèle : le traitement se divise en plusieurs traitements qui peuvent se réaliser simultanément"/>
                                                                                    <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Parallèle : le traitement se divise en plusieurs traitements qui peuvent se réaliser simultanément">Parallèle : le traitement se divise en plusieurs traitements qui peuvent se réaliser simultanément</label>
                                                                                </div>

                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                        let otherMissionList = this.state.otherMissionList;
                                                                                        otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                                                        this.setState({
                                                                                            otherMissionList: otherMissionList,
                                                                                        });
                                                                                    }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Initiale parallèle : le processus démarre à partir de plusieurs évènements" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Initiale parallèle : le processus démarre à partir de plusieurs évènements"/>
                                                                                    <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Initiale parallèle : le processus démarre à partir de plusieurs évènements">Initiale parallèle : le processus démarre à partir de plusieurs évènements</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                        let otherMissionList = this.state.otherMissionList;
                                                                                        otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                                                        this.setState({
                                                                                            otherMissionList: otherMissionList,
                                                                                        });
                                                                                    }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Synchronisation inclusive : plusieurs traitements se regroupent en ce point pour constituer une entrée d'un processus" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Synchronisation inclusive : plusieurs traitements se regroupent en ce point pour constituer une entrée d'un processus"/>
                                                                                    <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Synchronisation inclusive : plusieurs traitements se regroupent en ce point pour constituer une entrée d'un processus">Synchronisation inclusive : plusieurs traitements se regroupent en ce point pour constituer une entrée d'un processus</label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input onChange={(e)=>{
                                                                                        let otherMissionList = this.state.otherMissionList;
                                                                                        otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                                                        this.setState({
                                                                                            otherMissionList: otherMissionList,
                                                                                        });
                                                                                    }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Autre" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Autre"/>
                                                                                    <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Autre">Autre</label>
                                                                                </div>
                                                                            </div>

                                                                            <div className='section_body_question'>
                                                                                <div className='section_body_question_label'>
                                                                                    <label htmlFor={"Dans la chaîne de réalisation de chacune de vos missions, quelles sont les problèmes, difficultés et préoccupations que vous rencontrés ?:" + process.name}>
                                                                                        Dans la chaîne de réalisation de chacune de vos missions, quelles sont les problèmes, difficultés et préoccupations que vous rencontrés ?
                                                                                    </label>
                                                                                </div>
                                                                                <div className='section_body_question_input'>
                                                                                    <input placeholder="Décrivez les liens qui existent entre les tâches."
                                                                                        value={process.taskIdentification.missionProblems} type='text' id={"Dans la chaîne de réalisation de chacune de vos missions, quelles sont les problèmes, difficultés et préoccupations que vous rencontrés ?"+process.name} name={"Dans la chaîne de réalisation de chacune de vos missions, quelles sont les problèmes, difficultés et préoccupations que vous rencontrés ?"+process.name}
                                                                                        onChange={(e)=>{
                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.missionProblems = e.target.value;
                                                                                            this.setState({
                                                                                                otherMissionList: otherMissionList,
                                                                                            });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {/* Iterration sur les taches */}
                                                                        {
                                                                                process.taskIdentification.taskList.map((task, taskIndex) => (
                                                                                    <>
                                                                                        <section className='section'>
                                                                                            <h2 className='title'>
                                                                                                {task.name} 
                                                                                            </h2>
                                                                                            <br/>
                                                                                            <h3 className='section_title'>
                                                                                                Précision sur la durée de la tâche à la réalisation de la tâche
                                                                                            </h3>
                                                                                            <div className='section_body'>
                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Comment mesurez-vous l'avancement dans la réalisation de cette tâche ?:" + task.name}>
                                                                                                            Comment mesurez-vous l'avancement dans la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <textarea id={"Comment mesurez-vous l'avancement dans la réalisation de cette tâche ?:" + task.name} name={"Comment mesurez-vous l'avancement dans la réalisation de cette tâche ?:" + task.name}
                                                                                                            value={task.taskDuration.progressionWeightUnit}
                                                                                                            onChange={(e)=>{
                                                                                                                let otherMissionList = this.state.otherMissionList;
                                                                                                                otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.progressionWeightUnit = e.target.value;
                                                                                                                this.setState({
                                                                                                                    otherMissionList: otherMissionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelle est la durée prévue pour la réalisation de cette tâche ?"+task.name}>
                                                                                                            Quelle est la durée prévue pour la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.taskDurationValue = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskDuration.taskDurationValue} type='text' id={"Quelle est la durée prévue pour la réalisation de cette tâche ?"+task.name} name={"Quelle est la durée prévue pour la réalisation de cette tâche ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quel est le temps moyen que vous passez à réaliser la tâche ?"+task.name}>
                                                                                                            Quel est le temps moyen que vous passez à réaliser la tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.averageTaskTimeValue = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskDuration.averageTaskTimeValue} type='text' id={"Quel est le temps moyen que vous passez à réaliser la tâche ?"+task.name} name={"Quel est le temps moyen que vous passez à réaliser la tâche ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            Observez-vous des retards dans la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input type='radio' placeholder='' id={"Observez-vous des retards dans la réalisation de cette tâche ?__oui"+task.name} name={"Observez-vous des retards dans la réalisation de cette tâche ?"+task.name} 
                                                                                                            value={"oui"}
                                                                                                            onChange={(e)=>{
                                                                                                                let otherMissionList = this.state.otherMissionList;
                                                                                                                otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.taskLateness = e.target.value;
                                                                                                                this.setState({
                                                                                                                    otherMissionList: otherMissionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                        <label htmlFor={"Observez-vous des retards dans la réalisation de cette tâche ?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input type='radio' placeholder='' id={"Observez-vous des retards dans la réalisation de cette tâche ?__non" + task.name} name={"Observez-vous des retards dans la réalisation de cette tâche ?"+task.name} 
                                                                                                            value={"non"}
                                                                                                            onChange={(e)=>{
                                                                                                                let otherMissionList = this.state.otherMissionList;
                                                                                                                otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.taskLateness = e.target.value;
                                                                                                                this.setState({
                                                                                                                    otherMissionList: otherMissionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                        <label htmlFor={"Observez-vous des retards dans la réalisation de cette tâche ?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Qu'elles sont les raisons qui selon-vous engendrent des retards ?"+task.name}>
                                                                                                            Qu'elles sont les raisons qui selon-vous engendrent des retards ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.taskLatenessRaison = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskDuration.taskLatenessRaison} type='text' id={"Qu'elles sont les raisons qui selon-vous engendrent des retards ?"+task.name} name={"Qu'elles sont les raisons qui selon-vous engendrent des retards ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Comment les éviter ?"+task.name}>
                                                                                                            Comment les éviter ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.taskLatenessSolution = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskDuration.taskLatenessSolution} type='text' id={"Comment les éviter ?"+task.name} name={"Comment les éviter ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quels sont les coûts (financiers, matériels, humains, ...) engendrés par des retards sur la réalisation de cette tâche ?"+task.name}>
                                                                                                            Quels sont les coûts (financiers, matériels, humains, ...) engendrés par des retards sur la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDuration.taskLatenessCoast = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskDuration.taskLatenessCoast} type='text' id={"Quels sont les coûts (financiers, matériels, humains, ...) engendrés par des retards sur la réalisation de cette tâche ?"+task.name} name={"Quels sont les coûts (financiers, matériels, humains, ...) engendrés par des retards sur la réalisation de cette tâche ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </section>


                                                                                        <section className='section'>
                                                                                            <h3 className='section_title'>
                                                                                                Précision sur les ressources méthodologiques nécessaires à la réalisation de la tâche
                                                                                            </h3>
                                                                                            <div className='section_body'>
                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={'Quelles sont les ressources méthodologiques qui sont nécessaires à la réalisation de cette tâche ?:' + task.name}>
                                                                                                            Quelles sont les ressources méthodologiques qui sont nécessaires à la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <textarea id={'Quelles sont les ressources méthodologiques qui sont nécessaires à la réalisation de cette tâche ?:' + task.name} name={'Quelles sont les ressources méthodologiques qui sont nécessaires à la réalisation de cette tâche ?:' + task.name}
                                                                                                            value={task.taskMetResource.taskMetResourceValue}
                                                                                                            onChange={(e)=>{
                                                                                                                let otherMissionList = this.state.otherMissionList;
                                                                                                                otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskMetResource.taskMetResourceValue = e.target.value;
                                                                                                                this.setState({
                                                                                                                    otherMissionList: otherMissionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les règles métiers à appliquer pour valider la tâche ou les résultats produits ?"+task.name}>
                                                                                                            Quelles sont les règles métiers à appliquer pour valider la tâche ou les résultats produits ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskMetResource.taskRulJob = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskMetResource.taskRulJob} placholder="Préciser ces règles" type='text' id={"Quelles sont les règles métiers à appliquer pour valider la tâche ou les résultats produits ?"+task.name} name={"Quelles sont les règles métiers à appliquer pour valider la tâche ou les résultats produits ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les contraintes opérationnelles à observer pour la réalisation de la tâche ?"+task.name}>
                                                                                                            Quelles sont les contraintes opérationnelles à observer pour la réalisation de la tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskMetResource.taskOperationalProblems = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskMetResource.taskOperationalProblems} type='text' id={"Quelles sont les contraintes opérationnelles à observer pour la réalisation de la tâche ?"+task.name} name={"Quelles sont les contraintes opérationnelles à observer pour la réalisation de la tâche ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            Est ce que la tâche fait intervenir plusieurs équipes différentes ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input type='radio' placeholder='' id={"Est ce que la tâche fait intervenir plusieurs équipes différentes ?__oui"+task.name} name={"Est ce que la tâche fait intervenir plusieurs équipes différentes ?"+task.name} 
                                                                                                            value={"oui"}
                                                                                                            onChange={(e)=>{
                                                                                                                let otherMissionList = this.state.otherMissionList;
                                                                                                                otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskMetResource.taskDifferentCrew = e.target.value;
                                                                                                                this.setState({
                                                                                                                    otherMissionList: otherMissionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                        <label htmlFor={"Est ce que la tâche fait intervenir plusieurs équipes différentes ?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input type='radio' placeholder='' id={"Est ce que la tâche fait intervenir plusieurs équipes différentes ?__non" + task.name} name={"Est ce que la tâche fait intervenir plusieurs équipes différentes ?"+task.name} 
                                                                                                            value={"non"}
                                                                                                            onChange={(e)=>{
                                                                                                                let otherMissionList = this.state.otherMissionList;
                                                                                                                otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskMetResource.taskDifferentCrew = e.target.value;
                                                                                                                this.setState({
                                                                                                                    otherMissionList: otherMissionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                        <label htmlFor={"Est ce que la tâche fait intervenir plusieurs équipes différentes ?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Y-a-t-il des règles méthodologiques à observer dans les échanges entre les équipes qui interviennent dans la réalisation de cette tâche ?" + task.name}>
                                                                                                            Y-a-t-il des règles méthodologiques à observer dans les échanges entre les équipes qui interviennent dans la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskMetResource.taskMetRulExchange = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskMetResource.taskMetRulExchange} type='text' id={"Y-a-t-il des règles méthodologiques à observer dans les échanges entre les équipes qui interviennent dans la réalisation de cette tâche ?" + task.name} name={"Y-a-t-il des règles méthodologiques à observer dans les échanges entre les équipes qui interviennent dans la réalisation de cette tâche ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </section>

                                                                                        <section className='section'>
                                                                                            <h3 className='section_title'>
                                                                                                Précision sur les ressources technologiques et matérielles
                                                                                            </h3>
                                                                                            <div className='section_body'>
                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={'Quelles sont les équipements infractructurelles qui sont nécessaires à la réalisation de cette tâche ?:' + task.name}>
                                                                                                            Quelles sont les équipements infractructurelles qui sont nécessaires à la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <textarea placeholder="En termes d'équipements matériels de réseaux informatiques et internet, ordinateurs, tablettes, smartpones, scanner, imprimante, ordinateur, téléphone, ..." id={'Quelles sont les équipements infractructurelles qui sont nécessaires à la réalisation de cette tâche ?:' + task.name} name={'Quelles sont les équipements infractructurelles qui sont nécessaires à la réalisation de cette tâche ?:' + task.name} 
                                                                                                            value={task.taskTechResource.taskInfraEquipment}
                                                                                                            onChange={(e)=>{
                                                                                                                let otherMissionList = this.state.otherMissionList;
                                                                                                                otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskTechResource.taskInfraEquipment = e.target.value;
                                                                                                                this.setState({
                                                                                                                    otherMissionList: otherMissionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={'Quelles sont les équipements logicielles qui sont nécessaires à la réalisation de cette tâche ?:' + task.name}>
                                                                                                            Quelles sont les équipements logicielles qui sont nécessaires à la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <textarea placeholder="En termes de Système d'Information, de progiciels (ERP, CRM, ...), applicaitons informatiques, etc." id={'Quelles sont les équipements logicielles qui sont nécessaires à la réalisation de cette tâche ?:' + task.name} name={'Quelles sont les équipements logicielles qui sont nécessaires à la réalisation de cette tâche ?:' + task.name} 
                                                                                                            value={task.taskTechResource.taskLogicEquipment}
                                                                                                            onChange={(e)=>{
                                                                                                                let otherMissionList = this.state.otherMissionList;
                                                                                                                otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskTechResource.taskLogicEquipment = e.target.value;
                                                                                                                this.setState({
                                                                                                                    otherMissionList: otherMissionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les autres ressources technologiques qui sont nécessaires à la réalisation de cette tâche ?"+task.name}>
                                                                                                            Quelles sont les autres ressources technologiques qui sont nécessaires à la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskTechResource.taskOtherTechResource = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskTechResource.taskOtherTechResource} placeholder='Préciser les ressources qui sont différentes des équipements matériels et logiciels, comme par exemple les éuipements de sécurité' type='text' id={"Quelles sont les autres ressources technologiques qui sont nécessaires à la réalisation de cette tâche ?"+task.name} name={"Quelles sont les autres ressources technologiques qui sont nécessaires à la réalisation de cette tâche ?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les autres ressources matérielles qui sont nécessaires à la réalisation de cette tâche?"+task.name}>
                                                                                                            Quelles sont les autres ressources matérielles qui sont nécessaires à la réalisation de cette tâche?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskTechResource.taskOtherMaterialResource = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskTechResource.taskOtherMaterialResource} placeholder="En termes de ressources logistiques, les salles, les équipements bureautiques, etc." type='text' id={"Quelles sont les autres ressources matérielles qui sont nécessaires à la réalisation de cette tâche?"+task.name} name={"Quelles sont les autres ressources matérielles qui sont nécessaires à la réalisation de cette tâche?"+task.name}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </section>

                                                                                        <section className='section'>
                                                                                            <h3 className='section_title'>
                                                                                                Autres ressources nécessaires à la réalisation de la tâche
                                                                                            </h3>
                                                                                            <div className='section_body'>
                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={'Quelles sont les autres ressources nécessaires à la réalisation de cette tâche ?:' + task.name}>
                                                                                                            Quelles sont les autres ressources nécessaires à la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <textarea id={'Quelles sont les autres ressources nécessaires à la réalisation de cette tâche ?:' + task.name} name={'Quelles sont les autres ressources nécessaires à la réalisation de cette tâche ?:' + task.name}
                                                                                                            value={task.taskOthersResource.taskOthersResourceValue}
                                                                                                            onChange={(e)=>{
                                                                                                                let otherMissionList = this.state.otherMissionList;
                                                                                                                otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskOthersResource.taskOthersResourceValue = e.target.value;
                                                                                                                this.setState({
                                                                                                                    otherMissionList: otherMissionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            Toutes ces ressources sont elles disponibles ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input type='radio' placeholder='' id={"Toutes ces ressources sont elles disponibles ?__oui"+task.name} name={"Toutes ces ressources sont elles disponibles ?"+task.name} 
                                                                                                            value={"oui"}
                                                                                                            onChange={(e)=>{
                                                                                                                let otherMissionList = this.state.otherMissionList;
                                                                                                                otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskOthersResource.taskAllOthersResourceAvailable = e.target.value;
                                                                                                                this.setState({
                                                                                                                    otherMissionList: otherMissionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                        <label htmlFor={"Toutes ces ressources sont elles disponibles ?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input type='radio' placeholder='' id={"Toutes ces ressources sont elles disponibles ?__non" + task.name} name={"Toutes ces ressources sont elles disponibles ?"+task.name} 
                                                                                                            value={"non"}
                                                                                                            onChange={(e)=>{
                                                                                                                let otherMissionList = this.state.otherMissionList;
                                                                                                                otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskOthersResource.taskAllOthersResourceAvailable = e.target.value;
                                                                                                                this.setState({
                                                                                                                    otherMissionList: otherMissionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        />
                                                                                                        <label htmlFor={"Toutes ces ressources sont elles disponibles ?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Qu'est-ce qu'il faudrait ?" + task.name}>
                                                                                                            Qu'est-ce qu'il faudrait ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskOthersResource.taskOthersResourceSolution = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskOthersResource.taskOthersResourceSolution} type='text' id={"Qu'est-ce qu'il faudrait ?" + task.name} name={"Qu'est-ce qu'il faudrait ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </section>

                                                                                        <section className='section'>
                                                                                            <h3 className='section_title'>
                                                                                                Précision sur les échanges de données
                                                                                            </h3>
                                                                                            <div className='section_body'>
                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={'Quels sont les messages à envoyer ou à recevoir ?:' + task.name}>
                                                                                                            Quels sont les messages à envoyer ou à recevoir ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <textarea placeholder='Préciser clairement la nature et les contenus types de ces messages' id={'Quels sont les messages à envoyer ou à recevoir ?:' + task.name} name={'Quels sont les messages à envoyer ou à recevoir ?:' + task.name} 
                                                                                                            value={task.taskDataExchange.messageToSendAndReceive}
                                                                                                            onChange={(e)=>{
                                                                                                                let otherMissionList = this.state.otherMissionList;
                                                                                                                otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDataExchange.messageToSendAndReceive = e.target.value;
                                                                                                                this.setState({
                                                                                                                    otherMissionList: otherMissionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quels sont les formulaires à saisir ?" + task.name}>
                                                                                                            Quels sont les formulaires à saisir ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskDataExchange.forms = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskDataExchange.forms} type='text' id={"Quels sont les formulaires à saisir ?" + task.name} name={"Quels sont les formulaires à saisir ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </section>

                                                                                        <section className='section'>
                                                                                            <h3 className='section_title'>
                                                                                                Tâche manuelle ou automatisée
                                                                                            </h3>
                                                                                            <div className='section_body'>
                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            La tâche est-elle actuellement exécutée manuellement ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsManual = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={"oui"} type='radio' placeholder='' id={"La tâche est-elle actuellement exécutée manuellement ?__oui"+task.name} name={"La tâche est-elle actuellement exécutée manuellement ?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle actuellement exécutée manuellement ?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsManual = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={"non"} type='radio' placeholder='' id={"La tâche est-elle actuellement exécutée manuellement ?__non" + task.name} name={"La tâche est-elle actuellement exécutée manuellement ?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle actuellement exécutée manuellement ?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            La tâche est-elle exécutée par des agents internes au service?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsMakeByInternAgents = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={"oui"} type='radio' placeholder='' id={"La tâche est-elle exécutée par des agents internes au service?__oui"+task.name} name={"La tâche est-elle exécutée par des agents internes au service?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée par des agents internes au service?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsMakeByInternAgents = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={"non"} type='radio' placeholder='' id={"La tâche est-elle exécutée par des agents internes au service?__non" + task.name} name={"La tâche est-elle exécutée par des agents internes au service?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée par des agents internes au service?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les agents internes qui interviennent dans la réalisation de la tâche ?" + task.name}>
                                                                                                            Quelles sont les agents internes qui interviennent dans la réalisation de la tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskInternAgents = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskManualAuto.taskInternAgents} placeholder="Préciser lesquelles" type='text' id={"Quelles sont les agents internes qui interviennent dans la réalisation de la tâche ?" + task.name} name={"Quelles sont les agents internes qui interviennent dans la réalisation de la tâche ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            La tâche est-elle exécutée par des agents externes au service?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsMakeByExternalAgents = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={"oui"} type='radio' placeholder='' id={"La tâche est-elle exécutée par des agents externes au service?__oui"+task.name} name={"La tâche est-elle exécutée par des agents externes au service?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée par des agents externes au service?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsMakeByExternalAgents = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={"non"} type='radio' placeholder='' id={"La tâche est-elle exécutée par des agents externes au service?__non" + task.name} name={"La tâche est-elle exécutée par des agents externes au service?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée par des agents externes au service?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les agents externes qui interviennent dans la réalisation de la tâche ?" + task.name}>
                                                                                                            Quelles sont les agents externes qui interviennent dans la réalisation de la tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskExternalAgents = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskManualAuto.taskExternalAgents} placeholder="Préciser lesquelles" type='text' id={"Quelles sont les agents externes qui interviennent dans la réalisation de la tâche ?" + task.name} name={"Quelles sont les agents externes qui interviennent dans la réalisation de la tâche ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            La tâche est-elle exécutée par des acteurs externes au service ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsMakeByExternalActors = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={"oui"} type='radio' placeholder='' id={"La tâche est-elle exécutée par des acteurs externes au service ?__oui"+task.name} name={"La tâche est-elle exécutée par des acteurs externes au service ?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée par des acteurs externes au service ?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskIsMakeByExternalActors = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={"non"} type='radio' placeholder='' id={"La tâche est-elle exécutée par des acteurs externes au service ?__non" + task.name} name={"La tâche est-elle exécutée par des acteurs externes au service ?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée par des acteurs externes au service ?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les acteurs externes qui interviennent dans la réalisation de la tâche ?" + task.name}>
                                                                                                            Quelles sont les acteurs externes qui interviennent dans la réalisation de la tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskExternalActors = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskManualAuto.taskExternalActors} placeholder="Préciser lesquelles" type='text' id={"Quelles sont les acteurs externes qui interviennent dans la réalisation de la tâche ?" + task.name} name={"Quelles sont les acteurs externes qui interviennent dans la réalisation de la tâche ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label>
                                                                                                            La tâche est-elle exécutée à partir de ressources informatiques ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskHasInformatiqueResource = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={"oui"} type='radio' placeholder='' id={"La tâche est-elle exécutée à partir de ressources informatiques ?__oui"+task.name} name={"La tâche est-elle exécutée à partir de ressources informatiques ?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée à partir de ressources informatiques ?__oui"+task.name}>Oui</label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskHasInformatiqueResource = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={"non"} type='radio' placeholder='' id={"La tâche est-elle exécutée à partir de ressources informatiques ?__non" + task.name} name={"La tâche est-elle exécutée à partir de ressources informatiques ?"+task.name}/>
                                                                                                        <label htmlFor={"La tâche est-elle exécutée à partir de ressources informatiques ?__non" + task.name}>Non</label>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Quelles sont les ressources informatiques qui sont utilisées dans la réalisation de la tâche ?" + task.name}>
                                                                                                            Quelles sont les ressources informatiques qui sont utilisées dans la réalisation de la tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskManualAuto.taskInformatiqueResource = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskManualAuto.taskInformatiqueResource} placeholder="Préciser lesquelles" type='text' id={"Quelles sont les ressources informatiques qui sont utilisées dans la réalisation de la tâche ?" + task.name} name={"Quelles sont les ressources informatiques qui sont utilisées dans la réalisation de la tâche ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </section>

                                                                                        <section className='section'>
                                                                                            <h3 className='section_title'>
                                                                                                Précisions sur les problèmes rencontrés
                                                                                            </h3>
                                                                                            <div className='section_body'>
                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={'Quels sont les problèmes que vous rencontrez dans la réalisation de cette tâche ?:' + task.name}>
                                                                                                            Quels sont les problèmes que vous rencontrez dans la réalisation de cette tâche ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <textarea placeholder='Préciser clairement la nature et les contenus types de ces messages' id={'Quels sont les problèmes que vous rencontrez dans la réalisation de cette tâche ?:' + task.name} name={'Quels sont les problèmes que vous rencontrez dans la réalisation de cette tâche ?:' + task.name} 
                                                                                                            value={task.taskProblems.taskProblemsValue}
                                                                                                            onChange={(e)=>{
                                                                                                                let otherMissionList = this.state.otherMissionList;
                                                                                                                otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskProblems.taskProblemsValue = e.target.value;
                                                                                                                this.setState({
                                                                                                                    otherMissionList: otherMissionList,
                                                                                                                });
                                                                                                            }}
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Comment procédez-vous pour résoudre ces problèmes ?" + task.name}>
                                                                                                            Comment procédez-vous pour résoudre ces problèmes ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskProblems.taskProblemsSolutions = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskProblems.taskProblemsSolutions} type='text' id={"Comment procédez-vous pour résoudre ces problèmes ?" + task.name} name={"Comment procédez-vous pour résoudre ces problèmes ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Comment pensez-vous qu'on pourrait procéder pour régler définitivement ces problèmes ?" + task.name}>
                                                                                                            Comment pensez-vous qu'on pourrait procéder pour régler définitivement ces problèmes ?
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskProblems.taskProblemsDefinitiveSolution = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskProblems.taskProblemsDefinitiveSolution} type='text' id={"Comment pensez-vous qu'on pourrait procéder pour régler définitivement ces problèmes ?" + task.name} name={"Comment pensez-vous qu'on pourrait procéder pour régler définitivement ces problèmes ?" + task.name}/>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className='section_body_question'>
                                                                                                    <div className='section_body_question_label'>
                                                                                                        <label htmlFor={"Autres commentaires" + task.name}>
                                                                                                            Autres commentaires
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='section_body_question_input'>
                                                                                                        <input onChange={(e)=>{
                                                                                                            let otherMissionList = this.state.otherMissionList;
                                                                                                            otherMissionList[missionIndex].process.processList[processIndex].taskIdentification.taskList[taskIndex].taskProblems.taskProblemsOthersComments = e.target.value;
                                                                                                            this.setState({
                                                                                                                otherMissionList: otherMissionList,
                                                                                                            });
                                                                                                        }} value={task.taskProblems.taskProblemsOthersComments} type='text' id={"Autres commentaires" + task.name} name={"Autres commentaires" + task.name}/>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </section>
                                                                                    </>
                                                                                ))
                                                                        }
                                                                    </section>
                                                                </>
                                                            ))
                                                        }
                                                    </section>
                                            </section>
                                        </>
                                    ))
                                }
                                {
                                    // this.state.otherMissionList.map((mission, missionIndex) => (
                                    //     <>
                                    //         <section className='section'>
                                    //             <h2 className='title'>
                                    //                 {mission.name} 
                                    //             </h2>
                                    //             <br/>
                                    //             <h3 className='section_title'>
                                    //                 Description de la mission
                                    //             </h3>
                                    //             <div className='section_body'>
                                    //                 <div className='section_body_question'>
                                    //                     <div className='section_body_question_label'>
                                    //                         <label htmlFor={'Décrivez la mission:' + mission.name}>
                                    //                             Décrivez la mission
                                    //                         </label>
                                    //                     </div>
                                    //                     <div className='section_body_question_input'>
                                    //                         <textarea id={'Décrivez la mission:' + mission.name} name={'Décrivez la mission:' + mission.name} value={mission.description.description}
                                    //                             onChange={(e)=>{
                                    //                                 let otherMissionList = this.state.otherMissionList;
                                    //                                 otherMissionList[missionIndex].description.description = e.target.value;
                                    //                                 this.setState({
                                    //                                     otherMissionList: otherMissionList,
                                    //                                 });
                                    //                             }}
                                    //                         ></textarea>
                                    //                     </div>
                                    //                 </div>

                                    //                 <div className='section_body_question'>
                                    //                     <div className='section_body_question_label'>
                                    //                         <label htmlFor={"Quels sont les conditions qu'il faut avoir pour convenablement conduire la mission?"+mission.name}>
                                    //                             Quels sont les conditions qu'il faut avoir pour convenablement conduire la mission?
                                    //                         </label>
                                    //                     </div>
                                    //                     <div className='section_body_question_input'>
                                    //                         <input value={mission.description.condition} type='text' id={"Quels sont les conditions qu'il faut avoir pour convenablement conduire la mission?"+mission.name} name={"Quels sont les conditions qu'il faut avoir pour convenablement conduire la mission?"+mission.name}
                                    //                             onChange={(e)=>{
                                    //                                 let otherMissionList = this.state.otherMissionList;
                                    //                                 otherMissionList[missionIndex].description.condition = e.target.value;
                                    //                                 this.setState({
                                    //                                     otherMissionList: otherMissionList,
                                    //                                 });
                                    //                             }}
                                    //                         />
                                    //                     </div>
                                    //                 </div>

                                    //                 <div className='section_body_question'>
                                    //                     <div className='section_body_question_label'>
                                    //                         <label>
                                    //                             Ces conditions sont-elles réunies ?
                                    //                         </label>
                                    //                     </div>
                                    //                     <div className='section_body_question_input'>
                                    //                         <input type='radio' placeholder='' id={"Ces conditions sont-elles réunies ?__oui"+mission.name}
                                    //                             name={"Ces conditions sont-elles réunies ?"+mission.name}
                                    //                             value={"oui"}
                                    //                             onChange={(e)=>{
                                    //                                 let otherMissionList = this.state.otherMissionList;
                                    //                                 otherMissionList[missionIndex].description.allConditionsSatisfy = document.forms["submissionForm"]["Ces conditions sont-elles réunies ?"+mission.name].value;
                                    //                                 this.setState({
                                    //                                     otherMissionList: otherMissionList,
                                    //                                 });
                                    //                                 // console.log(otherMissionList[missionIndex].description.allConditionsSatisfy);
                                    //                             }}
                                    //                         />
                                    //                         <label htmlFor={"Ces conditions sont-elles réunies ?__oui"+mission.name}>Oui</label>
                                    //                     </div>
                                    //                     <div className='section_body_question_input'>
                                    //                         <input type='radio' placeholder='' id={"Ces conditions sont-elles réunies ?__non" + mission.name}
                                    //                             name={"Ces conditions sont-elles réunies ?"+mission.name}
                                    //                             value={"non"}
                                    //                             onChange={(e)=>{
                                    //                                 let otherMissionList = this.state.otherMissionList;
                                    //                                 otherMissionList[missionIndex].description.allConditionsSatisfy = document.forms["submissionForm"]["Ces conditions sont-elles réunies ?"+mission.name].value;
                                    //                                 this.setState({
                                    //                                     otherMissionList: otherMissionList,
                                    //                                 });

                                    //                                 // console.log(missionList[missionIndex].description.allConditionsSatisfy);
                                    //                             }}
                                    //                         />
                                    //                         <label htmlFor={"Ces conditions sont-elles réunies ?__non" + mission.name}>Non</label>
                                    //                     </div>
                                    //                 </div>

                                    //                 <div className='section_body_question'>
                                    //                     <div className='section_body_question_label'>
                                    //                         <label htmlFor={"Que faudrait-il ?"+mission.name}>
                                    //                             Que faudrait-il ?
                                    //                         </label>
                                    //                     </div>
                                    //                     <div className='section_body_question_input'>
                                    //                         <input value={mission.description.whatMake} type='text' id={"Que faudrait-il ?"+mission.name} name={"Que faudrait-il ?"+mission.name}
                                    //                             onChange={(e)=>{
                                    //                                 let otherMissionList = this.state.otherMissionList;
                                    //                                 otherMissionList[missionIndex].description.whatMake = e.target.value;
                                    //                                 this.setState({
                                    //                                     otherMissionList: otherMissionList,
                                    //                                 });
                                    //                             }}
                                    //                         />
                                    //                     </div>
                                    //                 </div>

                                    //                 <div className='section_body_question'>
                                    //                     <div className='section_body_question_label'>
                                    //                         <label htmlFor={"Quels sont les principaux résultats attendus à la fin de l'exécution de la mission ?" + mission.name}>
                                    //                             Quels sont les principaux résultats attendus à la fin de l'exécution de la mission ?
                                    //                         </label>
                                    //                     </div>
                                    //                     <div className='section_body_question_input'>
                                    //                         <input value={mission.description.resultWaiting} type='text' id={"Quels sont les principaux résultats attendus à la fin de l'exécution de la mission ?" + mission.name} name={"Quels sont les principaux résultats attendus à la fin de l'exécution de la mission ?" + mission.name}
                                    //                             onChange={(e)=>{
                                    //                                 let otherMissionList = this.state.otherMissionList;
                                    //                                 otherMissionList[missionIndex].description.resultWaiting = e.target.value;
                                    //                                 this.setState({
                                    //                                     otherMissionList: otherMissionList,
                                    //                                 });
                                    //                             }}
                                    //                         />
                                    //                     </div>
                                    //                 </div>
                                    //             </div>

                                    //             <h3 className='section_title'>
                                    //                 Identification des processus métiers
                                    //             </h3>
                                    //             <div className='section_body'>
                                    //                 <div className='section_body_question'>
                                    //                     <div className='section_body_question_label'>
                                    //                         <label htmlFor='Quels sont les processus dont vous avez la charge ?'>
                                    //                             Quels sont les processus dont vous avez la charge ?
                                    //                         </label>
                                    //                     </div>
                                    //                     <div className='section_body_question_list'>
                                    //                         {
                                    //                             this.state.otherMissionList[missionIndex].process.processList.map((process, index) => (
                                    //                                 <div className='section_body_question_list_elt'>
                                    //                                     <span className='section_body_question_list_elt_name'>{process.name}</span><span className='section_body_question_list_elt_x'
                                    //                                         onClick={(e) => {
                                    //                                             this._deleteMissionProcess(missionIndex, index);
                                    //                                         }}
                                    //                                     >×</span>
                                    //                                 </div>
                                    //                             ))
                                    //                         }
                                    //                     </div>
                                    //                     <div className='section_body_question_button'>
                                    //                         <span className='button_style_1' onClick={(e) => {
                                    //                             let processName = prompt("Veuillez saisir le nom du processus :");
                                    //                             if(processName) {
                                    //                                 this._addMissionProcess(missionIndex, processName);
                                    //                             }
                                    //                         }}>Ajouter un processus</span>
                                    //                     </div>
                                    //                 </div>

                                    //                 <div className='section_body_question'>
                                    //                     <div className='section_body_question_label'>
                                    //                         <label>
                                    //                             La gestion des processus couvre-t-elle tous vos processus ?
                                    //                         </label>
                                    //                     </div>
                                    //                     <div className='section_body_question_input'>
                                    //                         <input onChange={(e)=>{
                                    //                             let otherMissionList = this.state.otherMissionList;
                                    //                             otherMissionList[missionIndex].process.la_gestion_des_processus_couvre_t_elle_tous_vos_processus = e.target.value;
                                    //                             this.setState({
                                    //                                 otherMissionList: otherMissionList,
                                    //                             });
                                    //                         }} type='radio' placeholder='' id='La gestion des processus couvre-t-elle tous vos processus ?__oui' name='' value="oui"/>
                                    //                         <label>Oui</label>
                                    //                     </div>
                                    //                     <div className='section_body_question_input'>
                                    //                         <input onChange={(e)=>{
                                    //                             let otherMissionList = this.state.otherMissionList;
                                    //                             otherMissionList[missionIndex].process.la_gestion_des_processus_couvre_t_elle_tous_vos_processus = e.target.value;
                                    //                             this.setState({
                                    //                                 otherMissionList: otherMissionList,
                                    //                             });
                                    //                         }} type='radio' placeholder='' id='La gestion des processus couvre-t-elle tous vos processus ?__non' name='' value="non"/>
                                    //                         <label>Non</label>
                                    //                     </div>
                                    //                 </div>


                                    //                 <div className='section_body_question'>
                                    //                     <div className='section_body_question_label'>
                                    //                         <label htmlFor=''>
                                    //                             Quel est le périmètre couvert ?
                                    //                         </label>
                                    //                     </div>
                                    //                     <div className='section_body_question_input'>
                                    //                         <input onChange={(e)=>{
                                    //                             let otherMissionList = this.state.otherMissionList;
                                    //                             otherMissionList[missionIndex].process.quel_est_le_perimetre_couvert = e.target.value;
                                    //                             this.setState({
                                    //                                 otherMissionList: otherMissionList,
                                    //                             });
                                    //                         }} type='text' id='Quel est le périmètre couvert ?' name=''/>
                                    //                     </div>
                                    //                 </div>
                                    //             </div>
                                    //         </section>


                                    //         <section className='section'>
                                    //             <h3 className='section_title'>
                                    //                 Acteurs qui interviennent dans la réalisation de la mission
                                    //             </h3>
                                    //             <div className='section_body'>
                                    //                 <div className='section_body_question'>
                                    //                     <div className='section_body_question_label'>
                                    //                         <label htmlFor={'Quels sont les agents impliqués dans la réalisation de ces missions ?:' + mission.name}>
                                    //                             Quels sont les agents impliqués dans la réalisation de ces missions ?
                                    //                         </label>
                                    //                     </div>
                                    //                     <div className='section_body_question_input'>
                                    //                         <textarea id={'Quels sont les agents impliqués dans la réalisation de ces missions ?:' + mission.name} name={'Quels sont les agents impliqués dans la réalisation de ces missions ?:' + mission.name} 
                                    //                             value={mission.actors.agents}
                                    //                             onChange={(e)=>{
                                    //                                 let otherMissionList = this.state.otherMissionList;
                                    //                                 otherMissionList[missionIndex].actors.agents = e.target.value;
                                    //                                 this.setState({
                                    //                                     otherMissionList: otherMissionList,
                                    //                                 });
                                    //                             }}
                                    //                         ></textarea>
                                    //                     </div>
                                    //                 </div>

                                    //                 <div className='section_body_question'>
                                    //                     <div className='section_body_question_label'>
                                    //                         <label htmlFor={"Quels sont les rôles de chacun de ces agents dans la réalisation de ces missions ?"+mission.name}>
                                    //                             Quels sont les rôles de chacun de ces agents dans la réalisation de ces missions ?
                                    //                         </label>
                                    //                     </div>
                                    //                     <div className='section_body_question_input'>
                                    //                         <input value={mission.actors.roles} type='text' id={"Quels sont les rôles de chacun de ces agents dans la réalisation de ces missions ?"+mission.name} name={"Quels sont les rôles de chacun de ces agents dans la réalisation de ces missions ?"+mission.name}
                                    //                             onChange={(e)=>{
                                    //                                 let otherMissionList = this.state.otherMissionList;
                                    //                                 otherMissionList[missionIndex].actors.roles = e.target.value;
                                    //                                 this.setState({
                                    //                                     otherMissionList: otherMissionList,
                                    //                                 });
                                    //                             }}
                                    //                         />
                                    //                     </div>
                                    //                 </div>

                                    //                 <div className='section_body_question'>
                                    //                     <div className='section_body_question_label'>
                                    //                         <label htmlFor={"Quels sont les acteurs externes (autres services, clients, ...) impliqués dans la réalisation de ces missions ?"+mission.name}>
                                    //                             Quels sont les acteurs externes (autres services, clients, ...) impliqués dans la réalisation de ces missions ?
                                    //                         </label>
                                    //                     </div>
                                    //                     <div className='section_body_question_input'>
                                    //                         <input value={mission.actors.externalActors} type='text' id={"Quels sont les acteurs externes (autres services, clients, ...) impliqués dans la réalisation de ces missions ?"+mission.name} name={"Quels sont les acteurs externes (autres services, clients, ...) impliqués dans la réalisation de ces missions ?"+mission.name}
                                    //                             onChange={(e)=>{
                                    //                                 let otherMissionList = this.state.otherMissionList;
                                    //                                 otherMissionList[missionIndex].actors.externalActors = e.target.value;
                                    //                                 this.setState({
                                    //                                     otherMissionList: otherMissionList,
                                    //                                 });
                                    //                             }}
                                    //                         />
                                    //                     </div>
                                    //                 </div>

                                    //                 <div className='section_body_question'>
                                    //                     <div className='section_body_question_label'>
                                    //                         <label htmlFor={"Quels sont les rôles de chacun de ces acteurs dans la réalisation de ces missions ?" + mission.name}>
                                    //                             Quels sont les rôles de chacun de ces acteurs dans la réalisation de ces missions ?
                                    //                         </label>
                                    //                     </div>
                                    //                     <div className='section_body_question_input'>
                                    //                         <input value={mission.actors.actorsRoles} type='text' id={"Quels sont les rôles de chacun de ces acteurs dans la réalisation de ces missions ?" + mission.name} name={"Quels sont les rôles de chacun de ces acteurs dans la réalisation de ces missions ?" + mission.name}
                                    //                             onChange={(e)=>{
                                    //                                 let otherMissionList = this.state.otherMissionList;
                                    //                                 otherMissionList[missionIndex].actors.actorsRoles = e.target.value;
                                    //                                 this.setState({
                                    //                                     otherMissionList: otherMissionList,
                                    //                                 });
                                    //                             }}
                                    //                         />
                                    //                     </div>
                                    //                 </div>

                                    //                 <div className='section_body_question'>
                                    //                     <div className='section_body_question_label'>
                                    //                         <label htmlFor={'Quels sont les différentes étapes de réalisation de votre mission ?:' + mission.name}>
                                    //                             Quels sont les différentes étapes de réalisation de votre mission ?
                                    //                         </label>
                                    //                     </div>
                                    //                     <div className='section_body_question_input'>
                                    //                         <textarea placeholder='conception de document, graphiques, données produites, prise de décision, approbations, etc.' id={'Quels sont les différentes étapes de réalisation de votre mission ?:' + mission.name} name={'Quels sont les différentes étapes de réalisation de votre mission ?:' + mission.name} 
                                    //                             value={mission.actors.missionEtape}
                                    //                             onChange={(e)=>{
                                    //                                 let otherMissionList = this.state.otherMissionList;
                                    //                                 otherMissionList[missionIndex].actors.missionEtape = e.target.value;
                                    //                                 this.setState({
                                    //                                     otherMissionList: otherMissionList,
                                    //                                 });
                                    //                             }}
                                    //                             ></textarea>
                                    //                     </div>
                                    //                 </div>

                                    //                 <div className='section_body_question'>
                                    //                     <div className='section_body_question_label'>
                                    //                         <label htmlFor={"Quels sont les processus qui sont réalisés dans l'accomplissement de la mission ?" + mission.name}>
                                    //                             Quels sont les processus qui sont réalisés dans l'accomplissement de la mission ?
                                    //                         </label>
                                    //                     </div>
                                    //                     <div className='section_body_question_input'>
                                    //                         <input value={mission.actors.missionProcess} type='text' id={"Quels sont les processus qui sont réalisés dans l'accomplissement de la mission ?" + mission.name} name={"Quels sont les processus qui sont réalisés dans l'accomplissement de la mission ?" + mission.name}
                                    //                             onChange={(e)=>{
                                    //                                 let otherMissionList = this.state.otherMissionList;
                                    //                                 otherMissionList[missionIndex].actors.missionProcess = e.target.value;
                                    //                                 this.setState({
                                    //                                     otherMissionList: otherMissionList,
                                    //                                 });
                                    //                             }}
                                    //                         />
                                    //                     </div>
                                    //                 </div>
                                    //             </div>
                                    //         </section>
                                    //     </>
                                    // ))
                                }
                            </section>
                            {/* <section className='section'>
                                <h3 className='section_title'>
                                    Identification des processus métiers
                                </h3>
                                <div className='section_body'>
                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor='Quels sont les processus dont vous avez la charge ?'>
                                                Quels sont les processus dont vous avez la charge ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_list'>
                                            {
                                                this.state.processList.map((process, index) => (
                                                    <div className='section_body_question_list_elt'>
                                                        <span className='section_body_question_list_elt_name'>{process.name}</span><span className='section_body_question_list_elt_x'
                                                            onClick={(e) => {
                                                                this._deleteProcess(index);
                                                            }}
                                                        >×</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className='section_body_question_button'>
                                            <span className='button_style_1' onClick={(e) => {
                                                let processName = prompt("Veuillez saisir le nom du processus :");
                                                if(processName) {
                                                    this._addProcess(processName);
                                                }
                                            }}>Ajouter un processus</span>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label>
                                                La gestion des processus couvre-t-elle tous vos processus ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='radio' placeholder='' id='La gestion des processus couvre-t-elle tous vos processus ?__oui' name='La gestion des processus couvre-t-elle tous vos processus ?' value="oui"/>
                                            <label htmlFor='La gestion des processus couvre-t-elle tous vos processus ?__oui'>Oui</label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='radio' placeholder='' id='La gestion des processus couvre-t-elle tous vos processus ?__non' name='La gestion des processus couvre-t-elle tous vos processus ?' value="non"/>
                                            <label htmlFor='La gestion des processus couvre-t-elle tous vos processus ?__non'>Non</label>
                                        </div>
                                    </div>


                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor='Quel est le périmètre couvert ?'>
                                                Quel est le périmètre couvert ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' id='Quel est le périmètre couvert ?' name='Quel est le périmètre couvert ?'/>
                                        </div>
                                    </div>
                                </div>
                            </section> */}

                            <section className='section'>
                                <h3 className='section_title'>
                                    Actions du quotidien pour la gestion des processus métiers
                                </h3>
                                <div className='section_body'>
                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor='Quelles sont les actions menées pour suivre le bon fonctionnement de ces processus ?'>
                                                Quelles sont les actions menées pour suivre le bon fonctionnement de ces processus ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='Citez les actions pour le suivi du bon fonctionnement de vos processus métiers' id='Quelles sont les actions menées pour suivre le bon fonctionnement de ces processus ?' name='Quelles sont les actions menées pour suivre le bon fonctionnement de ces processus ?'/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Quelles sont les actions menées pour assurer le bon traitement des différents incidents d'incohérence ?">
                                                Quelles sont les actions menées pour assurer le bon traitement des différents incidents d'incohérence ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' id="Quelles sont les actions menées pour assurer le bon traitement des différents incidents d'incohérence ?" name="Quelles sont les actions menées pour assurer le bon traitement des différents incidents d'incohérence ?"/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Quelles sont les actions menées pour assurer l'interfaçage des processus métiers ?">
                                                Quelles sont les actions menées pour assurer l'interfaçage des processus métiers ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' id="Quelles sont les actions menées pour assurer l'interfaçage des processus métiers ?" name="Quelles sont les actions menées pour assurer l'interfaçage des processus métiers ?"/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Quelles sont les actions menées pour assurer la communication ?">
                                                Quelles sont les actions menées pour assurer la communication ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' id="Quelles sont les actions menées pour assurer la communication ?" name="Quelles sont les actions menées pour assurer la communication ?"/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Quelles sont les actions menées pour garantir la consolidation des données et des résultats ?">
                                                Quelles sont les actions menées pour garantir la consolidation des données et des résultats ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' id="Quelles sont les actions menées pour garantir la consolidation des données et des résultats ?" name="Quelles sont les actions menées pour garantir la consolidation des données et des résultats ?"/>
                                        </div>
                                    </div>
                                </div>
                            </section>


                            <section className='section'>
                                <h3 className='section_title'>
                                    Savoir-faire et savoir-être pour une gestion performante des processus métiers
                                </h3>
                                <div className='section_body'>
                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor='Quelles sont les attitudes, compétences et comportement souhaitées pour une gestion performante de vos processus ?'>
                                                Quelles sont les attitudes, compétences et comportement souhaitées pour une gestion performante de vos processus ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='' id='Quelles sont les attitudes, compétences et comportement souhaitées pour une gestion performante de vos processus ?' name='Quelles sont les attitudes, compétences et comportement souhaitées pour une gestion performante de vos processus ?'/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label>
                                                Comment acquérir les attitudes, compétences et comportement nécessaire à une gestion performante de vos processus ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='checkbox' placeholder='' id='Comment acquérir les attitudes, compétences et comportement nécessaire à une gestion performante de vos processus ?__Formation interne' name='Comment acquérir les attitudes, compétences et comportement nécessaire à une gestion performante de vos processus ?' value="Formation interne"/>
                                            <label htmlFor='Comment acquérir les attitudes, compétences et comportement nécessaire à une gestion performante de vos processus ?__Formation interne'>Formation interne</label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='checkbox' placeholder='' id='Comment acquérir les attitudes, compétences et comportement nécessaire à une gestion performante de vos processus ?__Formation externe' name='Comment acquérir les attitudes, compétences et comportement nécessaire à une gestion performante de vos processus ?' value="Formation externe"/>
                                            <label htmlFor='Comment acquérir les attitudes, compétences et comportement nécessaire à une gestion performante de vos processus ?__Formation externe'>Formation externe</label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='checkbox' placeholder='' id='Comment acquérir les attitudes, compétences et comportement nécessaire à une gestion performante de vos processus ?__Auto-formation' name='Comment acquérir les attitudes, compétences et comportement nécessaire à une gestion performante de vos processus ?' value="Auto-formation"/>
                                            <label htmlFor='Comment acquérir les attitudes, compétences et comportement nécessaire à une gestion performante de vos processus ?__Auto-formation'>Auto-formation</label>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Quelles types de formation permettraient d'acquérir les attitudes, compétences et comportement nécessaire à une bonne gestion de vos processus métiers ?">
                                                Quelles types de formation permettraient d'acquérir les attitudes, compétences et comportement nécessaire à une bonne gestion de vos processus métiers ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' id="Quelles types de formation permettraient d'acquérir les attitudes, compétences et comportement nécessaire à une bonne gestion de vos processus métiers ?" name="Quelles types de formation permettraient d'acquérir les attitudes, compétences et comportement nécessaire à une bonne gestion de vos processus métiers ?"/>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>

                        <section className='section'>
                            <h2 className='section_title'>
                                Identification des points d'amélioration dans la réalisation des missions de l'agent
                            </h2>
                            <section className='section'>
                                <h3 className='section_title'>
                                    Amélioration des missions
                                </h3>
                                <div className='section_body'>
                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor='Quels sont les difficultés/préoccupations/problèmes que vous rencontrez dans la réalisation de vos missions ?'>
                                                Quels sont les difficultés/préoccupations/problèmes que vous rencontrez dans la réalisation de vos missions ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='En terme de procédures et méthodes, outils numériques, conditions de travail, ...' id='Quels sont les difficultés/préoccupations/problèmes que vous rencontrez dans la réalisation de vos missions ?' name='Quels sont les difficultés/préoccupations/problèmes que vous rencontrez dans la réalisation de vos missions ?'/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor='Comment procédez-vous pour résoudre les difficultés que vous rencontrez ?'>
                                                Comment procédez-vous pour résoudre les difficultés que vous rencontrez ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='En terme de procédures et méthodes, outils numériques, conditions de travail, ...' id='Comment procédez-vous pour résoudre les difficultés que vous rencontrez ?' name='Comment procédez-vous pour résoudre les difficultés que vous rencontrez ?'/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor='Quelles sont les améliorations à apporter pour simplifier votre travail ?'>
                                                Quelles sont les améliorations à apporter pour simplifier votre travail ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='En terme de procédures et méthodes, outils numériques, conditions de travail, ...' id='Quelles sont les améliorations à apporter pour simplifier votre travail ?' name='Quelles sont les améliorations à apporter pour simplifier votre travail ?'/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Quelle est la nature des actions conduites pour l'amélioration de vos missions ?">
                                                Quelle est la nature des actions conduites pour l'amélioration de vos missions ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder="En terme de revues de processus, plan d'action stratégique, ..." id="Quelle est la nature des actions conduites pour l'amélioration de vos missions ?" name="Quelle est la nature des actions conduites pour l'amélioration de vos missions ?"/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Y-a-t-il un mécanisme permettant de s'assurer que les actions conduitent correspondent bien à celles qui sont prévues ?">
                                                Y-a-t-il un mécanisme permettant de s'assurer que les actions conduitent correspondent bien à celles qui sont prévues ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder="" id="Y-a-t-il un mécanisme permettant de s'assurer que les actions conduitent correspondent bien à celles qui sont prévues ?" name="Y-a-t-il un mécanisme permettant de s'assurer que les actions conduitent correspondent bien à celles qui sont prévues ?"/>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className='section'>
                                <h3 className='section_title'>
                                    Amélioration des processus métiers
                                </h3>
                                <div className='section_body'>
                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Comment pensez-vous qu'il faudrait procéder pour améliorer le fonctionnement des processus métiers qui sont à votre charge ?">
                                                Comment pensez-vous qu'il faudrait procéder pour améliorer le fonctionnement des processus métiers qui sont à votre charge ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='' id="Comment pensez-vous qu'il faudrait procéder pour améliorer le fonctionnement des processus métiers qui sont à votre charge ?" name="Comment pensez-vous qu'il faudrait procéder pour améliorer le fonctionnement des processus métiers qui sont à votre charge ?"/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Quelles sont les règles de gestion qu'on peut supprimer sans affecter le bon déroulement de ces processus ?">
                                                Quelles sont les règles de gestion qu'on peut supprimer sans affecter le bon déroulement de ces processus ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' id="Quelles sont les règles de gestion qu'on peut supprimer sans affecter le bon déroulement de ces processus ?" name="Quelles sont les règles de gestion qu'on peut supprimer sans affecter le bon déroulement de ces processus ?"/>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className='section'>
                                <h3 className='section_title'>
                                    Utilisation optimale des ressources
                                </h3>
                                <div className='section_body'>
                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label>
                                                Pensez-vous qu'on pourrait réaliser vos missions en utilisant moins de ressources ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_subLabel'>
                                            <label>
                                                En terme de matérielles, technologiques, humaines, méthodologiques, ...
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='radio' placeholder='' id="Pensez-vous qu'on pourrait réaliser vos missions en utilisant moins de ressources ?__oui" name="Pensez-vous qu'on pourrait réaliser vos missions en utilisant moins de ressources ?" value="oui"/>
                                            <label htmlFor="Pensez-vous qu'on pourrait réaliser vos missions en utilisant moins de ressources ?__oui">Oui</label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='radio' placeholder='' id="Pensez-vous qu'on pourrait réaliser vos missions en utilisant moins de ressources ?__non" name="Pensez-vous qu'on pourrait réaliser vos missions en utilisant moins de ressources ?" value="non"/>
                                            <label htmlFor="Pensez-vous qu'on pourrait réaliser vos missions en utilisant moins de ressources ?__non">Non</label>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor='Comment devrait-on procéder pour utiliser moins de ressources afin de réaliser vos missions ?'>
                                            Comment devrait-on procéder pour utiliser moins de ressources afin de réaliser vos missions ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='' id='Comment devrait-on procéder pour utiliser moins de ressources afin de réaliser vos missions ?' name='Comment devrait-on procéder pour utiliser moins de ressources afin de réaliser vos missions ?'/>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className='section'>
                                <h3 className='section_title'>
                                    Bonne gestion des erreurs et des plaintes
                                </h3>
                                <div className='section_body'>
                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor='Y-a-t-il des erreurs qui surviennent dans la réalisation de vos missions ?'>
                                                Y-a-t-il des erreurs qui surviennent dans la réalisation de vos missions ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='' id='Y-a-t-il des erreurs qui surviennent dans la réalisation de vos missions ?' name='Y-a-t-il des erreurs qui surviennent dans la réalisation de vos missions ?'/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Qu'elles sont les raisons qui selon-vous engendre les erreurs ?">
                                                Qu'elles sont les raisons qui selon-vous engendre les erreurs ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='' id="Qu'elles sont les raisons qui selon-vous engendre les erreurs ?" name="Qu'elles sont les raisons qui selon-vous engendre les erreurs ?"/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Comment les éviter ?">
                                                Comment les éviter ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='' id="Comment les éviter ?" name="Comment les éviter ?"/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Recevez-vous des plaintes des acteurs impliqués dans la résolution de vos missions ?">
                                                Recevez-vous des plaintes des acteurs impliqués dans la résolution de vos missions ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='' id="Recevez-vous des plaintes des acteurs impliqués dans la résolution de vos missions ?" name="Recevez-vous des plaintes des acteurs impliqués dans la résolution de vos missions ?"/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Qu'elles sont les raisons qui expliquent ces plaintes ?">
                                                Qu'elles sont les raisons qui expliquent ces plaintes ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='' id="Qu'elles sont les raisons qui expliquent ces plaintes ?" name="Qu'elles sont les raisons qui expliquent ces plaintes ?"/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label>
                                                Existe-t-il un système de prise en compte des erreurs et des plaintes des acteurs (agents, clients, ...) de manière à améliorer le fonctionnement des processus ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='radio' placeholder='' id="Existe-t-il un système de prise en compte des erreurs et des plaintes des acteurs (agents, clients, ...) de manière à améliorer le fonctionnement des processus ?__oui" name="Existe-t-il un système de prise en compte des erreurs et des plaintes des acteurs (agents, clients, ...) de manière à améliorer le fonctionnement des processus ?" value="oui"/>
                                            <label htmlFor="Existe-t-il un système de prise en compte des erreurs et des plaintes des acteurs (agents, clients, ...) de manière à améliorer le fonctionnement des processus ?__oui">Oui</label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='radio' placeholder='' id="Existe-t-il un système de prise en compte des erreurs et des plaintes des acteurs (agents, clients, ...) de manière à améliorer le fonctionnement des processus ?__non" name="Existe-t-il un système de prise en compte des erreurs et des plaintes des acteurs (agents, clients, ...) de manière à améliorer le fonctionnement des processus ?" value="non"/>
                                            <label htmlFor="Existe-t-il un système de prise en compte des erreurs et des plaintes des acteurs (agents, clients, ...) de manière à améliorer le fonctionnement des processus ?__non">Non</label>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/* <section className='section'>
                                <h3 className='section_title'>
                                    Gestion des périodes d'inactivité et du rendement
                                </h3>
                                <div className='section_body'>
                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label>
                                                Avez-vous des périodes d'inactivités dans votre service ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='radio' placeholder='' id="Avez-vous des périodes d'inactivités dans votre service ?__oui" name="Avez-vous des périodes d'inactivités dans votre service ?" value="oui"/>
                                            <label htmlFor="Avez-vous des périodes d'inactivités dans votre service ?__oui">Oui</label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='radio' placeholder='' id="Avez-vous des périodes d'inactivités dans votre service ?__non" name="Avez-vous des périodes d'inactivités dans votre service ?" value="non"/>
                                            <label htmlFor="Avez-vous des périodes d'inactivités dans votre service ?__non">Non</label>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Quelles sont les raisons qui peuvent expliquer que vous avez des périodes d'inactivités dans votre service ?">
                                                Quelles sont les raisons qui peuvent expliquer que vous avez des périodes d'inactivités dans votre service ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='' id="Quelles sont les raisons qui peuvent expliquer que vous avez des périodes d'inactivités dans votre service ?" name="Quelles sont les raisons qui peuvent expliquer que vous avez des périodes d'inactivités dans votre service ?"/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Comment pensez-vous qu'il faudrait procéder pour régler ce problème ?">
                                                Comment pensez-vous qu'il faudrait procéder pour régler ce problème ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='' id="Comment pensez-vous qu'il faudrait procéder pour régler ce problème ?" name="Comment pensez-vous qu'il faudrait procéder pour régler ce problème ?"/>
                                        </div>
                                    </div>
                                </div>
                            </section> */}

                            <section className='section'>
                                <h3 className='section_title'>
                                    Conduite du changement dans la réalisation des missions de l'agent
                                </h3>
                                <div className='section_body'>
                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Existe t-il un système permettant de conduire les changements des processus métiers et facilitant l'intégration des améliorations de ceux-ci ?">
                                                Existe t-il un système permettant de conduire les changements des processus métiers et facilitant l'intégration des améliorations de ceux-ci ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='' id="Existe t-il un système permettant de conduire les changements des processus métiers et facilitant l'intégration des améliorations de ceux-ci ?" name="Existe t-il un système permettant de conduire les changements des processus métiers et facilitant l'intégration des améliorations de ceux-ci ?"/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Quelles sont les actions de communication concernant la démarche, les actions conduites, les résultats obtenus ?">
                                                Quelles sont les actions de communication concernant la démarche, les actions conduites, les résultats obtenus ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='' id="Quelles sont les actions de communication concernant la démarche, les actions conduites, les résultats obtenus ?" name="Quelles sont les actions de communication concernant la démarche, les actions conduites, les résultats obtenus ?"/>
                                        </div>
                                    </div>

                                    <div className='section_body_question'>
                                        <div className='section_body_question_label'>
                                            <label htmlFor="Existe-t-il un système d'écoute clients de manière à appréhender leurs besoins et mesurer en retour les impacts des conduites de changement des processus et de leur intégration ?">
                                                Existe-t-il un système d'écoute clients de manière à appréhender leurs besoins et mesurer en retour les impacts des conduites de changement des processus et de leur intégration ?
                                            </label>
                                        </div>
                                        <div className='section_body_question_input'>
                                            <input type='text' placeholder='' id="Existe-t-il un système d'écoute clients de manière à appréhender leurs besoins et mesurer en retour les impacts des conduites de changement des processus et de leur intégration ?" name="Existe-t-il un système d'écoute clients de manière à appréhender leurs besoins et mesurer en retour les impacts des conduites de changement des processus et de leur intégration ?"/>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>

                        

{/* -----------------------------------------------------------------------------------------------------*/}
                        {/* Iterration sur les processus metiers */}
                        {/* <section className='section'>
                            <h2 className='section_title'>
                                Modélisation des processus métiers d'une mission (Rappel : Un processus métier est un ensemble de tâches à réaliser pour l'accomplissement d'une mission)
                            </h2>
                            {
                                this.state.processList.map((process, processIndex) => (
                                    <>
                                        <section className='section'>
                                            <h2 className='title'>
                                                {process.name} 
                                            </h2>
                                            <br/>
                                            <h3 className='section_title'>
                                                Identification des tâches de chaque processus métiers (Rappel : une tâche est une activité élémentaire à effectuer)
                                            </h3>
                                            <div className='section_body'>
                                                <div className='section_body_question'>
                                                    <div className='section_body_question_label'>
                                                        <label htmlFor={"Quel est l'événement qui déclenche la réalisation du processus ?:" + process.name}>
                                                            Quel est l'événement qui déclenche la réalisation du processus ?
                                                        </label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input placeholder="Décrivez le plus fidèlement possible l'événement qui subvient pour déclencher le début du processus" 
                                                            value={process.taskIdentification.eventListener} type='text' id={"Quel est l'événement qui déclenche la réalisation du processus ?"+process.name} name={"Quel est l'événement qui déclenche la réalisation du processus ?"+process.name}
                                                            onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.eventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='section_body_question'>
                                                    <div className='section_body_question_subLabel'>
                                                        <label>
                                                            Choisissez celui qui correspond le mieux
                                                        </label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Générique : Début automatique du processus" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Générique : Début automatique du processus"
                                                            onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                            }}
                                                        />
                                                        <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Générique : Début automatique du processus">Générique : Début automatique du processus</label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Message : Arrivé d'un message d'un autre processus" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Message : Arrivé d'un message d'un autre processus"
                                                            onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                            }}
                                                        />
                                                        <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Message : Arrivé d'un message d'un autre processus">Message : Arrivé d'un message d'un autre processus</label>
                                                    </div>

                                                    <div className='section_body_question_input'>
                                                        <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Temporisateur : Début à un moment temporelle donné" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Temporisateur : Début à un moment temporelle donné"
                                                            onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                            }}
                                                        />
                                                        <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Temporisateur : Début à un moment temporelle donné">Temporisateur : Début à un moment temporelle donné</label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Conditionnel : Début conditionné à la réalisation d'un événement" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Conditionnel : Début conditionné à la réalisation d'un événement"
                                                            onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                            }}
                                                        />
                                                        <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Conditionnel : Début conditionné à la réalisation d'un événement">Conditionnel : Début conditionné à la réalisation d'un événement</label>
                                                    </div>

                                                    <div className='section_body_question_input'>
                                                        <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Signal : Début à la réception d'un signal d'un autre processus" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Signal : Début à la réception d'un signal d'un autre processus"
                                                            onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                            }}
                                                        />
                                                        <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Signal : Début à la réception d'un signal d'un autre processus">Signal : Début à la réception d'un signal d'un autre processus</label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Parallèle : Début conditionné à de nombreux événements à prendre en compte" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Parallèle : Début conditionné à de nombreux événements à prendre en compte"
                                                            onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                            }}
                                                        />
                                                        <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Parallèle : Début conditionné à de nombreux événements à prendre en compte">Parallèle : Début conditionné à de nombreux événements à prendre en compte</label>
                                                    </div>

                                                    <div className='section_body_question_input'>
                                                        <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Multiple : Début conditionné à la réalisation simultanée de plusieurs événements" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Multiple : Début conditionné à la réalisation simultanée de plusieurs événements"
                                                            onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                            }}
                                                        />
                                                        <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Multiple : Début conditionné à la réalisation simultanée de plusieurs événements">Multiple : Début conditionné à la réalisation simultanée de plusieurs événements</label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input type='radio' placeholder='' id="Quel est l'événement qui déclenche la réalisation du processus ?__Autre" name={"Quel est l'événement qui déclenche la réalisation du processus ?" + process.name} value="Autre"
                                                            onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                            }}
                                                        />
                                                        <label htmlFor="Quel est l'événement qui déclenche la réalisation du processus ?__Autre">Autre</label>
                                                    </div>
                                                </div>

                                                <div className='section_body_question'>
                                                    <div className='section_body_question_label'>
                                                        <label htmlFor={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?:" + process.name}>
                                                            Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?
                                                        </label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input placeholder="Décrivez l'événement qui survient à la fin du processus" 
                                                            value={process.taskIdentification.end_eventListener} type='text' id={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?"+process.name} name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?"+process.name}
                                                            onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.end_eventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='section_body_question'>
                                                    <div className='section_body_question_subLabel'>
                                                        <label>
                                                            Choisissez celui qui correspond le mieux
                                                        </label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                        }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus"/>
                                                        <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus">Fin d'un processus</label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                        }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus composé de plusieurs modules de finition" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus composé de plusieurs modules de finition"/>
                                                        <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus composé de plusieurs modules de finition">Fin d'un processus composé de plusieurs modules de finition</label>
                                                    </div>

                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                        }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant l'émission d'un message à envoyer à un autre processus" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus déclenchant l'émission d'un message à envoyer à un autre processus"/>
                                                        <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant l'émission d'un message à envoyer à un autre processus">Fin d'un processus déclenchant l'émission d'un message à envoyer à un autre processus</label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                        }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant l'attribution d'une tâche à une entité" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus déclenchant l'attribution d'une tâche à une entité"/>
                                                        <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant l'attribution d'une tâche à une entité">Fin d'un processus déclenchant l'attribution d'une tâche à une entité</label>
                                                    </div>

                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                        }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus initiant l'émission d'un signal pour d'autres processus" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus initiant l'émission d'un signal pour d'autres processus"/>
                                                        <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus initiant l'émission d'un signal pour d'autres processus">Fin d'un processus initiant l'émission d'un signal pour d'autres processus</label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                        }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant un message d'erreur" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin d'un processus déclenchant un message d'erreur"/>
                                                        <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin d'un processus déclenchant un message d'erreur">Fin d'un processus déclenchant un message d'erreur</label>
                                                    </div>

                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                        }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin de traitement de tout le processus par suite de détection d'une incohérence grave" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin de traitement de tout le processus par suite de détection d'une incohérence grave"/>
                                                        <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin de traitement de tout le processus par suite de détection d'une incohérence grave">Fin de traitement de tout le processus par suite de détection d'une incohérence grave</label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                        }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin de traitement d'un processus en déclenchant le traitement d'un processus de remédiation" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Fin de traitement d'un processus en déclenchant le traitement d'un processus de remédiation"/>
                                                        <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Fin de traitement d'un processus en déclenchant le traitement d'un processus de remédiation">Fin de traitement d'un processus en déclenchant le traitement d'un processus de remédiation</label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.otherEnd_eventListener = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                        }} type='radio' placeholder='' id="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Autre" name={"Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?" + process.name} value="Autre"/>
                                                        <label htmlFor="Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?__Autre">Autre</label>
                                                    </div>
                                                </div>

                                                <div className='section_body_question'>
                                                    <div className='section_body_question_label'>
                                                        <label htmlFor="Quelles sont les tâches à réaliser pour l'accomplissement du processus?">
                                                            Quelles sont les tâches à réaliser pour l'accomplissement du processus?
                                                        </label>
                                                    </div>
                                                    <div className='section_body_question_list'>
                                                        {
                                                            process.taskIdentification.taskList.map((task, taskIndex) => (
                                                                <div className='section_body_question_list_elt'>
                                                                    <span className='section_body_question_list_elt_name'>{task.name}</span><span className='section_body_question_list_elt_x'
                                                                        onClick={(e) => {
                                                                            this._deleteProcessTask(processIndex, taskIndex);
                                                                        }}
                                                                    >×</span>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                    <div className='section_body_question_button'>
                                                        <span className='button_style_1' onClick={(e) => {
                                                            let taskName = prompt("Veuillez saisir le nom de la tâche :");
                                                            if(taskName) {
                                                                this._addProcessTask(processIndex, taskName);
                                                            }
                                                        }}>Ajouter une tâche</span>
                                                    </div>
                                                </div>

                                                <div className='section_body_question'>
                                                    <div className='section_body_question_label'>
                                                        <label htmlFor={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?:" + process.name}>
                                                            Qu'elles sont les liens qui existent entre les tâches élémentaires ?
                                                        </label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input placeholder="Décrivez les liens qui existent entre les tâches."
                                                            value={process.taskIdentification.elementaryTaskLink} type='text' id={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?"+process.name} name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?"+process.name}
                                                            onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.elementaryTaskLink = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='section_body_question'>
                                                    <div className='section_body_question_subLabel'>
                                                        <label>
                                                            Choisissez celui qui correspond le mieux
                                                        </label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                            let processList = this.state.processList;
                                                            processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                            this.setState({
                                                                processList: processList,
                                                            });
                                                        }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Exclusif : le traitement ne suit que l'une seule des possibilités. Le traitement peut aussi être confié à une autre partie prenante qui en avait précédemment la charge" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Exclusif : le traitement ne suit que l'une seule des possibilités. Le traitement peut aussi être confié à une autre partie prenante qui en avait précédemment la charge"/>
                                                        <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Exclusif : le traitement ne suit que l'une seule des possibilités. Le traitement peut aussi être confié à une autre partie prenante qui en avait précédemment la charge">Exclusif : le traitement ne suit que l'une seule des possibilités. Le traitement peut aussi être confié à une autre partie prenante qui en avait précédemment la charge</label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                            let processList = this.state.processList;
                                                            processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                            this.setState({
                                                                processList: processList,
                                                            });
                                                        }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Exclusif basé sur les évènements : existence de multiples évènements dont l'un seul à la fois peut subvenir comme entré d'un processus" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Exclusif basé sur les évènements : existence de multiples évènements dont l'un seul à la fois peut subvenir comme entré d'un processus"/>
                                                        <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Exclusif basé sur les évènements : existence de multiples évènements dont l'un seul à la fois peut subvenir comme entré d'un processus">Exclusif basé sur les évènements : existence de multiples évènements dont l'un seul à la fois peut subvenir comme entré d'un processus</label>
                                                    </div>

                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                            let processList = this.state.processList;
                                                            processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                            this.setState({
                                                                processList: processList,
                                                            });
                                                        }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Inclusif : le traitement dépend de plusieurs évènements qui peuvent se réaliser" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Inclusif : le traitement dépend de plusieurs évènements qui peuvent se réaliser"/>
                                                        <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Inclusif : le traitement dépend de plusieurs évènements qui peuvent se réaliser">Inclusif : le traitement dépend de plusieurs évènements qui peuvent se réaliser</label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                            let processList = this.state.processList;
                                                            processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                            this.setState({
                                                                processList: processList,
                                                            });
                                                        }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Parallèle : le traitement se divise en plusieurs traitements qui peuvent se réaliser simultanément" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Parallèle : le traitement se divise en plusieurs traitements qui peuvent se réaliser simultanément"/>
                                                        <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Parallèle : le traitement se divise en plusieurs traitements qui peuvent se réaliser simultanément">Parallèle : le traitement se divise en plusieurs traitements qui peuvent se réaliser simultanément</label>
                                                    </div>

                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                            let processList = this.state.processList;
                                                            processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                            this.setState({
                                                                processList: processList,
                                                            });
                                                        }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Initiale parallèle : le processus démarre à partir de plusieurs évènements" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Initiale parallèle : le processus démarre à partir de plusieurs évènements"/>
                                                        <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Initiale parallèle : le processus démarre à partir de plusieurs évènements">Initiale parallèle : le processus démarre à partir de plusieurs évènements</label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                            let processList = this.state.processList;
                                                            processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                            this.setState({
                                                                processList: processList,
                                                            });
                                                        }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Synchronisation inclusive : plusieurs traitements se regroupent en ce point pour constituer une entrée d'un processus" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Synchronisation inclusive : plusieurs traitements se regroupent en ce point pour constituer une entrée d'un processus"/>
                                                        <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Synchronisation inclusive : plusieurs traitements se regroupent en ce point pour constituer une entrée d'un processus">Synchronisation inclusive : plusieurs traitements se regroupent en ce point pour constituer une entrée d'un processus</label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input onChange={(e)=>{
                                                            let processList = this.state.processList;
                                                            processList[processIndex].taskIdentification.otherElementaryTaskLink = e.target.value;
                                                            this.setState({
                                                                processList: processList,
                                                            });
                                                        }} type='radio' placeholder='' id="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Autre" name={"Qu'elles sont les liens qui existent entre les tâches élémentaires ?" + process.name} value="Autre"/>
                                                        <label htmlFor="Qu'elles sont les liens qui existent entre les tâches élémentaires ?__Autre">Autre</label>
                                                    </div>
                                                </div>

                                                <div className='section_body_question'>
                                                    <div className='section_body_question_label'>
                                                        <label htmlFor={"Dans la chaîne de réalisation de chacune de vos missions, quelles sont les problèmes, difficultés et préoccupations que vous rencontrés ?:" + process.name}>
                                                            Dans la chaîne de réalisation de chacune de vos missions, quelles sont les problèmes, difficultés et préoccupations que vous rencontrés ?
                                                        </label>
                                                    </div>
                                                    <div className='section_body_question_input'>
                                                        <input placeholder="Décrivez les liens qui existent entre les tâches."
                                                            value={process.taskIdentification.missionProblems} type='text' id={"Dans la chaîne de réalisation de chacune de vos missions, quelles sont les problèmes, difficultés et préoccupations que vous rencontrés ?"+process.name} name={"Dans la chaîne de réalisation de chacune de vos missions, quelles sont les problèmes, difficultés et préoccupations que vous rencontrés ?"+process.name}
                                                            onChange={(e)=>{
                                                                let processList = this.state.processList;
                                                                processList[processIndex].taskIdentification.missionProblems = e.target.value;
                                                                this.setState({
                                                                    processList: processList,
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </>
                                ))
                            }
                        </section> */}

                        {/* <section className='section'>
                            <h2 className='section_title'>
                                Perception de l'agent sur sa fonction
                            </h2>
                            <div className='section_body'>
                                <div className='section_body_question'>
                                    <div className='section_body_question_label'>
                                        <label htmlFor="Quelle est selon-vous l'importance de votre travail pour votre organisation ?">
                                            Quelle est selon-vous l'importance de votre travail pour votre organisation ?
                                        </label>
                                    </div>
                                    <div className='section_body_question_input'>
                                        <input required type='text' id="Quelle est selon-vous l'importance de votre travail pour votre organisation ?" name="Quelle est selon-vous l'importance de votre travail pour votre organisation ?"/>
                                    </div>
                                </div>

                                <div className='section_body_question'>
                                    <div className='section_body_question_label'>
                                        <label>
                                            Obtenez-vous satisfaction de votre travail ?
                                        </label>
                                    </div>
                                    <div className='section_body_question_input'>
                                        <input type='radio' placeholder='' id='Obtenez-vous satisfaction de votre travail ?__oui' name='Obtenez-vous satisfaction de votre travail ?' value="oui"/>
                                        <label htmlFor='Obtenez-vous satisfaction de votre travail ?__oui'>Oui</label>
                                    </div>
                                    <div className='section_body_question_input'>
                                        <input type='radio' placeholder='' id='Obtenez-vous satisfaction de votre travail ?__non' name='Obtenez-vous satisfaction de votre travail ?' value="non"/>
                                        <label htmlFor='Obtenez-vous satisfaction de votre travail ?__non'>Non</label>
                                    </div>
                                </div>

                                <div className='section_body_question'>
                                    <div className='section_body_question_label'>
                                        <label htmlFor='Comment qualifierez-vous les relations que vous entretenez avec votre supérieur hiérarchique ?'>
                                            Comment qualifierez-vous les relations que vous entretenez avec votre supérieur hiérarchique ?
                                        </label>
                                    </div>
                                    <div className='section_body_question_input'>
                                        <input type='text' id='Comment qualifierez-vous les relations que vous entretenez avec votre supérieur hiérarchique ?' name='Comment qualifierez-vous les relations que vous entretenez avec votre supérieur hiérarchique ?'/>
                                    </div>
                                </div>

                                <div className='section_body_question'>
                                    <div className='section_body_question_label'>
                                        <label htmlFor="Connaissez-vous les critères de nomination de l'équipe de gouvernance des processus ?">
                                            Connaissez-vous les critères de nomination de l'équipe de gouvernance des processus ?
                                        </label>
                                    </div>
                                    <div className='section_body_question_input'>
                                        <input type='text' id="Connaissez-vous les critères de nomination de l'équipe de gouvernance des processus ?" name="Connaissez-vous les critères de nomination de l'équipe de gouvernance des processus ?"/>
                                    </div>
                                </div>

                                <div className='section_body_question'>
                                    <div className='section_body_question_label'>
                                        <label htmlFor="Quelles sont les difficultés que vous avez rencontrées pour prendre en charge votre poste et dans l'exercice de celui-ci ?">
                                            Quelles sont les difficultés que vous avez rencontrées pour prendre en charge votre poste et dans l'exercice de celui-ci ?
                                        </label>
                                    </div>
                                    <div className='section_body_question_input'>
                                        <input type='text' id="Quelles sont les difficultés que vous avez rencontrées pour prendre en charge votre poste et dans l'exercice de celui-ci ?" name="Quelles sont les difficultés que vous avez rencontrées pour prendre en charge votre poste et dans l'exercice de celui-ci ?"/>
                                    </div>
                                </div>

                                <div className='section_body_question'>
                                    <div className='section_body_question_label'>
                                        <label htmlFor="Quels sont les conseils que vous souhaitez donner pour aider d'autres services ou départements ?">
                                            Quels sont les conseils que vous souhaitez donner pour aider d'autres services ou départements ?
                                        </label>
                                    </div>
                                    <div className='section_body_question_input'>
                                        <input type='text' id="Quels sont les conseils que vous souhaitez donner pour aider d'autres services ou départements ?" name="Quels sont les conseils que vous souhaitez donner pour aider d'autres services ou départements ?"/>
                                    </div>
                                </div>

                                <div className='section_body_question'>
                                    <div className='section_body_question_label'>
                                        <label htmlFor="Quelles satisfactions, à titre personnel, trouvez-vous dans l'exercice de votre fonction ?">
                                            Quelles satisfactions, à titre personnel, trouvez-vous dans l'exercice de votre fonction ?
                                        </label>
                                    </div>
                                    <div className='section_body_question_input'>
                                        <input type='text' id="Quelles satisfactions, à titre personnel, trouvez-vous dans l'exercice de votre fonction ?" name="Quelles satisfactions, à titre personnel, trouvez-vous dans l'exercice de votre fonction ?"/>
                                    </div>
                                </div>
                            </div>
                        </section> */}

                        <div className='formButton'>
                            <button className='button_style_1'>
                                Merci d'envoyer vos réponses
                            </button>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}


HomeView = connect(mapStateToProps, null)(HomeView);
export default HomeView;