import React from 'react';
import { connect } from 'react-redux';


/**----------------------------------------------------------------------------------------*/
/**Importation des composants de la page */


/**----------------------------------------------------------------------------------------*/
/** Importation des screens */



/**----------------------------------------------------------------------------------------*/
/**Importation du superScreen*/
import SuperScreen from '../class/SuperScreen';



/**----------------------------------------------------------------------------------------*/
/**Importation des styles de la page */



/**----------------------------------------------------------------------------------------*/
/**Importation des images*/



import AccessStore from '../store/accessStore';

function mapStateToProps(state) {
    return {
        user : state.User.user,
    };
}

class DashboardSubmissionList extends SuperScreen {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            submissionList: [],

            optionsList: [
                {
                  "text": "Quel poste occupez-vous ?",
                  "value": "poste_occuper"
                },
                {
                  "text": "Dans quel service travaillez-vous ?",
                  "value": "service_travaillez_vous"
                },
                {
                  "text": "A quels service(s) ou département(s) ou direction(s) êtes-vous hiérarchiquement rattaché ?",
                  "value": "service_departement_direction_hierarchi"
                },
                {
                  "text": "Qui est votre supérieur hiérarchique direct ?",
                  "value": "superieur_hierarchique"
                },
                {
                  "text": "A qui reportez-vous dans le service ?",
                  "value": "a_qui_reportez_vous"
                },
                {
                  "text": "Qui, au sein du service/département/direction affecte les missions ?",
                  "value": "qui_au_sein_affecte_les_missions"
                },
                {
                  "text": "Quelles sont les missions/activités régaliennes que vous devez accomplir dans le cadre de votre poste ?",
                  "value": "mission",
                  "list": [
                    {
                      "text": "Nom de la mission",
                      "value": "name"
                    },
                    {
                      "text": "Description de la mission",
                      "value": "dexcription",
                      "list": [
                        {
                          "text": "Décrivez la mission",
                          "value": "description"
                        },{
                          "text": "Quels sont les conditions qu'il faut avoir pour convenablement conduire la mission?",
                          "value": "condition"
                        },{
                          "text": "Ces conditions sont-elles réunies ?",
                          "value": "allConditionsSatisfy"
                        },{
                          "text": "Que faudrait-il ?",
                          "value": "whatMake"
                        },{
                          "text": "Quels sont les principaux résultats attendus à la fin de l'exécution de la mission ?",
                          "value": "resultWaiting"
                        },
                      ]
                    },{
                      "text": "Acteurs qui interviennent dans la réalisation de la mission",
                      "value": "actors",
                      "list": [
                        {
                          "text": "Quels sont les agents impliqués dans la réalisation de ces missions ?",
                          "value": "agents"
                        },{
                          "text": "Quels sont les rôles de chacun de ces agents dans la réalisation de ces missions ?",
                          "value": "roles"
                        },{
                          "text": "Quels sont les acteurs externes (autres services, clients, ...) impliqués dans la réalisation de ces missions ?",
                          "value": "externalActors"
                        },{
                          "text": "Quels sont les rôles de chacun de ces acteurs dans la réalisation de ces missions ?",
                          "value": "actorsRoles"
                        },{
                          "text": "Quels sont les différentes étapes de réalisation de votre mission ?",
                          "value": "missionEtape"
                        },{
                          "text": "Quels sont les processus qui sont réalisés dans l'accomplissement de la mission ?",
                          "value": "missionProcess"
                        }
                      ]
                    },{
                      "text": "Identification des processus métiers",
                      "value": "process",
                      "list": [
                        {
                          "text": "Quels sont les processus dont vous avez la charge pour la réalisation de la mission ?",
                          "value": "processList",
                          "list": [
                            {
                              "text": "Nom du processus",
                              "value": "name"
                            },{
                              "text": "Identification des tâches de chaque processus métiers",
                              "value": "taskIdentification",
                              "list": [
                                {
                                  "text": "Quel est l'événement qui déclenche la réalisation du processus ?",
                                  "value": "eventListener"
                                },{
                                  "text": "Ou choisissez celui qui correspond le mieux",
                                  "value": "otherEventListener"
                                },{
                                  "text": "Quel est l'événement qui subvient pour mettre fin à l'exécution du processus?",
                                  "value": "end_eventListener"
                                },{
                                  "text": "Ou Choisissez celui qui correspond le mieux",
                                  "value": "otherEnd_eventListener"
                                },{
                                  "text": "Quelles sont les tâches à réaliser pour l'accomplissement du processus?",
                                  "value": "taskList",
                                  "list": [
                                    {
                                      "text": "Nom de la tâche",
                                      "value": "name"
                                    },{
                                      "text": "Précision sur la durée de la tâche à la réalisation de la tâche",
                                      "value": "taskDuration",
                                      "list": [
                                        {
                                          "text": "Comment mesurez-vous l'avancement dans la réalisation de cette tâche ?",
                                          "value": "progressionWeightUnit"
                                        },
                                        {
                                          "text": "Quelle est la durée prévue pour la réalisation de cette tâche ?",
                                          "value": "taskDurationValue"
                                        },
                                        {
                                          "text": "Quel est le temps moyen que vous passez à réaliser la tâche ?",
                                          "value": "averageTaskTimeValue"
                                        },
                                        {
                                          "text": "Observez-vous des retards dans la réalisation de cette tâche ?",
                                          "value": "taskLateness"
                                        },
                                        {
                                          "text": "Qu'elles sont les raisons qui selon-vous engendrent des retards ?",
                                          "value": "taskLatenessRaison"
                                        },
                                        {
                                          "text": "Comment les éviter ?",
                                          "value": "taskLatenessSolution"
                                        },
                                        {
                                          "text": "Quels sont les coûts (financiers, matériels, humains, ...) engendrés par des retards sur la réalisation de cette tâche ?",
                                          "value": "taskLatenessCoast"
                                        },
                                      ]
                                    },
                                    {
                                      "text": "Précision sur les ressources méthodologiques nécessaires à la réalisation de la tâche",
                                      "value": "taskMetResource",
                                      "list": [
                                        {
                                          "text": "Quelles sont les ressources méthodologiques qui sont nécessaires à la réalisation de cette tâche ?",
                                          "value": "taskMetResourceValue"
                                        },
                                        {
                                          "text": "Quelles sont les règles métiers à appliquer pour valider la tâche ou les résultats produits ?",
                                          "value": "taskRulJob"
                                        },
                                        {
                                          "text": "Quelles sont les contraintes opérationnelles à observer pour la réalisation de la tâche ?",
                                          "value": "taskOperationalProblems"
                                        },
                                        {
                                          "text": "Est ce que la tâche fait intervenir plusieurs équipes différentes ?",
                                          "value": "taskDifferentCrew"
                                        },
                                        {
                                          "text": "Y-a-t-il des règles méthodologiques à observer dans les échanges entre les équipes qui interviennent dans la réalisation de cette tâche ?",
                                          "value": "taskMetRulExchange"
                                        },
                                      ]
                                    },
                                    {
                                      "text": "Précision sur les ressources technologiques et matérielles",
                                      "value": "taskTechResource",
                                      "list": [
                                        {
                                          "text": "Quelles sont les équipements infractructurelles qui sont nécessaires à la réalisation de cette tâche ?",
                                          "value": "taskInfraEquipment"
                                        },{
                                          "text": "Quelles sont les équipements logicielles qui sont nécessaires à la réalisation de cette tâche ?",
                                          "value": "taskLogicEquipment"
                                        },{
                                          "text": "Quelles sont les autres ressources technologiques qui sont nécessaires à la réalisation de cette tâche ?",
                                          "value": "taskOtherTechResource"
                                        },{
                                          "text": "Quelles sont les autres ressources matérielles qui sont nécessaires à la réalisation de cette tâche?",
                                          "value": "taskOtherMaterialResource"
                                        },
                                      ]
                                    },
                                    {
                                      "text": "Autres ressources nécessaires à la réalisation de la tâche",
                                      "value": "taskOthersResource",
                                      "list": [
                                        {
                                          "text": "Quelles sont les autres ressources nécessaires à la réalisation de cette tâche ?",
                                          "value": "taskOthersResourceValue"
                                        },{
                                          "text": "Toutes ces ressources sont elles disponibles ?",
                                          "value": "taskAllOthersResourceAvailable"
                                        },{
                                          "text": "Qu'est-ce qu'il faudrait ?",
                                          "value": "taskOthersResourceSolution"
                                        }
                                      ]
                                    },
                                    {
                                      "text": "Précision sur les échanges de données",
                                      "value": "taskDataExchange",
                                      "list": [
                                        {
                                          "text": "Quels sont les messages à envoyer ou à recevoir ?",
                                          "value": "messageToSendAndReceive"
                                        },{
                                          "text": "Quels sont les formulaires à saisir ?",
                                          "value": "forms"
                                        }
                                      ]
                                    },
                                    {
                                      "text": "Tâche manuelle ou automatisée",
                                      "value": "taskManualAuto",
                                      "list": [
                                        {
                                          "text": "La tâche est-elle actuellement exécutée manuellement ?",
                                          "value": "taskIsManual"
                                        },{
                                          "text": "La tâche est-elle exécutée par des agents internes au service?",
                                          "value": "taskIsMakeByInternAgents"
                                        },{
                                          "text": "Quelles sont les agents internes qui interviennent dans la réalisation de la tâche ?",
                                          "value": "taskInternAgents"
                                        },{
                                          "text": "La tâche est-elle exécutée par des agents externes au service?",
                                          "value": "taskIsMakeByExternalAgents"
                                        },{
                                          "text": "Quelles sont les agents externes qui interviennent dans la réalisation de la tâche ?",
                                          "value": "taskExternalAgents"
                                        },{
                                          "text": "La tâche est-elle exécutée par des acteurs externes au service ?",
                                          "value": "taskIsMakeByExternalActors"
                                        },{
                                          "text": "Quelles sont les acteurs externes qui interviennent dans la réalisation de la tâche ?",
                                          "value": "taskExternalActors"
                                        },{
                                          "text": "La tâche est-elle exécutée à partir de ressources informatiques ?",
                                          "value": "taskHasInformatiqueResource"
                                        },{
                                          "text": "Quelles sont les ressources informatiques qui sont utilisées dans la réalisation de la tâche ?",
                                          "value": "taskInformatiqueResource"
                                        }
                                      ]
                                    },
                                    {
                                      "text": "Précisions sur les problèmes rencontrés",
                                      "value": "taskProblems",
                                      "list": [
                                        {
                                          "text": "Quels sont les problèmes que vous rencontrez dans la réalisation de cette tâche ?",
                                          "value": "taskProblemsValue"
                                        },{
                                          "text": "Comment procédez-vous pour résoudre ces problèmes ?",
                                          "value": "taskProblemsSolutions"
                                        },{
                                          "text": "Comment pensez-vous qu'on pourrait procéder pour régler définitivement ces problèmes ?",
                                          "value": "taskProblemsDefinitiveSolution"
                                        },{
                                          "text": "Autres commentaires",
                                          "value": "taskProblemsOthersComments"
                                        }
                                      ]
                                    }
                                  ]
                                },{
                                  "text": "Qu'elles sont les liens qui existent entre les tâches élémentaires ?",
                                  "value": "elementaryTaskLink"
                                },{
                                  "text": "Ou Choisissez celui qui correspond le mieux",
                                  "value": "otherElementaryTaskLink"
                                },{
                                  "text": "Dans la chaîne de réalisation de chacune de vos missions, quelles sont les problèmes, difficultés et préoccupations que vous rencontrés ?",
                                  "value": "missionProblems"
                                },
                              ]
                            },
                          ]
                        },{
                          "text": "La gestion des processus couvre-t-elle tous vos processus ?",
                          "value": "la_gestion_des_processus_couvre_t_elle_tous_vos_processus"
                        },{
                          "text": "Quel est le périmètre couvert ?",
                          "value": "quel_est_le_perimetre_couvert"
                        }
                      ]
                    }
                  ]
                },
                // {
                //   "text": "Quelles sont les autres missions que vous accomplissez de façon fréquente en dehors de vos missions régaliennes ?",
                //   "value": "othersMission"
                // },
                {
                  "text": "Avez-vous une définition écrite de chacune de vos missions ?",
                  "value": "avez_vous_une_definition_ecrite_de_chacune_de_vos_missions"
                },
                {
                  "text": "Quels sont les divers points que comprend les définitions de vos missions ?",
                  "value": "divers_points_que_comprend_les_definitions_de_vos_missions"
                },
                {
                  "text": "Existe-t-il des instances spécifiques pour traiter et arbitrer d'éventuels conflits dans la réalisation de vos missions ?",
                  "value": "instances_specifiques_pour_traiter_et_arbitrer_deventuels_conflits_dans_la_realisation_de_vos_missions"
                },
                {
                  "text": "Y a-t-il des échelles d'évaluation des enjeux de vos missions ?",
                  "value": "echelles_evaluation_des_enjeux_de_vos_missions"
                },
                {
                  "text": "Qui, au sein du service/département/direction juge les résultats obtenus ?",
                  "value": "qui_au_sein_juge_les_resultats_obtenus"
                },
                {
                  "text": "Comment sont mesurés les résultats obtenus dans l'accomplissement de vos missions ?",
                  "value": "comment_sont_mesures_les_resultats_obtenus_dans_laccomplissement_de_vos_missions"
                },
                {
                  "text": "Avez-vous déterminé des indicateurs de base pour la gestion de vos différentes missions ?",
                  "value": "avez_vous_determine_des_indicateurs_de_base_pour_la_gestion_de_vos_differentes_missions"
                },
                {
                  "text": "Ces indicateurs sont-ils toujours consolidables ?",
                  "value": "ces_indicateurs_sont_ils_toujours_consolidables"
                },
                {
                  "text": "Quelles sont les raisons qui peuvent expliquer que des indicateurs ne sont pas toujours consolidables ?",
                  "value": "raison_qui_explique_que_des_indicateurs_ne_sont_pas_toujours_consolidables"
                },
                {
                  "text": "Quels sont les pistes d'amélioration de ces indicateurs pour qu'ils puissent le mieux correspondre à une mesure cohérente des objectifs formulés ?",
                  "value": "pistes_damelioaration_de_ses_indicateurs"
                },
                // {
                //   "text": "Quels sont les processus métiers ?",
                //   "value": "processus_metiers"
                // },
                {
                  "text": "La gestion des processus couvre-t-elle tous vos processus ?",
                  "value": "la_gestion_des_processus_couvre_t_elle_tous_vos_processus"
                },
                {
                  "text": "Quel est le périmètre couvert ?",
                  "value": "quel_est_le_perimetre_couvert"
                },
                {
                  "text": "Quelles sont les actions menées pour suivre le bon fonctionnement de ces processus ?",
                  "value": "actions_menees_pour_suivre_le_bon_fonctionnement_de_ses_processuss"
                },
                {
                  "text": "Quelles sont les actions menées pour assurer le bon traitement des différents incidents d'incohérence ?",
                  "value": "actions_menees_pour_assurer_le_bon_traitement_des_incidents_d'incoherence"
                },
                {
                  "text": "Quelles sont les actions menées pour assurer l'interfaçage des processus métiers ?",
                  "value": "actions_menees_pour_assurer_linterfacage_des_processuss_metiers"
                },
                {
                  "text": "Quelles sont les actions menées pour assurer la communication ?",
                  "value": "actions_menees_pour_assurer_la_communication"
                },
                {
                  "text": "Quelles sont les actions menées pour garantir la consolidation des données et des résultats ?",
                  "value": "actions_menees_pour_garantir_la_consolidation_des_donnee_et_des_resultats"
                },
                {
                  "text": "Quelles sont les attitudes, compétences et comportement souhaitées pour une gestion performante de vos processus ?",
                  "value": "attitudes_competences_et_comportement_souhaitees_pour_une_gestion_performante_de_vos_processus"
                },
                {
                  "text": "Comment acquérir les attitudes, compétences et comportement nécessaire à une gestion performante de vos processus ?",
                  "value": "comment_acquerir_attitudes_competences_et_comportement_necessaire_a_une_gestion_performante_de_vos_processus"
                },
                {
                  "text": "Quelles types de formation permettraient d'acquérir les attitudes, compétences et comportement nécessaire à une bonne gestion de vos processus métiers ?",
                  "value": "quels_types_de_formation_permettraient_dacquerir_les_attitudes_competences_et_comportement_necessaire_a_une_bonne_gestion_de_vos_processus"
                },
                {
                  "text": "Quels sont les difficultés/préoccupations/problèmes que vous rencontrez dans la réalisation de vos missions ?",
                  "value": "difficultes_preoccupations_problemes_que_vous_rencontrez_dans_la_realisation_de_vos_missions"
                },
                {
                  "text": "Comment procédez-vous pour résoudre les difficultés que vous rencontrez ?",
                  "value": "comment_procedez_vous_pour_resoudre_les_difficultes_que_vous_rencontrez"
                },
                {
                  "text": "Quelles sont les améliorations à apporter pour simplifier votre travail ?",
                  "value": "ameliorations_a_apporter_pour_simplifier_votre_travail"
                },
                {
                  "text": "Quelle est la nature des actions conduites pour l'amélioration de vos missions ?",
                  "value": "nature_des_actions_conduites_pour_lamelioration_de_vos_missions"
                },
                {
                  "text": "Y-a-t-il un mécanisme permettant de s'assurer que les actions conduitent correspondent bien à celles qui sont prévues ?",
                  "value": "mecanisme_permettant_de_sassurer_que_les_actions_conduitent_correspondent_bien_a_celles_qui_sont_prevues"
                },
                {
                  "text": "Comment pensez-vous qu'il faudrait procéder pour améliorer le fonctionnement des processus métiers qui sont à votre charge ?",
                  "value": "comment_pensez_vous_quil_faudrait_proceder_pour_ameliorer_le_fonctionnement_des_processus_metiers_qui_sont_a_votre_charge"
                },
                {
                  "text": "Quelles sont les règles de gestion qu'on peut supprimer sans affecter le bon déroulement de ces processus ?",
                  "value": "regles_de_gestion_quon_peut_supprimer_sans_affecter_le_bon_deroulement_de_ces_processus"
                },
                {
                  "text": "Pensez-vous qu'on pourrait réaliser vos missions en utilisant moins de ressources ?",
                  "value": "pensez_vous_quon_pourrait_realiser_vos_missions_en_utilisant_moins_de_ressources"
                },
                {
                  "text": "Comment devrait-on procéder pour utiliser moins de ressources afin de réaliser vos missions ?",
                  "value": "comment_devrait_on_proceder_pour_utiliser_moins_de_ressources_afin_de_realiser_vos_missions"
                },
                {
                  "text": "Y-a-t-il des erreurs qui surviennent dans la réalisation de vos missions ?",
                  "value": "erreurs_qui_surviennent_dans_la_realisation_de_vos_missions"
                },
                {
                  "text": "Qu'elles sont les raisons qui selon-vous engendre les erreurs ?",
                  "value": "raisons_qui_selon_vous_engendre_les_erreurs"
                },
                {
                  "text": "Comment les éviter ?",
                  "value": "comment_les_eviter"
                },
                {
                  "text": "Recevez-vous des plaintes des acteurs impliqués dans la résolution de vos missions ?",
                  "value": "recevez_vous_des_plaintes_des_acteurs_impliques_dans_la_resolution_de_vos_missions"
                },
                {
                  "text": "Qu'elles sont les raisons qui expliquent ces plaintes ?",
                  "value": "raisons_qui_expliquent_ces_plaintes"
                },
                {
                  "text": "Existe-t-il un système de prise en compte des erreurs et des plaintes des acteurs (agents, clients, ...) de manière à améliorer le fonctionnement des processus ?",
                  "value": "systeme_de_prise_en_compte_des_erreurs_et_des_plaintes_des_acteurs_de_maniere_a_ameliorer_le_fonctionnement_des_processus"
                },
                {
                  "text": "Avez-vous des périodes d'inactivités dans votre service ?",
                  "value": "avez_vous_des_periodes_dinactivites_dans_votre_service"
                },
                {
                  "text": "Quelles sont les raisons qui peuvent expliquer que vous avez des périodes d'inactivités dans votre service ?",
                  "value": "raisons_qui_peuvent_expliquer_que_vous_avez_des_periodes_dinactivites_dans_votre_service"
                },
                {
                  "text": "Comment pensez-vous qu'il faudrait procéder pour régler ce problème ?",
                  "value": "comment_pensez_vous_quil_faudrait_proceder_pour_regler_ce_probleme"
                },
                {
                  "text": "Existe t-il un système permettant de conduire les changements des processus métiers et facilitant l'intégration des améliorations de ceux-ci ?",
                  "value": "existe_til_un_systeme_permettant_de_conduire_les_changements_des_processus_metiers_et_facilitant_lintegration_des_ameliorations_de_ceux_ci"
                },
                {
                  "text": "Quelles sont les actions de communication concernant la démarche, les actions conduites, les résultats obtenus ?",
                  "value": "actions_de_communication_concernant_la_demarche_les_actions_conduites_les_resultats_obtenus"
                },
                {
                  "text": "Existe-t-il un système d'écoute clients de manière à appréhender leurs besoins et mesurer en retour les impacts des conduites de changement des processus et de leur intégration ?",
                  "value": "systeme_decoute_clients_de_maniere_a_apprehender_leurs_besoins_et_mesurer_en_retour_les_impacts_des_conduites_de_changement_des_processus_et_de_leur_integration"
                },
                {
                  "text": "Quelle est selon-vous l'importance de votre travail pour votre organisation ?",
                  "value": "importance_de_votre_travail_pour_votre_organisation"
                },
                {
                  "text": "Obtenez-vous satisfaction de votre travail ?",
                  "value": "obtenez_vous_satisfaction_de_votre_travail"
                },
                {
                  "text": "Comment qualifierez-vous les relations que vous entretenez avec votre supérieur hiérarchique ?",
                  "value": "relations_que_vous_entretenez_avec_votre_superieur_hierarchique"
                },
                {
                  "text": "Connaissez-vous les critères de nomination de l'équipe de gouvernance des processus ?",
                  "value": "criteres_de_nomination_de_lequipe_de_gouvernance_des_processus"
                },
                {
                  "text": "Quelles sont les difficultés que vous avez rencontrées pour prendre en charge votre poste et dans l'exercice de celui-ci ?",
                  "value": "difficultes_rencontrees_pour_prendre_en_charge_votre_poste_et_dans_lexercice_de_celui_ci"
                },
                {
                  "text": "Quels sont les conseils que vous souhaitez donner pour aider d'autres services ou départements ?",
                  "value": "conseils_que_vous_souhaitez_donner_pour_aider_dautres_services_ou_departements"
                },
                {
                  "text": "Quelles satisfactions, à titre personnel, trouvez-vous dans l'exercice de votre fonction ?",
                  "value": "quelles_satisfactions_a_titre_personnel_trouvez_vous_dans_lexercice_de_votre_fonction"
                }
            ]        
        }
        this.accessStore = new AccessStore( this.props.dispatch);
    }

    _generateTableContent(list, listElements) {
      try {
        return (
          list.map((elt, index) => (
            <>
              <td>
                {listElements[elt.value]}
              </td>
              {
                elt.list ?
                  this._generateTableContent(elt.list, listElements[elt.value])
                :
                  null
              }
            </>
          ))
        )
      } catch (error) {
        
      }
    }

    componentDidMount() {
        this._getAllSubmission((response) => {
            // console.log(response);
            this.setState({
                submissionList: response.data,
            });
        });
    }
    render() {
        return (
            <>
                {
                    super.render()
                }
                <div className='dashboard_body'>
                    <div className='dashboard_body_title'>
                        <span className='dashboard_body_title_ text-center'>
                            Liste des soumissions
                        </span>
                    </div>
                    <table>
                        <thead>
                            {/* {this._generateHeader(this.state.optionsList)} */}
                            <tr className="table-head">
                              {
                                this.state.optionsList.map((option, index)=>(
                                  <th colSpan={
                                    option.text==="Quelles sont les missions/activités régaliennes que vous devez accomplir dans le cadre de votre poste ?"?57:1
                                  }>
                                    {option.text}
                                  </th>
                                ))
                              }
                            </tr>
                            <tr className="table-head">
                              {
                                this.state.optionsList.map((option, index)=>(
                                  option.list ?
                                  option.list.map((elt) => (
                                    <th colSpan={
                                      elt.text==="Identification des processus métiers"? 45 : (elt.list?elt.list.length:1)
                                    }>
                                      {elt.text}
                                    </th>
                                  ))
                                  : <th></th>
                                ))
                              }
                            </tr>
                            <tr className="table-head">
                              {
                                this.state.optionsList.map((option, index)=>(
                                  option.list ?
                                  option.list.map((elt) => (
                                    elt.list ?
                                    elt.list.map((elt_) => (
                                      <th colSpan={elt_.text==="Quels sont les processus dont vous avez la charge pour la réalisation de la mission ?" ? 43 : 1}>
                                        {elt_.text}
                                      </th>
                                    ))
                                    :
                                    <th></th>
                                  ))
                                  : <th></th>
                                ))
                              }
                            </tr>
                            <tr className="table-head">
                              {
                                this.state.optionsList.map((option, index)=>(
                                  option.list ?
                                  option.list.map((elt) => (
                                    elt.list ?
                                    elt.list.map((elt_) => (
                                      elt_.list ?
                                        elt_.list.map((elt__) => (
                                          <th colSpan={elt__.text==="Identification des tâches de chaque processus métiers" ? 42 : 1}>
                                            {elt__.text}
                                          </th>
                                        ))
                                      :
                                      <th></th>
                                    ))
                                    :
                                    <th></th>
                                  ))
                                  : <th></th>
                                ))
                              }
                            </tr>

                            <tr className="table-head">
                              {
                                this.state.optionsList.map((option, index)=>(
                                  option.list ?
                                  option.list.map((elt) => (
                                    elt.list ?
                                    elt.list.map((elt_) => (
                                      elt_.list ?
                                        elt_.list.map((elt__) => (
                                            elt__.list ?
                                            elt__.list.map((elt___) => (
                                              <th colSpan={elt___.text==="Quelles sont les tâches à réaliser pour l'accomplissement du processus?" ? 35 : 1}>
                                                {elt___.text}
                                              </th>
                                            ))
                                            :<th></th>
                                        ))
                                      :
                                      <th></th>
                                    ))
                                    :
                                    <th></th>
                                  ))
                                  : <th></th>
                                ))
                              }
                            </tr>

                            <tr className="table-head">
                              {
                                this.state.optionsList.map((option, index)=>(
                                  option.list ?
                                  option.list.map((elt) => (
                                    elt.list ?
                                    elt.list.map((elt_) => (
                                      elt_.list ?
                                      elt_.list.map((elt__) => (
                                        elt__.list ?
                                        elt__.list.map((elt___) => (
                                          elt___.list ?
                                          elt___.list.map((elt____) => (
                                            <th
                                              colSpan={elt____.list ? elt____.list.length : 1}
                                            >
                                              {elt____.text}
                                            </th>
                                          ))
                                          :<th></th>
                                        ))
                                        :
                                        <th></th>
                                      ))
                                      :
                                      <th></th>
                                    ))
                                    :
                                    <th></th>
                                  ))
                                  : <th></th>
                                ))
                              }
                            </tr>

                            <tr className="table-head">
                              {
                                this.state.optionsList.map((option, index)=>(
                                  option.list ?
                                  option.list.map((elt) => (
                                    elt.list ?
                                    elt.list.map((elt_) => (
                                      elt_.list ?
                                      elt_.list.map((elt__) => (
                                        elt__.list ?
                                        elt__.list.map((elt___) => (
                                          elt___.list ?
                                          elt___.list.map((elt____) => (
                                            elt____.list ?
                                            elt____.list.map((elt_____) => (
                                              <th>
                                                {elt_____.text}
                                              </th>
                                            ))
                                            :<th></th>
                                          ))
                                          :<th></th>
                                        ))
                                        :
                                        <th></th>
                                      ))
                                      :
                                      <th></th>
                                    ))
                                    :
                                    <th></th>
                                  ))
                                  : <th></th>
                                ))
                              }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.submissionList.map((submission, index)=>(
                                    <tr key={"submission-" + index}
                                        style={{
                                            background: index%2 !== 0 ? this.VAR.color_1 : "transparent",
                                            color:  index%2 !== 0 ? "#ffffff" : "#000000",
                                            zIndex: "10000"
                                        }}
                                    >
                                      {
                                        this._generateTableContent(this.state.optionsList,submission.data)
                                      }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}


DashboardSubmissionList = connect(mapStateToProps, null)(DashboardSubmissionList);
export default DashboardSubmissionList;